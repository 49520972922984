import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Contact }          from "Utils/API";

// Components
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Hospitality Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityEdit(props) {
    const { open, elemID, clientID, conversationID, onClose, onSubmit } = props;

    const { elem, editElem, statuses, hotels, customFields } = Store.useState("hospitality");
    const { fetchEditData, editHospitality } = Store.useAction("hospitality");


    // The Current State
    const [ openContact, setOpenContact ] = React.useState(false);

    // The Initial Data
    const initialData = {
        hospitalityID : 0,
        clientID      : 0,
        hotelID       : 0,
        contactID     : 0,
        contactName   : "",
        externalID    : "",
        roomNumber    : "",
        arrivalDate   : "",
        departureDate : "",
        pinMainNumber : "",
        pinSecNumber  : "",
        status        : "Booking",
    };
    for (const customField of customFields) {
        initialData[customField.id] = "";
    }


    // Handles the Set
    const handleSet = () => {
        if (editElem.id) {
            setElem({ ...editElem });
        } else {
            setElem({ ...initialData });
        }
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editHospitality({ ...data, clientID, conversationID });
    };

    // Handles the Contact Create
    const handleContactCreate = (contactID, response) => {
        setData({
            contactID   : response.contact.id,
            contactName : response.contact.fullName,
        });
    };

    // Handles the Final Submit
    const handleFinalSubmit = (response) => {
        const sameElem = !elem.id || elem.id === editElem.id;
        onSubmit(sameElem ? response.hospitalityID : 0);
    };


    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("hospitality", initialData, handleEdit, handleFinalSubmit);

    // Load the Data
    const { loading } = useDialog("hospitality", open, elemID, { elemID, clientID }, handleSet, fetchEditData);



    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="hospitality"
            title={elemID ? "HOSPITALITY_EDIT_TITLE" : "HOSPITALITY_CREATE_TITLE"}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="suggest"
                name="contactName"
                label="CONTACTS_SINGULAR"
                suggestID="contactID"
                suggestFetch={Contact.search}
                suggestParams={{ clientID }}
                value={data.contactName}
                error={errors.contactID}
                onChange={handleChange}
                button="GENERAL_CREATE"
                onClick={() => setOpenContact(true)}
                isRequired
            />

            <InputField
                type="select"
                name="hotelID"
                label="HOTELS_SINGULAR"
                options={hotels}
                value={data.hotelID}
                error={errors.hotelID}
                onChange={handleChange}
                isRequired
            />

            <Columns>
                <InputField
                    name="externalID"
                    label="HOSPITALITY_EXTERNAL_ID"
                    value={data.externalID}
                    error={errors.externalID}
                    onChange={handleChange}
                    generateCode
                />
                <InputField
                    name="roomNumber"
                    label="HOSPITALITY_ROOM_NUMBER"
                    value={data.roomNumber}
                    error={errors.roomNumber}
                    onChange={handleChange}
                />

                <InputField
                    type="date"
                    name="arrivalDate"
                    label="HOSPITALITY_ARRIVAL_DATE"
                    value={data.arrivalDate}
                    error={errors.arrivalDate}
                    onChange={handleChange}
                />
                <InputField
                    type="date"
                    name="departureDate"
                    label="HOSPITALITY_DEPARTURE_DATE"
                    value={data.departureDate}
                    error={errors.departureDate}
                    onChange={handleChange}
                />

                <InputField
                    name="pinMainNumber"
                    label="HOSPITALITY_PIN_MAIN_NUMBER"
                    value={data.pinMainNumber}
                    error={errors.pinMainNumber}
                    onChange={handleChange}
                    codeLength="6"
                    codeSets="d"
                    generateCode
                />
                <InputField
                    name="pinSecNumber"
                    label="HOSPITALITY_PIN_SEC_NUMBER"
                    value={data.pinSecNumber}
                    error={errors.pinSecNumber}
                    onChange={handleChange}
                    codeLength="6"
                    codeSets="d"
                    generateCode
                />

                <FieldsInputs
                    fields={customFields}
                    data={data}
                    errors={errors}
                    onChange={handleChange}
                    withRequired
                />
            </Columns>

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
        </EditDialog>

        <ContactEdit
            open={openContact}
            clientID={clientID}
            onSubmit={handleContactCreate}
            onClose={() => setOpenContact(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityEdit.propTypes = {
    open           : PropTypes.bool.isRequired,
    onClose        : PropTypes.func.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    elemID         : PropTypes.number,
    clientID       : PropTypes.number,
    conversationID : PropTypes.number,
};

export default HospitalityEdit;
