import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Empty = Styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 24px;
    box-sizing: border-box;
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    font-size: 16px;
    font-weight: normal;
`;



/**
 * The Campaign Empty
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignEmpty(props) {
    const { waitMessage, configureMessage } = props;

    const navigate = Hooks.useGoto();

    const { canAddChannel } = Store.useState("campaign");


    // Handles the Click
    const handleClick = () => {
        navigate("SETTINGS", "CHANNELS", "ADD");
    };


    // Do the Render
    return <Empty>
        <Title>
            {NLS.get(canAddChannel ? configureMessage : waitMessage)}
        </Title>
        {canAddChannel && <Button
            variant="primary"
            message="CHANNELS_ADD_TITLE"
            onClick={handleClick}
        />}
    </Empty>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignEmpty.propTypes = {
    waitMessage      : PropTypes.string.isRequired,
    configureMessage : PropTypes.string.isRequired,
};

export default CampaignEmpty;
