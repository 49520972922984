import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Integration Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationHeader(props) {
    const { levels } = props;

    const parent = Navigate.useParent(levels);

    const { canEdit, elem } = Store.useState("integration");
    const { totalChanges, publishErrors } = Store.useState("integrationEditor");
    const { setAction, setInActivate, setInPublish } = Store.useAction("integrationState");

    const { publishErrors : flowPublishErrors } = Store.useState("flowEditor");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isActivate && (publishErrors.length || flowPublishErrors.length)) {
            setInActivate(true);
            setFlowInPublish(true);
        } else if (action.isPublish && (publishErrors.length || flowPublishErrors.length)) {
            setInPublish(true);
            setFlowInPublish(true);
        } else {
            setAction(action);
        }
    };


    // Do the Render
    const isActive = elem.isActive || elem.isPending;

    return <Header
        message={elem.name || "INTEGRATIONS_SINGULAR"}
        icon="integration"
        href={parent}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                isHidden={isActive}
                variant={totalChanges > 0 ? "primary" : "outlined"}
                action="ACTIVATE"
                badge={totalChanges}
            />
            <ActionItem
                isHidden={!isActive}
                variant={totalChanges > 0 ? "primary" : "outlined"}
                action="PUBLISH"
                badge={totalChanges}
            />

            <ActionItem
                isHidden={!canEdit}
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="EDIT"
                    message="INTEGRATIONS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!isActive}
                    action="PAUSE"
                    message="INTEGRATIONS_PAUSE_TITLE"
                />
                <ActionOption
                    isHidden={!elem.isDraft}
                    action="COPY"
                    message="INTEGRATIONS_COPY_TITLE"
                />
                <ActionOption
                    isHidden={!isActive || !elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="INTEGRATIONS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationHeader.propTypes = {
    levels : PropTypes.number.isRequired,
};

export default IntegrationHeader;
