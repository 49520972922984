import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FieldsInputs         from "Components/Utils/Inputs/FieldsInputs";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Account Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountFilter(props) {
    const { open, filters, clientID, onClose, onSubmit } = props;

    const { customFields } = Store.useState("account");


    // The Initial Data
    const initialData = {
        period     : 0,
        fromDate   : "",
        toDate     : "",
        name       : "",
        externalID : "",
    };
    for (const customField of customFields) {
        initialData[customField.id] = "";
    }

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("account", open, initialData, filters, onSubmit, { elemID : 0, clientID });


    // Do the Render
    return <FilterDialog
        open={open}
        title="ACCOUNTS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <Columns>
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                onChange={handleChange}
            />
            <InputField
                name="externalID"
                label="ACCOUNTS_EXTERNAL_ID"
                value={data.externalID}
                onChange={handleChange}
            />

            <FieldsInputs
                fields={customFields}
                data={data}
                onChange={handleChange}
                skipFiles
            />
        </Columns>
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
    clientID : PropTypes.number,
};

export default AccountFilter;
