import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";

// Components
import FlowTemplateEdit     from "./FlowTemplateEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Flow Template Dialogs
 * @returns {React.ReactElement}
 */
function FlowTemplateDialogs() {
    const { openDetails } = Store.useAction("core");
    const navigate = Navigate.useGoto();

    const { elem } = Store.useState("flowTemplate");
    const { fetchElem, publishFlow, clearFlow } = Store.useAction("flowTemplate");

    const { totalChanges, publishErrors } = Store.useState("flowEditor");
    const { fetchEditor } = Store.useAction("flowEditor");

    const { action } = Store.useState("flowState");
    const { setAction, setInPublish } = Store.useAction("flowState");


    // Handles the Flow Edit
    const handleEdit = (response) => {
        setAction();
        if (response.flowID !== elem.id) {
            navigate("SETTINGS", "FLOWS", response.flowID);
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishFlow(elem.id);
        if (result.success) {
            setInPublish(false);
            fetchElem(elem.id);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearFlow(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        openDetails();
    };



    // Do the Render
    return <>
        <FlowTemplateEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isPublish && totalChanges === 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="FLOWS_TEMPLATES_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={NLS.pluralize("FLOWS_TEMPLATES_PUBLISH", totalChanges, elem.name)}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="FLOWS_TEMPLATES_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isError}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishErrors.length > 1 ? "FLOWS_TEMPLATES_ERRORS_TEXT" : "FLOWS_TEMPLATES_ERROR_TEXT"}
            content={String(publishErrors.length)}
            onSubmit={handleErrors}
            onClose={setAction}
        />
    </>;
}

export default FlowTemplateDialogs;
