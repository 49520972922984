import Store                    from "Dashboard/Core/Store";


// Core
import GlobalStore              from "Stores/Core/GlobalStore";
import AuthStore                from "Stores/Core/AuthStore";
import PermissionStore          from "Stores/Core/PermissionStore";

// Profile
import ProfileStore             from "Stores/App/Profile/ProfileStore";
import OrganizationStore        from "Stores/App/Profile/OrganizationStore";

// Dashboard
import DashboardFilterStore     from "Stores/App/Dashboard/DashboardFilterStore";
import DashboardReportStore     from "Stores/App/Dashboard/DashboardReportStore";
import DashboardUserStore       from "Stores/App/Dashboard/DashboardUserStore";
import DashboardTeamStore       from "Stores/App/Dashboard/DashboardTeamStore";
import DashboardMonitoringStore from "Stores/App/Dashboard/DashboardMonitoringStore";

// Admin
import ClientStore              from "Stores/App/Admin/ClientStore";
import PartnerStore             from "Stores/App/Admin/PartnerStore";
import SubscriptionStore        from "Stores/App/Admin/SubscriptionStore";

// Client
import ContactStore             from "Stores/App/Client/ContactStore";
import ContactMergeStore        from "Stores/App/Client/ContactMergeStore";
import HospitalityStore         from "Stores/App/Client/HospitalityStore";
import AccountStore             from "Stores/App/Client/AccountStore";
import UserStore                from "Stores/App/Client/UserStore";
import TeamStore                from "Stores/App/Client/TeamStore";

// Conversation
import ConversationStore        from "Stores/App/Conversation/ConversationStore";
import ConversationMessageStore from "Stores/App/Conversation/ConversationMessageStore";
import ConversationNotStore     from "Stores/App/Conversation/ConversationNoteStore";

// Store
import StoreStore               from "Stores/App/Store/StoreStore";
import StoreStateStore          from "Stores/App/Store/StoreStateStore";
import StoreOrderStore          from "Stores/App/Store/StoreOrderStore";
import StoreProductStore        from "Stores/App/Store/StoreProductStore";
import StoreCategoryStore       from "Stores/App/Store/StoreCategoryStore";
import StoreBannerStore         from "Stores/App/Store/StoreBannerStore";
import StoreOptionStore         from "Stores/App/Store/StoreOptionStore";

// Campaign
import CampaignStore            from "Stores/App/Campaign/CampaignStore";
import CampaignReportStore      from "Stores/App/Campaign/CampaignReportStore";
import CampaignExecutionStore   from "Stores/App/Campaign/CampaignExecutionStore";
import CampaignStateStore       from "Stores/App/Campaign/CampaignStateStore";

// Config
import TagStore                 from "Stores/App/Config/TagStore";
import TongueStore              from "Stores/App/Config/TongueStore";
import MsgTemplateStore         from "Stores/App/Config/MsgTemplateStore";
import QuestionnaireStore       from "Stores/App/Config/QuestionnaireStore";
import ScheduleStore            from "Stores/App/Config/ScheduleStore";
import CustomFieldStore         from "Stores/App/Config/CustomFieldStore";
import HotelStore               from "Stores/App/Config/HotelStore";
import ChannelStore             from "Stores/App/Config/ChannelStore";
import PhoneNumberStore         from "Stores/App/Config/PhoneNumberStore";
import WabaTemplateStore        from "Stores/App/Config/WabaTemplateStore";

// Assistant
import AssistantStore           from "Stores/App/Assistant/AssistantStore";
import AssistantModelStore      from "Stores/App/Assistant/AssistantModelStore";
import AssistantRunStore        from "Stores/App/Assistant/AssistantRunStore";
import AssistantThreadStore     from "Stores/App/Assistant/AssistantThreadStore";

// Flow
import FlowStore                from "Stores/App/Flow/FlowStore";
import FlowTemplateStore        from "Stores/App/Flow/FlowTemplateStore";
import FlowReportStore          from "Stores/App/Flow/FlowReportStore";
import FlowConversationStore    from "Stores/App/Flow/FlowConversationStore";
import FlowEditorStore          from "Stores/App/Flow/FlowEditorStore";
import FlowStateStore           from "Stores/App/Flow/FlowStateStore";
import FlowSimulationStore      from "Stores/App/Flow/FlowSimulationStore";

// Widget
import WidgetStore              from "Stores/App/Widget/WidgetStore";
import WidgetPlatformStore      from "Stores/App/Widget/WidgetPlatformStore";
import WidgetStateStore         from "Stores/App/Widget/WidgetStateStore";

// Integration
import IntegrationStore         from "Stores/App/Integration/IntegrationStore";
import IntegrationTempStore     from "Stores/App/Integration/IntegrationTempStore";
import IntegrationReportStore   from "Stores/App/Integration/IntegrationReportStore";
import IntegrationExecStore     from "Stores/App/Integration/IntegrationExecStore";
import IntegrationEditorStore   from "Stores/App/Integration/IntegrationEditorStore";
import IntegrationStateStore    from "Stores/App/Integration/IntegrationStateStore";

// Connector
import ConnectorStore           from "Stores/App/Connector/ConnectorStore";
import ConnectorTypeStore       from "Stores/App/Connector/ConnectorTypeStore";

// Support
import TicketStore              from "Stores/App/Support/TicketStore";
import TicketTypeStore          from "Stores/App/Support/TicketTypeStore";
import TicketWaitlistStore      from "Stores/App/Support/TicketWaitlistStore";
import DocumentStore            from "Stores/App/Support/DocumentStore";

// Setup
import AdminStore               from "Stores/App/Setup/AdminStore";
import PlanStore                from "Stores/App/Setup/PlanStore";
import AddonStore               from "Stores/App/Setup/AddonStore";
import CurrencyStore            from "Stores/App/Setup/CurrencyStore";
import CountryStore             from "Stores/App/Setup/CountryStore";
import ProvinceStore            from "Stores/App/Setup/ProvinceStore";
import LanguageStore            from "Stores/App/Setup/LanguageStore";
import MediaStore               from "Stores/App/Setup/MediaStore";

// Email
import EmailTemplateStore       from "Stores/App/Email/EmailTemplateStore";
import EmailQueueStore          from "Stores/App/Email/EmailQueueStore";
import EmailWhiteListStore      from "Stores/App/Email/EmailWhiteListStore";

// Log
import NotificationLogStore     from "Stores/App/Log/NotificationLogStore";
import ProviderLogStore         from "Stores/App/Log/ProviderLogStore";
import ConnectorLogStore        from "Stores/App/Log/ConnectorLogStore";
import ActionLogStore           from "Stores/App/Log/ActionLogStore";
import QueryLogStore            from "Stores/App/Log/QueryLogStore";
import ErrorLogStore            from "Stores/App/Log/ErrorLogStore";



// The Combined Stores
export default Store.configureStore({

    // Core
    global              : GlobalStore,
    auth                : AuthStore,
    permission          : PermissionStore,

    // Profile
    profile             : ProfileStore,
    organization        : OrganizationStore,

    // Dashboard
    dashboardFilter     : DashboardFilterStore,
    dashboardReport     : DashboardReportStore,
    dashboardUser       : DashboardUserStore,
    dashboardTeam       : DashboardTeamStore,
    dashboardMonitoring : DashboardMonitoringStore,

    // Admin
    client              : ClientStore,
    partner             : PartnerStore,
    subscription        : SubscriptionStore,

    // Client
    contact             : ContactStore,
    contactMerge        : ContactMergeStore,
    hospitality         : HospitalityStore,
    account             : AccountStore,
    user                : UserStore,
    team                : TeamStore,

    // Conversation
    conversation        : ConversationStore,
    conversationMessage : ConversationMessageStore,
    conversationNote    : ConversationNotStore,

    // Store
    store               : StoreStore,
    storeState          : StoreStateStore,
    storeOrder          : StoreOrderStore,
    storeProduct        : StoreProductStore,
    storeCategory       : StoreCategoryStore,
    storeBanner         : StoreBannerStore,
    storeOption         : StoreOptionStore,

    // Campaign
    campaign            : CampaignStore,
    campaignReport      : CampaignReportStore,
    campaignExecution   : CampaignExecutionStore,
    campaignState       : CampaignStateStore,

    // Config
    tag                 : TagStore,
    tongue              : TongueStore,
    msgTemplate         : MsgTemplateStore,
    questionnaire       : QuestionnaireStore,
    schedule            : ScheduleStore,
    customField         : CustomFieldStore,
    hotel               : HotelStore,
    channel             : ChannelStore,
    phoneNumber         : PhoneNumberStore,
    wabaTemplate        : WabaTemplateStore,

    // Assistant
    assistant           : AssistantStore,
    assistantModel      : AssistantModelStore,
    assistantRun        : AssistantRunStore,
    assistantThread     : AssistantThreadStore,

    // Flow
    flow                : FlowStore,
    flowTemplate        : FlowTemplateStore,
    flowReport          : FlowReportStore,
    flowConversation    : FlowConversationStore,
    flowEditor          : FlowEditorStore,
    flowState           : FlowStateStore,
    flowSimulation      : FlowSimulationStore,

    // Widget
    widget              : WidgetStore,
    widgetPlatform      : WidgetPlatformStore,
    widgetState         : WidgetStateStore,

    // Integration
    integration         : IntegrationStore,
    integrationTemplate : IntegrationTempStore,
    integrationExec     : IntegrationExecStore,
    integrationReport   : IntegrationReportStore,
    integrationEditor   : IntegrationEditorStore,
    integrationState    : IntegrationStateStore,

    // Connector
    connector           : ConnectorStore,
    connectorType       : ConnectorTypeStore,

    // Support
    ticket              : TicketStore,
    ticketType          : TicketTypeStore,
    ticketWaitlist      : TicketWaitlistStore,
    document            : DocumentStore,

    // Setup
    admin               : AdminStore,
    plan                : PlanStore,
    addon               : AddonStore,
    currency            : CurrencyStore,
    country             : CountryStore,
    province            : ProvinceStore,
    language            : LanguageStore,
    media               : MediaStore,

    // Email
    emailTemplate       : EmailTemplateStore,
    emailQueue          : EmailQueueStore,
    emailWhiteList      : EmailWhiteListStore,

    // Log
    notificationLog     : NotificationLogStore,
    providerLog         : ProviderLogStore,
    connectorLog        : ConnectorLogStore,
    actionLog           : ActionLogStore,
    queryLog            : QueryLogStore,
    errorLog            : ErrorLogStore,

});
