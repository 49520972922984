import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Home
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeHome(props) {
    const { onChange, onClear, forceClear, getText } = props;

    const { data } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="toggle"
            name="showBanners"
            label="PERSONALIZE_SHOW_BANNERS"
            value={Number(data.showBanners)}
            onChange={onChange}
            withBorder
        />
        <InputField
            isHidden={!data.showBanners}
            type="toggle"
            name="autoSlideBanners"
            label="PERSONALIZE_AUTO_SLIDE_BANNERS"
            value={Number(data.autoSlideBanners)}
            onChange={onChange}
            withBorder
        />

        <InputField
            className="top-space-big"
            type="number"
            name="totalCategories"
            label="PERSONALIZE_TOTAL_CATEGORIES"
            helperText="PERSONALIZE_TOTAL_CATEGORIES_TIP"
            value={Number(data.totalCategories)}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("totalCategories")}
            minValue={0}
        />
        <InputField
            type="number"
            name="rowsPerCategory"
            label="PERSONALIZE_ROWS_PER_CATEGORY"
            helperText="PERSONALIZE_ROWS_PER_CATEGORY_TIP"
            value={Number(data.rowsPerCategory)}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("rowsPerCategory")}
            minValue={0}
        />
        <InputField
            type="number"
            name="productsPerCategory"
            label="PERSONALIZE_PRODUCTS_PER_CATEGORY"
            helperText="PERSONALIZE_PRODUCTS_PER_CATEGORY_TIP"
            value={Number(data.productsPerCategory)}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("productsPerCategory")}
            minValue={0}
        />
        <InputField
            name="noCategory"
            label="PERSONALIZE_SEARCH_NONE_RESULTS"
            value={getText("noCategory")}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("noCategory")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeHome.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default PersonalizeHome;
