import React                from "react";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import StackedBar           from "Components/Utils/Common/StackedBar";

// Dashboard
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";



/**
 * The Flow Report
 * @returns {React.ReactElement}
 */
function FlowReport() {
    const { loadFilter } = useList("flowReport", "FLOW");

    const data = Store.useState("flowReport");
    const { filters, labels, amounts, channels } = data;


    // Do the Render
    const colors = [];

    return <>
        <FilterList
            onFilter={loadFilter}
            values={filters}
            hideButton
        >
            <FilterItem
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getLastSelect()}
            />
            <FilterItem
                type="select"
                name="providerName"
                label="GENERAL_PLATFORM"
                options={Commons.getPlatformSelect(channels)}
            />
            <FilterItem
                type="select"
                name="channelID"
                label="CHANNELS_SINGULAR"
                options={Commons.getChannelSelect(channels)}
            />
        </FilterList>

        <StackedBar
            labels={labels}
            amounts={amounts}
            colors={colors}
        />
    </>;
}

export default FlowReport;
