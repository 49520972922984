import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import AssistantModelEdit   from "./AssistantModelEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Assistant Model List
 * @returns {React.ReactElement}
 */
function AssistantModelList() {
    const { fetch, load } = useList("assistantModel");

    const data = Store.useState("assistantModel");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteAssistantModel } = Store.useAction("assistantModel");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteAssistantModel(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "assistantModelID", elemID, "name");

    return <Main>
        <Header message="ASSISTANTS_MODELS_NAME" icon="assistant-model">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="ASSISTANTS_MODELS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"       message="GENERAL_NAME"                  isTitle />
                    <TableHeader field="modelType"  message="GENERAL_TYPE"                  />
                    <TableHeader field="externalID" message="ASSISTANTS_MODELS_MODEL"       />
                    <TableHeader field="outputCost" message="ASSISTANTS_MODELS_INPUT_COST"  align="right" maxWidth="160" />
                    <TableHeader field="inputCost"  message="ASSISTANTS_MODELS_OUTPUT_COST" align="right" maxWidth="160" />
                    <TableHeader field="position"   message="GENERAL_POSITION"              align="center" maxWidth="80" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.assistantModelID} elemID={elem.assistantModelID}>
                        <TableCell message={elem.name}          />
                        <TableCell message={elem.modelTypeName} />
                        <TableCell message={elem.externalID}    />
                        <TableCell message={elem.inputText}     />
                        <TableCell message={elem.outputText}    />
                        <TableCell message={elem.position}      />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="ASSISTANTS_MODELS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="ASSISTANTS_MODELS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <AssistantModelEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="ASSISTANTS_MODELS_DELETE_TITLE"
            message="ASSISTANTS_MODELS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default AssistantModelList;
