import React                 from "react";
import PropTypes             from "prop-types";
import Store                 from "Dashboard/Core/Store";
import Navigate              from "Dashboard/Core/Navigate";
import Conversations         from "Utils/Conversations";

// Components
import ConversationCreate    from "./ConversationCreate";
import ConversationSummarize from "./ConversationSummarize";
import ContactBlock          from "Components/App/Client/Contact/Dialogs/ContactBlock";
import AccountAssign         from "Components/App/Client/Account/Dialogs/AccountAssign";
import TagEdit               from "Components/App/Config/Tag/TagEdit";

// Dashboard
import ActionItem            from "Dashboard/Components/Header/ActionItem";
import ActionOption          from "Dashboard/Components/Header/ActionOption";
import MenuItem              from "Dashboard/Components/Menu/MenuItem";
import DeleteDialog          from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Conversation Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationActions(props) {
    const { isUnreadRef } = props;

    const { conversationTab, conversationHash } = Navigate.useParams();
    const conversationsNavigate = Conversations.useGoto();

    const { hasAccount } = Store.useState("permission");

    const {
        canEdit, canDelete, elem, contact, providers,
        users, teams, tags, tongues, hotels, flows, assistants,
    } = Store.useState("conversation");

    const {
        deleteConversation,
        markAsUnread, triggerElemUpdate,
        assignUser, assignTeam, assignTag, assignTongue, assignHotel, assignFlow,
    } = Store.useAction("conversation");


    // The Current State
    const [ openDialog, setOpenDialog ] = React.useState("");
    const [ loading,    setLoading    ] = React.useState(false);

    // Generates the lists
    const tagList   = [ ...tags, { key : 0, value : "TAGS_CREATE_TITLE" }];
    const teamList  = [ ...teams ];
    const hotelList = [ ...hotels ];

    if (elem.teamID) {
        teamList.unshift({ key : 0, value : "GENERAL_NONE" });
    }
    if (elem.hotelID) {
        hotelList.unshift({ key : 0, value : "GENERAL_NONE" });
    }


    // Handles the Conversation Create
    const handleCreate = (response) => {
        setOpenDialog("");
        if (response.hash !== conversationHash) {
            conversationsNavigate(conversationTab, response.hash);
        }
    };

    // Handles the Unread Action
    const handleUnread = () => {
        if (elem.isProgress || elem.isFollowup) {
            markAsUnread(elem.id);
            isUnreadRef.current = 1;
        }
    };

    // Handles the User Action
    const handleUser = (userID) => {
        if (userID !== elem.userID) {
            assignUser(elem.id, userID);
        }
    };

    // Handles the Team Action
    const handleTeam = (teamID) => {
        if (teamID !== elem.teamID) {
            assignTeam(elem.id, teamID);
        } else {
            assignTeam(elem.id, 0);
        }
    };

    // Handles the Tag Action
    const handleTag = (tagID) => {
        if (!tagID) {
            setOpenDialog("tag");
        } else {
            assignTag(elem.id, tagID);
        }
    };

    // Handles the Tag Create Action
    const handleTagCreate = (response) => {
        if (response.tagID) {
            assignTag(elem.id, response.tagID);
        }
        setOpenDialog("");
    };

    // Handles the Tongue Action
    const handleTongue = (tongueID) => {
        if (tongueID !== elem.tongueID) {
            assignTongue(elem.id, tongueID);
        }
    };

    // Handles the Hotel Action
    const handleHotel = (hotelID) => {
        if (hotelID !== elem.hotelID) {
            assignHotel(elem.id, hotelID);
        } else {
            assignHotel(elem.id, 0);
        }
    };

    // Handles the Flow Action
    const handleFlow = (flowID) => {
        assignFlow(elem.id, flowID);
    };

    // Handles the Conversation Delete
    const handleDelete = async () => {
        setLoading(true);
        const result = await deleteConversation(elem.id);
        if (result.success) {
            conversationsNavigate(conversationTab);
        }
        setOpenDialog("");
        setLoading(false);
    };

    // Handles an Action
    const handleAction = () => {
        triggerElemUpdate();
        setOpenDialog("");
    };


    // Variables
    const canMarkUnread    = Boolean(elem.isProgress || elem.isFollowup);
    const canAssignUser    = Boolean(!elem.isResolved);
    const canAssignTeam    = Boolean(!elem.isResolved && teams.length);
    const canAssignTag     = Boolean(!elem.isResolved && tags.length);
    const canAssignTongue  = Boolean(!elem.isResolved && tongues.length);
    const canAssignHotel   = Boolean(!elem.isResolved && hotels.length > 1);
    const canAssignFlow    = Boolean(!elem.isResolved && flows.length);

    const canAssignAccount = Boolean(canEdit && hasAccount);
    const canBlock         = Boolean(canEdit && elem.contactID);
    const canSummarize     = Boolean(assistants.length);


    // Do the Render
    if (!conversationHash || !elem.id) {
        return <React.Fragment />;
    }
    return <>
        <ActionItem icon="more" message="GENERAL_ACTIONS">
            <ActionOption
                isHidden={!canEdit || !providers.length}
                icon="create"
                message="CONVERSATIONS_CREATE_TITLE"
                onClick={() => setOpenDialog("create")}
            />
            <ActionOption
                isHidden={!canMarkUnread}
                icon="unread"
                message="CONVERSATIONS_MARK_UNREAD"
                onClick={() => handleUnread()}
            />
            <ActionOption
                isHidden={!canAssignUser}
                direction="left"
                icon="user"
                message="CONVERSATIONS_ASSIGN_USER"
            >
                {users.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={Number(key) === elem.userID ? "checkedbox" : "checkbox"}
                    onClick={() => handleUser(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignTeam}
                direction="left"
                icon="team"
                message="CONVERSATIONS_ASSIGN_TEAM"
            >
                {teamList.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={!key ? "none" : (key === elem.teamID ? "checkedbox" : "checkbox")}
                    onClick={() => handleTeam(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignTag}
                direction="left"
                icon="tag"
                message="CONVERSATIONS_ASSIGN_TAG"
            >
                {tagList.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={!key ? "tag" : (Object.keys(elem.tags).includes(String(key)) ? "checkedbox" : "checkbox")}
                    onClick={() => handleTag(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignTongue}
                direction="left"
                icon="language"
                message="CONVERSATIONS_ASSIGN_LANGUAGE"
            >
                {tongues.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={key === elem.tongueID ? "checkedbox" : "checkbox"}
                    onClick={() => handleTongue(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignHotel}
                direction="left"
                icon="hotel"
                message="CONVERSATIONS_ASSIGN_HOTEL"
            >
                {hotelList.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={!key ? "none" : (key === elem.hotelID ? "checkedbox" : "checkbox")}
                    onClick={() => handleHotel(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignFlow}
                direction="left"
                icon="flow"
                message="CONVERSATIONS_ASSIGN_FLOW"
            >
                {flows.map(({ key, value }) => <MenuItem
                    key={key}
                    value={key}
                    message={value}
                    icon={key === elem.flowID ? "checkedbox" : "checkbox"}
                    onClick={() => handleFlow(key)}
                />)}
            </ActionOption>

            <ActionOption
                isHidden={!canAssignAccount}
                icon="account"
                message="ACCOUNTS_ASSIGN_ACCOUNT_TITLE"
                onClick={() => setOpenDialog("account")}
            />
            <ActionOption
                isHidden={!canBlock}
                icon="block"
                message={contact.isBlocked ? "CONTACTS_UNBLOCK_TITLE" : "CONTACTS_BLOCK_TITLE"}
                onClick={() => setOpenDialog("block")}
            />

            <ActionOption
                isHidden={!canSummarize}
                icon="summary"
                message="CONVERSATIONS_SUMMARIZE_TITLE"
                onClick={() => setOpenDialog("summarize")}
            />
            <ActionOption
                isHidden={!canDelete}
                icon="delete"
                message="CONVERSATIONS_DELETE_TITLE"
                onClick={() => setOpenDialog("delete")}
            />
        </ActionItem>

        <ConversationCreate
            open={openDialog === "create"}
            clientID={elem.clientID}
            contactID={contact.id && !contact.isBlocked ? contact.id : 0}
            onSubmit={handleCreate}
            onClose={() => setOpenDialog("")}
        />
        <ConversationSummarize
            open={openDialog === "summarize"}
            clientID={elem.clientID}
            conversationID={elem.conversationID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />

        <TagEdit
            open={openDialog === "tag"}
            clientID={elem.clientID}
            onSubmit={handleTagCreate}
            onClose={() => setOpenDialog("")}
        />
        <ContactBlock
            open={openDialog === "block"}
            elem={contact}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <AccountAssign
            openAssign={openDialog === "account"}
            elemName={contact.fullName}
            contactID={contact.id}
            clientID={contact.clientID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <DeleteDialog
            open={openDialog === "delete"}
            isLoading={loading}
            title="CONVERSATIONS_DELETE_TITLE"
            message="CONVERSATIONS_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={() => setOpenDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationActions.propTypes = {
    isUnreadRef : PropTypes.object.isRequired,
};

export default ConversationActions;
