import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import ContactItem          from "Components/Utils/Details/ContactItem";
import ConversationsItem    from "Components/Utils/Details/ConversationsItem";
import HospitalityItem      from "Components/Utils/Details/HospitalityItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";




/**
 * The Hospitality Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityDetails(props) {
    const { startAction } = props;
    const { canEdit, elem, contact, conversations } = Store.useState("hospitality");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    return <Details
        isHidden={!elem.id}
        collapsible="hospitality"
        onAction={handleAction}
        canEdit={canEdit}
    >
        <HospitalityItem
            action="EDIT"
            elem={elem}
        />
        <ContactItem
            action="CONTACT"
            elem={contact}
        />
        <ConversationsItem
            list={conversations}
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityDetails.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default HospitalityDetails;
