import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Category Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CategoryEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { elem } = Store.useState("store");
    const { tongues, statuses } = Store.useState("storeCategory");
    const { editCategory } = Store.useAction("storeCategory");


    // The Current State
    const [ tongueID, setTongueID ] = React.useState(0);

    // The Initial Data
    const initialData = {
        storeID    : 0,
        categoryID : 0,
        externalID : "",
        image      : "",
        position   : 0,
        status     : "Active",
        values     : {},
    };

    // Handle the Set
    const handleSet = (elem) => {
        setElem(elem);
        setTongueID(tongues[0].key);
    };

    // Handles the Value
    const handleValue = (name, value) => {
        const elem   = { ...data.values[tongueID], [name] : value };
        const values = { ...data.values, [tongueID] : elem };
        handleChange("values", values);
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editCategory({
            ...data,
            storeID : elem.storeID,
            values  : JSON.stringify(data.values),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("storeCategory", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("storeCategory", open, elemID, { storeID : elem.storeID }, handleSet);


    // Do the Render
    const showTongues = Boolean(tongues.length > 1);

    return <EditDialog
        open={open}
        icon="category"
        title={elemID ? "CATEGORIES_EDIT_TITLE" : "CATEGORIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        {showTongues && <TabList
            variant="lined"
            selected={tongueID}
            onClick={setTongueID}
            inDialog
        >
            {tongues.map(({ key, value }) => <TabItem
                key={key}
                message={value}
                value={key}
            />)}
        </TabList>}

        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.values[tongueID]?.name || ""}
            error={errors.name}
            onChange={handleValue}
            isRequired
        />
        <InputField
            name="externalID"
            label="GENERAL_EXTERNAL_ID"
            value={data.externalID}
            error={errors.externalID}
            onChange={handleChange}
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.values[tongueID]?.description || ""}
            error={errors.description}
            onChange={handleValue}
        />
        <MediaField
            name="image"
            label="GENERAL_IMAGE"
            mediaType={MediaType.IMAGE}
            value={data.image}
            error={errors.image}
            clientID={elem.clientID}
            onChange={handleChange}
        />

        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CategoryEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CategoryEdit;
