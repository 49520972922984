import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Input = Styled(InputField)`
    width: 160px;
    .input-content {
        padding: 5px 10px;
    }
    a {
        margin-bottom: -4px;
    }

    @media (max-width: 700px) {
        display: none;
    }
`;



/**
 * The Flow Search
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSearch(props) {
    const { isHidden } = props;

    const { search } = Store.useState("flowState");
    const { setSearch, setDetailsTab } = Store.useAction("flowState");


    // Handles the Search
    const handleSearch = (name, value) => {
        setSearch(value);
        setDetailsTab("editor");
    };


    // Do the Render
    return <Input
        isHidden={isHidden}
        name="search"
        icon="search"
        placeholder="GENERAL_SEARCH"
        autoComplete="new-password"
        value={search}
        onChange={handleSearch}
        onClear={() => setSearch("")}
        hasClear={search.length > 2}
        isSmall
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSearch.propTypes = {
    isHidden : PropTypes.bool,
};

export default FlowSearch;
