import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import KeyCode              from "Dashboard/Utils/KeyCode";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MessageDay           from "Components/App/Client/Conversation/Message/MessageDay";
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import Button               from "Dashboard/Components/Form/Button";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



// Styles
const Container = Styled(DetailList).attrs(({ hasInternalTabs }) => ({ hasInternalTabs }))`
    --assistant-tabs: ${(props) => props.hasInternalTabs ? "calc(var(--tabs-dialog) + 16px)" : "0px"};
    --assistant-reply: 85px;

    .details-content {
        display: flex;
        flex-direction: column;
        height: calc(var(--details-height) - var(--assistant-reply) - var(--assistant-tabs));
        padding: 0;
    }

    @media (max-width: 1200px) {
        .details-content {
            height: calc(100dvh - var(--assistant-reply) - var(--assistant-tabs));
        }
    }
`;

const Chat = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 260px;
    padding-bottom: 16px;
    overflow: auto;
`;

const Send = Styled.footer`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-top: 1px solid var(--border-color-light);
`;

const Textarea = Styled(InputField)`
    flex-grow: 2;
`;

const Footer = Styled.footer`
    padding: 8px 12px;
    border-top: 1px solid var(--border-color-light);

    .btn {
        width: 100%;
    }
`;



/**
 * The Assistant Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantItem(props) {
    const { module, parentID, assistantID, hasInternalTabs } = props;

    const { submits, items } = Store.useState("assistantThread");
    const { fetchMessages, sendMessage, restartMessages } = Store.useAction("assistantThread");


    // The References
    const containerRef = React.useRef(null);
    const inputRef     = React.useRef(null);

    // The Current State
    const [ firstScroll, setFirstScroll ] = React.useState(true);
    const [ atBottom,    setAtBottom    ] = React.useState(true);
    const [ message,     setMessage     ] = React.useState("");
    const [ sending,     setSending     ] = React.useState(false);
    const [ loading,     setLoading     ] = React.useState(false);
    const [ showRestart, setShowRestart ] = React.useState(false);


    // Fetch the Messages
    React.useEffect(() => {
        if (module) {
            fetchMessages({ module, parentID });
        }
    }, [ module, parentID ]);

    // Scroll container
    React.useEffect(() => {
        if (atBottom && containerRef.current) {
            window.setTimeout(() => Utils.scrollToBottom(containerRef, firstScroll), 100);
            setFirstScroll(false);
        }
    }, [ submits ]);


    // Handles the Scroll
    const handleScroll = async (e) => {
        let atBottom = false;
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 50) {
            atBottom = true;
        }
        setAtBottom(atBottom);
    };

    // Handles the Message KeyDown
    const handleKeyDown = (e) => {
        if (e.keyCode === KeyCode.DOM_VK_RETURN && !e.shiftKey) {
            handleSubmit();
            e.preventDefault();
        }
    };

    // handles the Submit
    const handleSubmit = async () => {
        if (!message) {
            return;
        }

        setMessage("");
        setSending(true);
        await sendMessage({ module, parentID, assistantID, message });
        setSending(false);
        inputRef.current.focus();
    };

    // Handles the Restart
    const handleRestart = async () => {
        setLoading(true);
        await restartMessages({ module, parentID, assistantID, message });
        setShowRestart(false);
        setLoading(false);
    };


    // Do the Render
    return <Container
        icon="assistant"
        message="ASSISTANTS_SINGULAR"
        hasInternalTabs={hasInternalTabs}
    >
        <Chat ref={containerRef} onScroll={handleScroll}>
            {items.map((elem, key) => <React.Fragment key={key}>
                <MessageDay dayName={elem.dayName} />
                {elem.list.map((item) => <React.Fragment key={item.id}>
                    <div>
                        <MessageBubble
                            isMine={true}
                            message={item.message}
                            time={item.time}
                            hideStatus
                        />
                    </div>
                    <div>
                        <MessageBubble
                            isMine={false}
                            message={item.response}
                            time={item.time}
                            hideStatus
                        />
                    </div>
                </React.Fragment>)}
            </React.Fragment>)}
        </Chat>

        <Send>
            <Textarea
                passedRef={inputRef}
                type="textarea"
                name="message"
                label="ASSISTANTS_THREADS_WRITE_TEXT"
                value={message}
                onChange={(name, value) => setMessage(value)}
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
                maxLength={250}
                rows="1"
                maxRows="8"
            />
            <CircularLoader
                isHidden={!sending}
                isSmall
            />
            <IconLink
                isHidden={sending}
                icon="send"
                onClick={handleSubmit}
                isSmall
            />
        </Send>

        <Footer>
            <Button
                variant="primary"
                message="ASSISTANTS_THREADS_RESTART_TITLE"
                onClick={() => setShowRestart(true)}
                isSmall
            />
        </Footer>

        <ConfirmDialog
            open={showRestart}
            isLoading={loading}
            icon="check"
            title="ASSISTANTS_THREADS_RESTART_TITLE"
            message="ASSISTANTS_THREADS_RESTART_TEXT"
            onSubmit={handleRestart}
            onClose={() => setShowRestart(false)}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantItem.propTypes = {
    module          : PropTypes.string.isRequired,
    parentID        : PropTypes.number,
    assistantID     : PropTypes.number,
    hasInternalTabs : PropTypes.bool,
};

export default AssistantItem;
