import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Hotel Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HotelPreview(props) {
    const { data } = props;

    const { elem, statuses, pmsProviders, ftpHostname, ftpPort, bookingProviders } = Store.useState("hotel");
    const { isAnyAdmin } = Store.useState("auth");


    // Variables
    const statusName          = Utils.getValue(statuses, "key", data.status, "value");
    const statusColor         = Utils.getValue(statuses, "key", data.status, "color");

    const pmsProvider         = Utils.getValue(pmsProviders, "key", data.pmsProvider);
    const pmsInputs           = pmsProvider?.inputs ?? [];
    const hasPMS              = Boolean(data.pmsProvider);
    const hasPMSLink          = Boolean(pmsProvider && pmsProvider.hasAccessToken);
    const hasPMSError         = Boolean(pmsProvider && pmsProvider.reqDefaultFields && data.pmsErrorTime);

    const hasFTP              = Boolean(pmsProvider && pmsProvider.allowsFTP && data.pmsUseFTP);
    const ftpUsername         = hasFTP && data.pmsID ? `${data.pmsID}@${ftpHostname}` : "";

    const bookingProvider     = Utils.getValue(bookingProviders, "key", data.bookingProvider);
    const hasBooking          = Boolean(data.bookingProvider);
    const hasBookingToken     = Boolean(hasBooking && bookingProvider.requiresToken);
    const hasBookingRefresh   = Boolean(hasBooking && bookingProvider.requiresRefresh);
    const hasBookingPromoCode = Boolean(hasBooking && bookingProvider.hasPromoCode);


    // Do the Render
    return <Details>
        <DetailList
            message="HOTELS_SINGULAR"
            collapsible="hotel"
        >
            <DetailItem
                icon="name"
                message={data.name}
                tooltip="GENERAL_NAME"
            />
            <DetailItem
                icon="link"
                message={data.siteUrl}
                url={data.siteUrl}
                target="_blank"
                tooltip="HOTELS_SITE_URL"
            />
            <DetailItem
                icon="status"
                message={statusName}
                textColor={statusColor}
                tooltip="GENERAL_STATUS"
            />
        </DetailList>

        <DetailList
            isHidden={!hasPMS}
            message="HOTELS_PMS_PROVIDER"
            collapsible="pms"
        >
            <DetailItem
                message={data.pmsProvider}
                prefix="HOTELS_PMS_PROVIDER"
            />
            <DetailItem
                message={data.pmsID}
                prefix="HOTELS_PMS_ID"
            />
            <>
                {pmsInputs.map((item) => <DetailItem
                    {...item}
                    key={item.name}
                    message={data[item.name] ?? ""}
                    prefix={item.label}
                />)}
            </>
            <DetailItem
                isHidden={!isAnyAdmin}
                message={Utils.toYesNo(data.pmsLogAll)}
                prefix="HOTELS_PMS_LOG_ALL"
            />
            <DetailItem
                isHidden={!isAnyAdmin}
                message={Utils.toYesNo(data.pmsIsDisabled)}
                prefix="HOTELS_PMS_IS_DISABLED"
            />
            <DetailItem
                isHidden={!hasPMSLink}
                message={Utils.toYesNo(elem.pmsAccessToken ? 1 : 0)}
                prefix="HOTELS_PMS_IS_LINKED"
                textColor={!elem.pmsAccessToken ? "red" : ""}
            />
            <DetailItem
                isHidden={!hasPMSError}
                message="GENERAL_YES"
                prefix="HOTELS_PMS_HAS_ERROR"
                textColor="red"
            />
        </DetailList>

        <DetailList
            isHidden={!hasFTP}
            message="HOTELS_FTP_TITLE"
            collapsible="ftp"
        >
            <DetailItem
                message={ftpHostname}
                prefix="HOTELS_FTP_HOSTNAME"
            />
            <DetailItem
                message={ftpPort}
                prefix="HOTELS_FTP_PORT"
            />
            <DetailItem
                message={ftpUsername}
                prefix="USERS_SINGULAR"
            />
            <DetailItem
                message={data.pmsPassword}
                prefix="GENERAL_PASSWORD"
            />
            <DetailItem
                message="/"
                prefix="HOTELS_FTP_DIRECTORY"
            />
        </DetailList>

        <DetailList
            isHidden={!hasBooking}
            message="HOTELS_BOOKING_ENGINE"
            collapsible="booking"
        >
            <DetailItem
                message={data.bookingProvider}
                prefix="HOTELS_BOOKING_ENGINE"
            />
            <DetailItem
                message={data.bookingHotel}
                prefix="HOTELS_BOOKING_HOTEL"
            />
            <DetailItem
                isHidden={!hasBookingToken}
                message={data.bookingAccessToken}
                prefix="HOTELS_BOOKING_TOKEN"
            />
            <DetailItem
                isHidden={!hasBookingRefresh}
                message={data.bookingRefreshToken}
                prefix="HOTELS_BOOKING_REFRESH"
            />
            <DetailItem
                message={data.bookingUrl}
                prefix="HOTELS_BOOKING_URL"
                url={data.bookingUrl}
                target="_blank"
            />
            <DetailItem
                isHidden={!hasBookingPromoCode}
                message={data.bookingPromoCode}
                prefix="HOTELS_BOOKING_PROMO_CODE"
            />
        </DetailList>
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HotelPreview.propTypes = {
    data : PropTypes.object.isRequired,
};

export default HotelPreview;
