import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import ConversationEdit     from "./Actions/ConversationEdit";
import NoteDetails          from "./Note/NoteDetails";
import NoteEdit             from "./Note/NoteEdit";
import HospitalityEdit      from "Components/App/Client/Hospitality/Dialogs/HospitalityEdit";
import OrderEdit            from "Components/App/Client/Store/Order/OrderEdit";
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";
import AccountAssign        from "Components/App/Client/Account/Dialogs/AccountAssign";
import AccountEdit          from "Components/App/Client/Account/Dialogs/AccountEdit";
import ConversationItem     from "Components/Utils/Details/ConversationItem";
import HospitalityItem      from "Components/Utils/Details/HospitalityItem";
import QuotationItem        from "Components/Utils/Details/QuotationItem";
import OrderItem            from "Components/Utils/Details/OrderItem";
import ContactItem          from "Components/Utils/Details/ContactItem";
import AccountsItem         from "Components/Utils/Details/AccountsItem";
import ConversationsItem    from "Components/Utils/Details/ConversationsItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



/**
 * The Conversation Details
 * @returns {React.ReactElement}
 */
function ConversationDetails() {
    const { conversationHash } = Navigate.useParams();

    const {
        canEdit, elem, contact, hospitality, quotation, order,
        accounts, conversations, customFields,
    } = Store.useState("conversation");

    const { triggerElemUpdate } = Store.useAction("conversation");
    const { closeDetails } = Store.useAction("core");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();
    const [ noteID, setNoteID ] = React.useState(0);


    // Handles the Edit
    const handleEdit = () => {
        triggerElemUpdate();
        endAction();
    };

    // Handles the Close
    const handleClose = (update) => {
        if (update) {
            triggerElemUpdate();
        }
        endAction();
    };

    // Handles the Note Open
    const handleNoteOpen = (noteID) => {
        setNoteID(noteID);
        endAction();
        closeDetails();
    };

    // Handles the Note Close
    const handleNoteClose = () => {
        endAction();
        setNoteID(0);
    };



    // Do the Render
    return <>
        <Details
            isEmpty={!conversationHash || !elem.id}
            collapsible="conversation"
            onAction={startAction}
            canEdit={canEdit}
        >
            <ConversationItem
                action={customFields.length ? "EDIT" : ""}
                elem={elem}
            />
            <HospitalityItem
                action="HOSPITALITY"
                elem={hospitality}
            />
            <QuotationItem
                elem={quotation}
            />
            <OrderItem
                action="ORDER"
                elem={order}
            />
            <ContactItem
                action="CONTACT"
                elem={contact}
            />
            <AccountsItem
                list={accounts}
                onItemAction={startAction}
            />
            <ConversationsItem
                list={conversations}
            />
            <NoteDetails
                onClick={handleNoteOpen}
            />
        </Details>

        <ConversationEdit
            open={action.isEdit}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <NoteEdit
            open={!!noteID}
            elemID={noteID}
            onClose={handleNoteClose}
        />

        <HospitalityEdit
            open={action.isHospitality}
            elemID={hospitality.id}
            conversationID={elem.id}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <OrderEdit
            open={action.isOrder}
            elemID={order.id}
            conversationID={elem.id}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ContactEdit
            open={action.isContact}
            elemID={contact.id}
            clientID={elem.clientID}
            conversationID={elem.id}
            onSubmit={handleEdit}
            onClose={handleClose}
        />

        <AccountAssign
            openAssign={action.isAssign}
            openUnassign={action.isUnassign}
            elemID={elemID}
            elemName={contact.fullName}
            contactID={contact.id}
            clientID={contact.clientID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <AccountEdit
            open={action.isAccount}
            elemID={elemID}
            clientID={contact.clientID}
            onSubmit={handleClose}
            onClose={endAction}
        />
    </>;
}

export default ConversationDetails;
