import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowNodeOutput       from "./FlowNodeOutput";
import FlowNodeIO           from "./FlowNodeIO";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div.attrs(({ isNotSelected, isFocused, isDragging, isNotAllowed, color }) => ({ isNotSelected, isFocused, isDragging, isNotAllowed, color }))`
    --node-color: ${(props) => props.isNotSelected ? "var(--light-gray)" : props.color};
    --node-square: ${(props) => props.isNotSelected ? "var(--dark-gray)" : "var(--black-color)"};
    --node-cursor: ${(props) => props.isDragging ? "move" : "grab"};

    position: absolute;
    top: 0;
    left: 0;
    width: 160px;
    background-color: white;
    border-radius: var(--border-radius);
    transform-origin: top left;
    z-index: ${(props) => props.isDragging ? 4 : 3};
    cursor: pointer;

    ${(props) => props.isFocused ? `
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.31) 0px 0px 1px,
            white 0 0 1px 2px,
            var(--primary-color) 0 0 1px 4px;
    ` : `
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.31) 0px 0px 1px;
    `}

    ${(props) => props.isNotAllowed && `
        opacity: 0.5;
    `}

    &.highlight {
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.31) 0px 0px 1px,
            white 0 0 1px 2px,
            var(--primary-color) 0 0 1px 4px;
    }
`;

const Header = Styled.header`
    height: 22px;
    padding: 0 4px 0 6px;
    color: var(--black-color);
    font-size: 12px;
    line-height: 22px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: var(--node-color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    cursor: var(--node-cursor);

    .icon {
        margin-right: 4px;
    }
`;

const Content = Styled.div`
    display: grid;
    grid-template-columns: 51px calc(100% - 51px);
    min-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 10px;
`;

const Input = Styled(FlowNodeIO)`
    margin-left: -6px;
`;

const Errors = Styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    background-color: var(--error-color);
    border-radius: var(--border-radius);
    transform: translate(25%, -25%);
`;

const Changes = Styled.div`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border: 1px solid white;
    background-color: var(--primary-color);
    border-radius: 9999px;
    transform: translate(25%, -25%);
`;



/**
 * The Flow Node
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowNode(props) {
    const { elem, posX, posY, isCreating, isDragging } = props;

    const { publishErrors } = Store.useState("flowEditor");
    const { inPublish, selectedNodes, selectedEdge } = Store.useState("flowState");
    const { currentNodeID } = Store.useState("flowSimulation");


    // Variables
    const isNotSelected = Boolean(selectedNodes.length && !selectedNodes.includes(elem.id));
    const isFocused     = currentNodeID === elem.id;
    const style         = { transform : `translate(${posX}px, ${posY}px)` };
    const errorCount    = Utils.getValue(publishErrors, "id", elem.id, "total");
    const showError     = Boolean(inPublish && errorCount > 0);
    const showChanges   = Boolean(!showError && elem.hasChanges);


    // Do the Render
    return <Container
        className={`flow-node-${elem.id}`}
        style={style}
        color={elem.color}
        isNotSelected={isNotSelected}
        isFocused={isFocused}
        isDragging={isCreating || isDragging}
        isNotAllowed={!elem.isAllowed}
    >
        <Header data-action="grabNode" data-id={elem.id}>
            <Icon icon={elem.icon} />
            {NLS.get(elem.name || elem.message)}
        </Header>
        <Content data-action="selectNode" data-id={elem.id}>
            <div>
                {elem.hasInput && <Input
                    action="dropEdge"
                    id={elem.id}
                    isSelected={selectedEdge && selectedEdge.toNodeID === elem.id}
                    message="FLOWS_NODES_INPUT"
                />}
            </div>
            <FlowNodeOutput elem={elem} />
            {showError && <Errors>{errorCount}</Errors>}
            {showChanges && <Changes />}
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowNode.propTypes = {
    elem       : PropTypes.object.isRequired,
    posX       : PropTypes.number,
    posY       : PropTypes.number,
    isCreating : PropTypes.bool,
    isDragging : PropTypes.bool,
};

export default FlowNode;
