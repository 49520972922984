import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import AccountItem          from "Components/Utils/Details/AccountItem";
import AccountsItem         from "Components/Utils/Details/AccountsItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



/**
 * The Account Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountDetails(props) {
    const { startAction } = props;

    const { canEdit, elem, relations } = Store.useState("account");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    return <Details
        isHidden={!elem.id}
        collapsible="account"
        onAction={handleAction}
        canEdit={canEdit}
    >
        <AccountItem
            action="EDIT"
            elem={elem}
        />
        <AccountsItem
            list={relations}
            onItemAction={startAction}
            forContact
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountDetails.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default AccountDetails;
