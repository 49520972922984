import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Partner }           from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canImport  : false,
    canExport  : false,
    canFilter  : false,
    list       : [],
    total      : 0,
    elem       : {},
    countries  : [],
    provinces  : {},
    statuses   : [],
    filters    : {},
    hasFilters : false,
    sort       : {
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "PARTNER_LOADING" });
    },

    /**
     * Fetches the Partner List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        const data   = await Partner.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "PARTNER_LIST", data });
    },

    /**
     * Fetches a single Partner
     * @param {Function} dispatch
     * @param {Number}   partnerID
     * @returns {Promise}
     */
    async fetchElem(dispatch, partnerID) {
        const data = await Partner.getOne({ partnerID });
        dispatch({ type : "PARTNER_ELEM", data });
    },

    /**
     * Fetches the Partner Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await Partner.getEditData();
        dispatch({ type : "PARTNER_EDIT", data });
    },

    /**
     * Exports the Partners
     * @param {Function} dispatch
     * @param {Object}   filters
     * @returns {String}
     */
    exportPartners(dispatch, filters) {
        return Partner.export(filters);
    },

    /**
     * Edits/Creates a Partner
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editPartner(dispatch, data) {
        return Partner.edit(data);
    },

    /**
     * Deletes a Partner
     * @param {Function} dispatch
     * @param {Number}   partnerID
     * @returns {Promise}
     */
    deletePartner(dispatch, partnerID) {
        return Partner.delete({ partnerID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.contact         = Utils.concat(" ", elem.firstName, elem.lastName);
    elem.addressText     = Utils.concat(" - ", elem.countryName, elem.provinceName, elem.locality, elem.address, elem.zipCode);
    elem.clientsText     = NLS.pluralize("PARTNERS_CLIENTS", elem.clientsCount);
    elem.commissionText  = `${elem.commission}%`;
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "PARTNER_LIST", "PARTNER_ELEM", "PARTNER_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "PARTNER_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "PARTNER_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            canFilter  : action.data.canFilter,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            filters    : action.data.filters,
            hasFilters : !Utils.isEmpty(action.data.filters),
            sort       : action.data.sort,
        };

    case "PARTNER_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            canEdit    : action.data.canEdit,
            elem       : parseElem(action.data.elem),
            countries  : action.data.countries,
            provinces  : action.data.provinces,
            statuses   : action.data.statuses,
        };

    case "PARTNER_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            countries  : action.data.countries,
            provinces  : action.data.provinces,
            statuses   : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
