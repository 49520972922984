import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Search
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeSearch(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { data, errors } = Store.useState("storeOption");


    // Do the Render
    const showSearch = Number(data.showSearch);

    return <>
        <InputField
            type="toggle"
            name="showSearch"
            label="PERSONALIZE_SHOW_SEARCH"
            value={Number(data.showSearch)}
            onChange={onChange}
            withBorder
        />

        <InputField
            isHidden={!showSearch}
            type="toggle"
            name="rightSearch"
            label="PERSONALIZE_RIGHT_SEARCH"
            value={Number(data.rightSearch)}
            onChange={onChange}
            withBorder
        />
        <InputField
            isHidden={!showSearch}
            type="color"
            name="searchBgColor"
            label="PERSONALIZE_SEARCH_BG_COLOR"
            value={data.searchBgColor}
            error={errors.searchBgColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("searchBgColor")}
        />
        <InputField
            isHidden={!showSearch}
            type="color"
            name="searchTextColor"
            label="PERSONALIZE_SEARCH_TEXT_COLOR"
            value={data.searchTextColor}
            error={errors.searchTextColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("searchTextColor")}
        />

        <InputField
            isHidden={!showSearch}
            name="searchPlaceholder"
            label="PERSONALIZE_SEARCH_PLACEHOLDER"
            value={getText("searchPlaceholder")}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("searchPlaceholder")}
        />
        <InputField
            isHidden={!showSearch}
            name="searchResultsTitle"
            label="PERSONALIZE_SEARCH_RESULTS_TITLE"
            value={getText("searchResultsTitle")}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("searchResultsTitle")}
        />
        <InputField
            isHidden={!showSearch}
            name="searchNoneResults"
            label="PERSONALIZE_SEARCH_NONE_RESULTS"
            value={getText("searchNoneResults")}
            onChange={onChange}
            onClear={onClear}
            forceClear={forceClear("searchNoneResults")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeSearch.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default PersonalizeSearch;
