import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserEdit(props) {
    const { open, elemID, clientID, partnerID, onClose, onSubmit } = props;

    const { isAnyAdmin } = Store.useState("auth");
    const { elem, editElem, colors, accesses, statuses } = Store.useState("user");
    const { fetchEditData, editUser } = Store.useAction("user");


    // The Initial Data
    const initialData = {
        userID        : 0,
        clientID      : 0,
        partnerID     : 0,
        credentialID  : 0,
        access        : "",
        firstName     : "",
        lastName      : "",
        email         : "",
        phone         : "",
        password      : "",
        reqPassChange : 0,
        color         : "#f2f2f2",
        observations  : "",
        status        : "Active",
    };

    // Handles the Set
    const handleSet = () => {
        if (editElem.id) {
            setElem({ ...editElem });
        } else {
            setElem({ ...initialData, clientID });
        }
    };

    // Handles the Final Submit
    const handleFinalSubmit = (response) => {
        const sameElem = !elem.id || elem.id === editElem.id;
        onSubmit(sameElem ? response.teamID : 0, response);
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("user", initialData, editUser, handleFinalSubmit);

    // Load the Data
    const { loading } = useDialog("user", open, elemID, { elemID, clientID, partnerID }, handleSet, fetchEditData);



    // Do the Render
    const canEditAll = isAnyAdmin && !editElem.isPending;

    return <EditDialog
        open={open}
        icon="user"
        title="USERS_EDIT_TITLE"
        message={NLS.format("USERS_EDIT_TEXT", editElem.displayName)}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            error={errors.access}
            onChange={handleChange}
            isRequired
        />

        <InputField
            isHidden={!canEditAll}
            name="firstName"
            label="GENERAL_FIRST_NAME"
            value={data.firstName}
            error={errors.firstName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!canEditAll}
            name="lastName"
            label="GENERAL_LAST_NAME"
            value={data.lastName}
            error={errors.lastName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!canEditAll}
            type="email"
            name="email"
            label="GENERAL_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!canEditAll}
            type="tel"
            name="phone"
            label="GENERAL_CELLPHONE"
            value={data.phone}
            onChange={handleChange}
            autoComplete="new-password"
        />
        <InputField
            isHidden={!canEditAll}
            type="password"
            name="password"
            label="GENERAL_PASSWORD"
            value={data.password}
            error={errors.password}
            onChange={handleChange}
            autoComplete="new-password"
            generateCode
        />
        <InputField
            isHidden={!canEditAll}
            type="toggle"
            name="reqPassChange"
            label="GENERAL_REQ_PASS_CHANGE"
            value={!!data.reqPassChange}
            onChange={handleChange}
            withBorder
        />

        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserEdit.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    elemID    : PropTypes.number,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default UserEdit;
