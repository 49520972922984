import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import WidgetHeader         from "./View/WidgetHeader";
import WidgetDialogs        from "./View/WidgetDialogs";
import WidgetPreview        from "./Dialogs/WidgetPreview";
import WidgetOptions        from "./Options/WidgetOptions";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



// Styles
const Container = Styled.main`
    display: grid;
    grid-template-columns: 1fr var(--widget-width);
    gap: var(--main-gap);

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

const Preview = Styled(WidgetPreview)`
    box-sizing: border-box;
    max-height: var(--page-height);
    padding: 16px 16px 0 16px;
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: auto;

    @media screen and (max-width: 700px) {
        display: none;
    }
`;



/**
 * The Widget Page
 * @returns {React.ReactElement}
 */
function WidgetPage() {
    const { widgetID, widgetTab } = Navigate.useParams();

    const navigate = Hooks.useGoto();

    const { charging, edition, elem, options, optionsDraft, platformsDraft } = Store.useState("widget");
    const { fetchData, editOption } = Store.useAction("widget");

    const { tongueID, data } = Store.useState("widgetState");
    const { setTongueID, setInitialData, setInitialErrors, setData, setErrors } = Store.useAction("widgetState");


    // The References
    const timerRef = React.useRef(null);


    // Load the Data
    React.useEffect(() => {
        handleLoad(widgetID);
    }, [ widgetID ]);

    // Updates the Data
    React.useEffect(() => {
        if (elem.id) {
            setInitialData(elem.name, elem.status, optionsDraft);
        }
    }, [ edition ]);


    // Handle the Load
    const handleLoad = async (widgetID) => {
        const data = await fetchData(widgetID);
        if (!widgetTab) {
            navigate("SETTINGS", "WIDGETS", widgetID, "MAIN");
        }
        if (data.tongues.length > 0) {
            setTongueID(data.tongues[0].key);
        }
    };


    // Handles the Change
    const handleChange = (name, value) => {
        const newTongueID = Utils.isObject(data[name]) ? tongueID : 0;
        setData(name, value);

        Utils.setTimeout(timerRef, () => {
            handleSave(name, value, newTongueID);
        }, 1000);
    };

    // Handles the Color
    const handleColor = (name, value) => {
        const newTongueID = Utils.isObject(data[name]) ? tongueID : 0;
        setData(name, value);

        if (!Utils.isValidColor(value)) {
            return;
        }
        Utils.setTimeout(timerRef, () => {
            handleSave(name, value, newTongueID);
        }, 1000);
    };

    // Handles the Clear
    const handleClear = (name) => {
        if (Utils.isObject(data[name])) {
            handleChange(name, options[name][tongueID] || "");
        } else {
            handleChange(name, options[name] || "");
        }
    };

    // Returns true if the Clear is forced for the given Option
    const forceClear = (name) => {
        if (Utils.isObject(data[name])) {
            return data[name] && options[name] && data[name][tongueID] !== options[name][tongueID];
        }
        return data[name] !== options[name];
    };

    // Handles the Save
    const handleSave = async (name, value, newTongueID = 0) => {
        try {
            setInitialErrors();
            await editOption(widgetID, name, value, newTongueID);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Returns the Text for the given Option
    const getText = (name) => {
        if (data[name] && Utils.isObject(data[name])) {
            return data[name][tongueID] || "";
        }
        return "";
    };



    // Do the Render
    return <>
        <Main withNavigation>
            <WidgetHeader />

            <Content isLoading={charging}>
                <Container>
                    <WidgetOptions
                        onChange={handleChange}
                        onColor={handleColor}
                        onClear={handleClear}
                        forceClear={forceClear}
                        getText={getText}
                    />
                    <Preview
                        options={data}
                        platforms={platformsDraft}
                        getText={getText}
                    />
                </Container>
            </Content>
        </Main>

        <WidgetDialogs
            data={data}
            tongueID={tongueID}
        />
    </>;
}

export default WidgetPage;
