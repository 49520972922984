import React                from "react";
import PropTypes            from "prop-types";
import Hooks                from "Utils/Hooks";

// Components
import DashboardPersonalize from "../Utils/DashboardPersonalize";

// Dashboard
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionList           from "Dashboard/Components/Header/ActionList";



/**
 * The Reports Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReportsHeader(props) {
    const { isHidden } = props;

    const clientID  = Hooks.useClientID();
    const partnerID = Hooks.usePartnerID();

    // The Current State
    const [ dialog, setDialog ] = React.useState("");


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ActionList>
            <ActionItem
                icon="personalize"
                message="PERSONALIZE_NAME"
                onClick={() => setDialog("personalize")}
            />
        </ActionList>

        <DashboardPersonalize
            open={dialog === "personalize"}
            clientID={clientID}
            partnerID={partnerID}
            onClose={() => setDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReportsHeader.propTypes = {
    isHidden : PropTypes.bool,
};

export default ReportsHeader;
