import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import AccountHeader        from "./AccountHeader";
import AccountDetails       from "./AccountDetails";
import AccountDialogs       from "./AccountDialogs";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Account List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("account", type);

    const { accountID } = Navigate.useParams();
    const clientID      = Hooks.useClientID();
    const navigate      = Hooks.useGoto();

    const data = Store.useState("account");
    const { elem, loading, canEdit, list, total, filters, sort } = data;

    const { openDetails } = Store.useAction("core");
    const { exportAccounts } = Store.useAction("account");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Add the Hooks for the Details
    Hooks.useItemDetails("account", accountID);

    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isView) {
            endAction();
            if (elemID !== accountID) {
                navigate("ACCOUNTS", elemID);
            } else {
                openDetails();
            }
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = exportAccounts(clientID, filters);
            await Utils.download(source, "accounts.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };


    // Variables
    const withNavigation = type === "CLIENT";
    const withDetails    = !!elem.id;


    // Do the Render
    return <>
        <Main withNavigation={withNavigation} withDetails={withDetails}>
            <AccountHeader
                startAction={handleAction}
                isExporting={exporting}
            />

            <Content>
                <Table
                    isLoading={loading}
                    fetch={load}
                    sort={sort}
                    none="ACCOUNTS_NONE_AVAILABLE"
                >
                    <TableHead>
                        <TableHeader field="name"           message="GENERAL_NAME"         isTitle isFlex smallSpace />
                        <TableHeader field="externalID"     message="ACCOUNTS_EXTERNAL_ID" maxWidth="140" align="center" />
                        <TableHeader field="relationsCount" message="CONTACTS_NAME"        maxWidth="100" />
                        <TableHeader field="createdTime"    message="GENERAL_CREATED_FEM"  maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((item) => <TableRow
                            key={item.accountID}
                            elemID={item.accountID}
                            isSelected={item.accountID === accountID}
                        >
                            <TableCell message={item.name}          />
                            <TableCell message={item.externalID}    />
                            <TableCell message={item.relationsText} />
                            <TableCell message={item.createdDate}   />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={handleAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="ACCOUNTS_VIEW_TITLE"           />
                        <TableAction action="EDIT"   message="ACCOUNTS_EDIT_TITLE"           />
                        <TableAction action="ASSIGN" message="ACCOUNTS_ASSIGN_CONTACT_TITLE" />
                        <TableAction action="DELETE" message="ACCOUNTS_DELETE_TITLE"         />
                    </TableActionList>
                </Table>
            </Content>
        </Main>

        <AccountDetails startAction={startAction} />

        <AccountDialogs
            action={action}
            elemID={elemID}
            endAction={endAction}
            fetch={fetch}
            loadFilter={loadFilter}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default AccountList;
