import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import HospitalityFilter    from "./Dialogs/HospitalityFilter";
import HospitalityEdit      from "./Dialogs/HospitalityEdit";
import HospitalityImport    from "./Dialogs/HospitalityImport";
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";
import ConversationCreate   from "Components/App/Client/Conversation/Actions/ConversationCreate";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Hospitality Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityDialogs(props) {
    const { action, elemID, endAction, fetch, loadFilter } = props;

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const { list, filters, elem } = Store.useState("hospitality");
    const { fetchElem, removeElem, deleteHospitality } = Store.useAction("hospitality");


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
        removeElem();
    };

    // Handles the Edit Submit
    const handleEdit = (hospitalityID) => {
        fetch();
        if (hospitalityID === elem.id) {
            fetchElem(hospitalityID);
        } else if (hospitalityID) {
            navigate("HOSPITALITIES", hospitalityID);
        }
        endAction();
    };

    // Handles the Contact Submit
    const handleContact = () => {
        fetch();
        if (elemID === elem.id) {
            fetchElem(elem.id);
        }
        endAction();
    };

    // Handles the Conversation Submit
    const handleConversation = () => {
        fetch();
        fetchElem(elem.id);
        endAction();
    };

    // Handles the Import Submit
    const handleImport = () => {
        fetch();
        endAction();
    };

    // Handles the Edit Close
    const handleClose = (update) => {
        if (update) {
            fetch();
            fetchElem(elem.id);
        }
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteHospitality(elemID);
        if (result.success) {
            fetch();
            if (elemID === elem.id) {
                removeElem();
            }
        }
        endAction();
    };


    // Variables
    const listElem = Utils.getValue(list, "hospitalityID", elemID);
    const currElem = listElem.id ? listElem : elem;


    // Do the Render
    return <>
        <HospitalityFilter
            open={action.isFilter}
            filters={filters}
            clientID={clientID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <HospitalityEdit
            open={action.isCE}
            elemID={action.isCreate ? 0 : elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <HospitalityImport
            open={action.isImport}
            clientID={clientID}
            onSubmit={handleImport}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="HOSPITALITY_DELETE_TITLE"
            message="HOSPITALITY_DELETE_TEXT"
            content={currElem.name}
            onSubmit={handleDelete}
            onClose={endAction}
        />

        <ContactEdit
            open={action.isContact}
            elemID={currElem.contactID}
            clientID={currElem.clientID}
            hospitalityID={currElem.id}
            onSubmit={handleContact}
            onClose={handleClose}
        />
        <ConversationCreate
            open={action.isConversation}
            clientID={clientID}
            contactID={currElem.contactID || 0}
            onSubmit={handleConversation}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityDialogs.propTypes = {
    action     : PropTypes.object.isRequired,
    elemID     : PropTypes.number,
    endAction  : PropTypes.func.isRequired,
    fetch      : PropTypes.func.isRequired,
    loadFilter : PropTypes.func.isRequired,
};

export default HospitalityDialogs;
