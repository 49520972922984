import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Admin Menu
 * @returns {React.ReactElement}
 */
function AdminMenu() {
    const { isAnyAdmin, isSupport } = Store.useState("auth");
    const { closeMenu } = Store.useAction("core");


    // Do the Render
    if (!isAnyAdmin) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="DASHBOARD"
            icon="dashboard"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="CLIENTS"
            icon="client"
            message="CLIENTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="PARTNERS"
            icon="partner"
            message="PARTNERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="SUBSCRIPTIONS"
            icon="subscription"
            message="SUBSCRIPTIONS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="TICKETS"
            icon="ticket"
            message="TICKETS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="DOCUMENTS"
            icon="document"
            message="DOCUMENTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />

        <BarIcon
            isHidden={isSupport}
            url="SETTINGS"
            icon="settings"
            message="GENERAL_SETTINGS"
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={isSupport}
            url="LOGS"
            icon="logs"
            message="GENERAL_LOGS"
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default AdminMenu;
