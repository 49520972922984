import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Buttons
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeButtons(props) {
    const { onColor, onClear, forceClear } = props;

    const { data, errors } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            type="color"
            name="buttonBgColor"
            label="PERSONALIZE_BUTTON_BG_COLOR"
            value={data.buttonBgColor}
            error={errors.buttonBgColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("buttonBgColor")}
        />
        <InputField
            type="color"
            name="buttonTextColor"
            label="PERSONALIZE_BUTTON_TEXT_COLOR"
            value={data.buttonTextColor}
            error={errors.buttonTextColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("buttonTextColor")}
        />

        <InputField
            type="color"
            name="buyBgColor"
            label="PERSONALIZE_BUY_BG_COLOR"
            value={data.buyBgColor}
            error={errors.buyBgColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("buyBgColor")}
        />
        <InputField
            type="color"
            name="buyTextColor"
            label="PERSONALIZE_BUY_TEXT_COLOR"
            value={data.buyTextColor}
            error={errors.buyTextColor}
            onChange={onColor}
            onClear={onClear}
            forceClear={forceClear("buyTextColor")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeButtons.propTypes = {
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
};

export default PersonalizeButtons;
