// spell-checker: ignore configuracion, codigo, categorias, numeros, telefonos, envios, paises
const urls = {

    // General Urls
    NONE                : "",
    ADD                 : "agregar",
    CREATE              : "crear",
    EDIT                : "editar",
    REPORT              : "reporte",
    REPORTS             : "reportes",

    // Auth Urls
    LOGIN               : "ingresar",
    PASS_RECOVER        : "recuperar",
    PASS_CODE           : "codigo",
    PASS_RESET          : "resetear",
    PASS_RESET_CODE     : "resetear/:code",
    PASS_CHANGE         : "cambiar",
    JOIN_ORGANIZATION   : "join/:code",
    AUTHORIZED          : "authorized/:code",

    // Profile Urls
    PROFILE             : "perfil",
    ORGANIZATIONS       : "organizaciones",

    // Dashboard Urls
    DASHBOARD           : "dashboard",
    DASHBOARD_TAB       : "dashboard/:dashboardTab",
    MONITORING          : "monitoreo",

    // Admin Urls
    CLIENTS             : "clientes",
    CLIENT              : "clientes/:clientID",
    CLIENT_STORES       : "clientes/:clientID/tiendas",
    CLIENT_SETTINGS     : "clientes/:clientID/configuracion",
    SUBSCRIPTIONS       : "suscripciones",

    // Partner Urls
    PARTNERS            : "partners",
    PARTNER             : "partners/:partnerID",
    PARTNER_CLIENT      : "partners/:partnerID/clientes/:clientID",
    PARTNER_STORES      : "partners/:partnerID/clientes/:clientID/tiendas",
    PARTNER_SETTINGS    : "partners/:partnerID/clientes/:clientID/configuracion",

    // Config Urls
    TAGS                : "etiquetas",
    CUSTOM_FIELDS       : "campos",
    PHONE_NUMBERS       : "numeros-telefonos",

    // Conversation Urls
    CONVERSATIONS       : "conversaciones",
    CONVERSATION_TAB    : "conversaciones/:conversationTab",
    CONVERSATION        : "conversaciones/:conversationTab/:conversationHash",
    QUEUE               : "cola",
    PROGRESS            : "progreso",
    FOLLOWUP            : "seguimiento",
    RESOLVED            : "resueltas",
    IN_FLOW             : "flujo",

    // Store Urls
    STORES              : "tiendas",
    STORE               : ":storeID",
    STORE_TAB           : ":storeID/:storeTab",
    STORE_SUB           : ":storeID/:storeTab/:storeSubID",
    LIST                : "lista",
    ORDERS              : "pedidos",
    PRODUCTS            : "productos",
    CATEGORIES          : "categorias",
    BANNERS             : "banners",
    PERSONALIZE         : "personalizar",

    // Contact Urls
    CONTACTS            : "contactos",
    CONTACT             : "contactos/:contactID",

    // Hospitality Urls
    HOSPITALITIES       : "hospitality",
    HOSPITALITY         : "hospitality/:hospitalityID",

    // Account Urls
    ACCOUNTS            : "cuentas",
    ACCOUNT             : "cuentas/:accountID",

    // Campaign Urls
    CAMPAIGNS           : "campanas",
    CAMPAIGN            : "campanas/:campaignID",
    CAMPAIGN_TAB        : "campanas/:campaignID/:campaignTab",
    DISPATCHES          : "envios",

    // User Urls
    USERS               : "usuarios",
    USER                : "usuarios/:userID",

    // Team Urls
    TEAMS               : "equipos",
    TEAM                : "equipos/:teamID",

    // Message Template Urls
    MSG_TEMPLATES       : "plantillas-mensajes",
    MSG_TEMPLATE        : "plantillas-mensajes/:msgTemplateID",
    MSG_TEMPLATE_ADD    : "plantillas-mensajes/crear",
    MSG_TEMPLATE_COPY   : "plantillas-mensajes/crear/:msgTemplateID",

    // Questionnaire Urls
    QUESTIONNAIRES      : "preguntas-frecuentes",
    QUESTIONNAIRE       : "preguntas-frecuentes/:questionnaireID",
    QUESTIONNAIRE_ADD   : "preguntas-frecuentes/crear",
    QUESTIONNAIRE_COPY  : "preguntas-frecuentes/crear/:questionnaireID",

    // Schedule Urls
    SCHEDULES           : "horarios",
    SCHEDULE            : "horarios/:scheduleID",
    SCHEDULE_ADD        : "horarios/crear",
    SCHEDULE_COPY       : "horarios/crear/:scheduleID",

    // Hotel Urls
    HOTELS              : "hoteles",
    HOTEL               : "hoteles/:hotelID",
    HOTELS_ADD          : "hoteles/agregar",

    // Channel Urls
    CHANNELS            : "canales",
    CHANNELS_ADD        : "canales/agregar",

    // WABA Template Urls
    WABA_TEMPLATES      : "plantillas-waba",
    WABA_TEMPLATE       : "plantillas-waba/:wabaTemplateID",
    WABA_TEMPLATE_ADD   : "plantillas-waba/crear",
    WABA_TEMPLATE_COPY  : "plantillas-waba/crear/:wabaTemplateID",

    // Assistants AI Urls
    ASSISTANTS          : "asistentes",
    ASSISTANT           : "asistentes/:assistantID",
    ASSISTANT_ADD       : "asistentes/crear",
    ASSISTANTS_MODELS   : "modelos-asistentes",

    // Widget Urls
    WIDGETS             : "widgets",
    WIDGET              : "widgets/:widgetID",
    WIDGET_TAB          : "widgets/:widgetID/:widgetTab",
    MAIN                : "principal",
    CHAT                : "chat",
    PLATFORMS           : "plataformas",
    ICON                : "icono",

    // Flow Urls
    FLOWS               : "flujos",
    FLOW_ONE            : "flujo",
    FLOW                : "flujos/:flowID",
    FLOW_TAB            : "flujos/:flowID/:flowTab",

    // Integration Urls
    INTEGRATIONS        : "integraciones",
    INTEGRATION         : "integraciones/:integrationID",
    INTEGRATION_TAB     : "integraciones/:integrationID/:integrationTab",
    EXECUTIONS          : "ejecuciones",

    // Connector Urls
    CONNECTORS          : "conectores",
    CONNECTOR           : "conectores/:connectorID",
    CONNECTOR_ADD       : "conectores/crear",

    // Ticket Urls
    TICKETS             : "tickets",
    TICKET_TAB          : "tickets/:ticketTab",
    TICKET              : "tickets/:ticketTab/:ticketID",
    TICKET_TYPES        : "tipos-tickets",
    WAITLISTS           : "listas-espera",
    OPEN                : "abiertos",
    WAITING             : "esperando",
    CLOSED              : "cerrados",

    // Document Urls
    DOCUMENTS           : "documentos",
    DOCUMENTS_TYPE      : "documentos/:documentLanguage/:documentType",
    DOCUMENT            : "documentos/:documentLanguage/:documentType/:documentSlug",

    // Setup Urls
    SETTINGS            : "configuracion",
    ADMINS              : "admins",
    PLANS               : "planes",
    ADDONS              : "addons",
    CURRENCIES          : "monedas",
    LANGUAGES           : "idiomas",
    COUNTRIES           : "paises",
    PROVINCES           : "provincias",
    MEDIA               : "archivos",

    // Email Urls
    EMAIL_TEMPLATES     : "plantillas-emails",
    EMAIL_QUEUE         : "cola-emails",
    EMAIL_WHITE_LIST    : "emails-blancos",

    // Log Urls
    LOGS                : "logs",
    LOG_NOTIFICATIONS   : "log-notificaciones",
    LOG_PROVIDERS       : "log-proveedores",
    LOG_PROVIDER        : "log-proveedores/:logID",
    LOG_CONNECTORS      : "log-conectores",
    LOG_CONNECTOR       : "log-conectores/:logID",
    LOG_ASSISTANTS      : "log-asistentes",
    LOG_ASSISTANT       : "log-asistentes/:logID",
    LOG_ACTIONS         : "log-acciones",
    LOG_QUERIES         : "log-queries",
    LOG_QUERY           : "log-queries/:logID",
    LOG_ERRORS          : "log-errores",
    LOG_ERROR           : "log-errores/:logID",

};

export default urls;
