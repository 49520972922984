import NLS                  from "Dashboard/Core/NLS";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// The API
import {
    Account, AccountIO,
} from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    canFilter    : false,
    list         : [],
    total        : 0,
    elem         : { id : 0 },
    relations    : [],
    editElem     : {},
    customFields : [],
    filters      : {},
    hasFilters   : false,
    sort         : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ACCOUNT_LOADING" });
    },

    /**
     * Fetches the Account List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await Account.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "ACCOUNT_LIST", data });
    },

    /**
     * Fetches a single Account
     * @param {Function} dispatch
     * @param {Number}   accountID
     * @returns {Promise}
     */
    async fetchElem(dispatch, accountID) {
        const data = await Account.getOne({ accountID });
        dispatch({ type : "ACCOUNT_ELEM", data });
    },

    /**
     * Removes the single Contact
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "ACCOUNT_REMOVE" });
    },

    /**
     * Fetches the Account Edit data
     * @param {Function} dispatch
     * @param {Number}   accountID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, accountID, clientID) {
        const data = await Account.getEditData({ accountID, clientID });
        dispatch({ type : "ACCOUNT_EDIT", data });
    },

    /**
     * Creates an Account
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createAccount(dispatch, data) {
        return Account.create(data);
    },

    /**
     * Edits the given Account
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editAccount(dispatch, data) {
        return Account.edit(data);
    },

    /**
     * Assigns an Account to a Contact
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    assignAccount(dispatch, data) {
        return Account.assign(data);
    },

    /**
     * Unassigns an Account from a Contact
     * @param {Function} dispatch
     * @param {Number}   accountRelationID
     * @returns {Promise}
     */
    unassignAccount(dispatch, accountRelationID) {
        return Account.unassign({ accountRelationID });
    },

    /**
     * Deletes a Account
     * @param {Function} dispatch
     * @param {Number}   accountID
     * @returns {Promise}
     */
    deleteAccount(dispatch, accountID) {
        return Account.delete({ accountID });
    },


    /**
     * Exports the Accounts
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Object}   filters
     * @returns {String}
     */
    exportAccounts(dispatch, clientID, filters) {
        return AccountIO.export({ ...filters, clientID });
    },

    /**
     * Uploads the Import File
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    uploadImport(dispatch, data) {
        return AccountIO.uploadImport(data);
    },

    /**
     * Imports the Accounts
     * @param {Function} dispatch
     * @param {Object}   data
     * @param {Object}   controller
     * @returns {Promise}
     */
    importAccounts(dispatch, data, controller) {
        return AccountIO.import(data, controller);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate   = DateTime.formatDate(elem.createdTime, "dashes");
    elem.relationsText = NLS.pluralize("ACCOUNTS_CONTACTS", elem.relationsCount);

    Commons.parseFields(elem);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ACCOUNT_LIST", "ACCOUNT_ELEM", "ACCOUNT_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ACCOUNT_LOADING":
        return {
            ...state,
            loading      : true,
        };

    case "ACCOUNT_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            canFilter    : action.data.canFilter,
            list         : Utils.parseList(action.data.list, parseElem),
            total        : action.data.total,
            filters      : action.data.filters,
            hasFilters   : !Utils.isEmpty(action.data.filters),
            sort         : action.data.sort,
        };

    case "ACCOUNT_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : parseElem(action.data.elem),
            relations    : action.data.relations,
            customFields : action.data.customFields,
        };

    case "ACCOUNT_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            editElem     : parseElem(action.data.elem),
            customFields : action.data.customFields,
        };

    case "ACCOUNT_REMOVE":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : { ...initialState.elem },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
