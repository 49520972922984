import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Conversation Summarize Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationSummarize(props) {
    const { open, clientID, conversationID, onSubmit, onClose } = props;

    const { assistants } = Store.useState("conversation");
    const { summarizeConversation } = Store.useAction("conversation");


    // The Initial Data
    const initialData = {
        assistantID  : "",
        prompt       : "",
        includeNotes : 0,
    };

    // Handles the Set
    const handleSet = (data) => {
        setElem({
            ...data,
            assistantID : assistants[0]?.key || 0,
        });
    };

    // Handles the Edit
    const handleEdit = () => {
        return summarizeConversation({ ...data, conversationID });
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, handleEdit, onSubmit);

    // Load the Data
    useDialog("conversation", open, 0, {
        clientID, conversationID,
    }, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="summary"
        title="CONVERSATIONS_SUMMARIZE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="select"
            name="assistantID"
            label="ASSISTANTS_SINGULAR"
            options={assistants}
            value={data.assistantID}
            error={errors.assistantID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="prompt"
            label="FLOWS_NODES_SUMMARIZE_PROMPT"
            helperText="FLOWS_NODES_SUMMARIZE_PROMPT_TIP"
            value={data.prompt}
            error={errors.prompt}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="includeNotes"
            label="FLOWS_NODES_SUMMARIZE_NOTES"
            value={data.includeNotes}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationSummarize.propTypes = {
    open           : PropTypes.bool.isRequired,
    clientID       : PropTypes.number.isRequired,
    conversationID : PropTypes.number.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    onClose        : PropTypes.func.isRequired,
};

export default ConversationSummarize;
