import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import PDFViewer            from "Dashboard/Components/Media/PDFViewer";
import Icon                 from "Dashboard/Components/Common/Icon";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`;

const Content = Styled.div`
    position: relative;
    padding: 16px;
    background-color: #e5ddd5;
    border-radius: var(--border-radius);

    ::before {
        background: url("https://static.xx.fbcdn.net/rsrc.php/v4/yb/r/rmr3BrOAAd8.png");
        background-size: 366.5px 666px;
        content: '';
        height: 100%;
        left: 0;
        opacity: .06;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

const Message = Styled.div`;
    position: relative;
    background-color: white;
    border-radius: 7.5px;
    border-top-left-radius: 0;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
    min-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, Roboto, Arial, sans-serif;

    ::after {
        content: "";
        background: url("https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/cTpzmA9a2VH.png") 50% 50% no-repeat;
        background-size: contain;
        height: 19px;
        left: -12px;
        position: absolute;
        top: 0;
        width: 12px;
    }
`;

const Head = Styled.header`
    padding: 6px 7px 0 9px;
    font-size: 15px;
    font-weight: bold;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.76);

    :empty {
        display: none;
    }
`;

const Media = Styled.header`
    position: relative;
    top: 3px;
    margin: 3px 3px 0 3px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
`;

const MediaContent = Styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    aspect-ratio: 1.91;
    border-radius: 4px;
    background-color: #ccd0d5;
    overflow: hidden;

    .icon {
        display: flex;
        font-size: 90px;
        line-height: 1;
        color: white;
    }
`;

const Image = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Video = Styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PDF = Styled(PDFViewer)`
    width: 100%;
    max-width: 244px;
    overflow: hidden;
`;

const Map = Styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 2.5;
    overflow: hidden;

    iframe {
        display: block;
        width: 150%;
        height: 200%;
        position: absolute;
        bottom: -50%;
        left: -25%;
        pointer-events: none;
    }
`;

const Location = Styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 6px;
    gap: 4px;
    font-size: 10px;
    color: #32373b;
`;

const Body = Styled(Html)`
    color: #262626;
    font-size: 13.6px;
    line-height: 19px;
    margin: 0 7px 0 9px;
    padding: 7px 0 6px 0;
    overflow: hidden;
`;

const Foot = Styled.footer`
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
    line-height: 17px;
    padding: 0 7px 8px 9px;

    :empty {
        display: none;
    }
`;

const Time = Styled.time`
    display: block;
    padding: 0 7px 3px 0;
    color: rgba(0, 0, 0, 0.4);
    margin-top: -4px;
    text-align: right;
    font-size: 11px;
    line-height: 15px;
`;

const Buttons = Styled.section`
    padding: 0 8px;
    border-top: 1px solid #dadde1;
`;

const Button = Styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 44px;
    padding: 0 16px;
    color: #00a5f4;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;

    :not(:first-child) {
        border-top: 1px solid #dadde1;
    }
`;



/**
 * The WABA Template Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplatePreview(props) {
    const { clientID, getValue } = props;


    // Returns the Body Text
    const getHeaderText = () => {
        const headerText = getValue("headerText");
        const sampleList = [ getValue("headerSample") ];
        return Commons.replaceWabaVariables(headerText, sampleList);
    };

    // Returns the Body Text
    const getBodyText = () => {
        const bodyText   = getValue("bodyText", " ");
        const sampleList = getValue("sampleList", []);
        const result     = Commons.replaceWabaVariables(bodyText, sampleList);
        return Commons.formatWabaText(result);
    };


    // Variables
    const headerType     = getValue("headerType");
    const isText         = headerType === "TEXT";
    const isImage        = headerType === "IMAGE";
    const isVideo        = headerType === "VIDEO";
    const isDocument     = headerType === "DOCUMENT";
    const isLocation     = headerType === "LOCATION";
    const isMedia        = isImage || isVideo || isDocument || isLocation;
    const hasFile        = Boolean(getValue("headerFile"));
    const latitude       = getValue("headerLatitude");
    const longitude      = getValue("headerLongitude");
    const hasMap         = Boolean(latitude && longitude);
    const mapSource      = hasMap ? Utils.getGoogleMapEmbed(latitude, longitude, "", 14) : "";
    const fileUrl        = `${process.env.REACT_APP_FILES}${clientID}/${getValue("headerFile")}`;
    const bodyText       = getBodyText();
    const footerText     = getValue("footerText");
    const showLinksFirst = Boolean(getValue("showLinksFirst"));
    const buttonList     = JSON.parse(getValue("buttonList", "[]")).filter((elem) => !!elem.length);
    const buttonLinks    = JSON.parse(getValue("buttonLinks", "[]")).filter((elem) => !!elem.type);
    const totalButtons   = buttonList.length + buttonLinks.length;
    const filterButtons  = showLinksFirst ? buttonLinks.length : 0;
    const filterLinks    = !showLinksFirst ? buttonList.length : 0;
    const buttons        = buttonList.filter((elem, index) => index <= (totalButtons > 3 ? 1 : 2) - filterButtons);
    const links          = buttonLinks.filter((elem, index) => index <= (totalButtons > 3 ? 1 : 2) - filterLinks);
    const hasButtons     = totalButtons > 0;
    const hasMore        = totalButtons > 3;


    // Do the Render
    return <Details>
        <Title>{NLS.get("GENERAL_PREVIEW")}</Title>
        <Content>
            <Message>
                {isText && <Head>{getHeaderText()}</Head>}
                {isMedia && <Media>
                    <MediaContent>
                        {isImage && (!hasFile ? <Icon icon="image" /> : <Image src={fileUrl} />)}
                        {isVideo && (!hasFile ? <Icon icon="video" /> : <Video src={fileUrl} />)}
                        {isDocument && (!hasFile ? <Icon icon="document" /> : <PDF
                            source={fileUrl}
                            maxWidth={244}
                        />)}
                        {isLocation && (!hasMap ? <Icon icon="address" /> : <Map>
                            <iframe
                                title="Map"
                                width="560"
                                height="315"
                                src={mapSource}
                                frameBorder="0"
                                scrolling="no"
                            />
                        </Map>)}
                    </MediaContent>
                    {isLocation && <Location>
                        <b>{getValue("headerLocation", NLS.get("WABA_TEMPLATES_HEADER_LOCATION"))}</b>
                        <span>{getValue("headerAddress", NLS.get("WABA_TEMPLATES_HEADER_ADDRESS"))}</span>
                    </Location>}
                </Media>}
                <Body addBreaks>{bodyText}</Body>
                <Foot>{footerText}</Foot>
                <Time>6:45 PM</Time>

                {hasButtons && <Buttons>
                    {!showLinksFirst && buttons.map((text, index) => <Button key={index}>
                        <Icon icon="reply" />
                        {text}
                    </Button>)}
                    {links.map((elem, index) => <Button key={index}>
                        <Icon icon={elem.type === "URL" ? "link" : "phone"} />
                        {elem.text}
                    </Button>)}
                    {showLinksFirst && buttons.map((text, index) => <Button key={index}>
                        <Icon icon="reply" />
                        {text}
                    </Button>)}
                    {hasMore && <Button>
                        <Icon icon="list" />
                        {NLS.get("WABA_TEMPLATES_SEE_ALL_OPTIONS")}
                    </Button>}
                </Buttons>}
            </Message>
        </Content>
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplatePreview.propTypes = {
    clientID : PropTypes.number.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default WabaTemplatePreview;
