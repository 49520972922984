import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";



// The initial Data
const initialData = {
    name                    : "",
    status                  : "",
    fontFamily              : "",
    showStart               : 1,
    headerLeftAlign         : 0,
    logoFile                : "",
    showChat                : 1,
    flowID                  : 0,
    requestName             : 0,
    requestMessage          : 1,
    bigStartButton          : 0,
    showNames               : "none",
    autoStartChat           : 0,
    showChatLogo            : 0,
    showEmojiButton         : 1,
    showFileButton          : 1,
    showLocationButton      : 1,
    headerBgColor           : "",
    headerTextColor         : "",
    buttonBgColor           : "",
    buttonIconColor         : "",
    contactMessageBgColor   : "",
    contactMessageTextColor : "",
    userMessageBgColor      : "",
    userMessageTextColor    : "",
    bubbleButtonColor       : "",
    bubbleButtonHover       : "",
    showPlatforms           : 1,
    showMessageCount        : 0,
    iconShowFile            : 0,
    iconFile                : "",
    iconBgColor             : "",
    iconIconColor           : "",
    iconShowBorder          : 1,
    iconBorderColor         : "",
    iconShowShadow          : 1,
    iconSize                : 0,
    iconBottomSpace         : 0,
    showBubble              : 1,
    bubbleBgColor           : "",
    bubbleBorderColor       : "",
    bubbleTextColor         : "",
};
const initialTexts = {
    headerText         : {},
    bubbleText         : {},
    conversationText   : {},
    requestNameText    : {},
    requestMessageText : {},
    startButtonText    : {},
    restartText        : {},
    chatText           : {},
    sendText           : {},
    botName            : {},
    unreadText         : {},
    writingText        : {},
    platformsText      : {},
    connectText        : {},
    webLinkText        : {},
    qrCodeText         : {},
};
const initialErrors = {
    ...initialData,
    flowID : "",
};

// The initial State
const initialState = {
    action   : Action.get(),
    tongueID : 0,
    data     : { ...initialData },
    errors   : { ...initialErrors },
};



// The Actions
const actions = {
    /**
     * Sets the Widget action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "WIDGET_ACTION", action });
    },

    /**
     * Sets the Widget tongue
     * @param {Function} dispatch
     * @param {Number}   tongueID
     * @returns {Void}
     */
    setTongueID(dispatch, tongueID) {
        dispatch({ type : "WIDGET_TONGUE", tongueID });
    },

    /**
     * Sets the Widget initial data
     * @param {Function} dispatch
     * @param {String}   name
     * @param {Number}   status
     * @param {Object}   optionsDraft
     * @returns {Void}
     */
    setInitialData(dispatch, name, status, optionsDraft) {
        dispatch({ type : "WIDGET_INITIAL_DATA", name, status, optionsDraft });
    },

    /**
     * Sets the Widget initial errors
     * @param {Function} dispatch
     * @returns {Void}
     */
    setInitialErrors(dispatch) {
        dispatch({ type : "WIDGET_INITIAL_ERRORS" });
    },

    /**
     * Sets the Widget data
     * @param {Function} dispatch
     * @param {String}   name
     * @param {*}        value
     * @returns {Void}
     */
    setData(dispatch, name, value) {
        dispatch({ type : "WIDGET_DATA", name, value });
    },

    /**
     * Sets the Widget errors
     * @param {Function} dispatch
     * @param {Object}   errors
     * @returns {Void}
     */
    setErrors(dispatch, errors) {
        dispatch({ type : "WIDGET_ERRORS", errors });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "WIDGET_ACTION":
        return {
            ...state,
            action   : Action.get(action.action),
        };

    case "WIDGET_TONGUE":
        return {
            ...state,
            tongueID : action.tongueID,
        };

    case "WIDGET_INITIAL_DATA":
        return {
            ...state,
            data     : {
                ...initialData,
                name   : action.name,
                status : action.status,
                ...action.optionsDraft,
            },
            errors   : { ...initialErrors },
        };

    case "WIDGET_INITIAL_ERRORS":
        return {
            ...state,
            errors   : { ...initialErrors },
        };

    case "WIDGET_DATA":
        if (Utils.hasProp(initialTexts, action.name)) {
            return {
                ...state,
                data     : {
                    ...state.data,
                    [action.name] : {
                        ...state.data[action.name],
                        [state.tongueID] : action.value,
                    },
                },
            };
        }
        return {
            ...state,
            data     : {
                ...state.data,
                [action.name] : action.value,
            },
        };

    case "WIDGET_ERRORS":
        return {
            ...state,
            errors   : { ...action.errors },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
