import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useMedia             from "Dashboard/Hooks/Media";
import Hooks                from "Utils/Hooks";

// Components
import CampaignEmpty        from "../Utils/CampaignEmpty";
import CampaignTongues      from "../Utils/CampaignTongues";
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";
import TextField            from "Components/Utils/Inputs/TextField";
import EditorField          from "Components/Utils/Inputs/EditorField";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Campaign Email
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignEmail(props) {
    const { data, getOptionError, onChange } = props;

    const {
        elem, hasEmail, selects : { tongues, variables, channelLinks, emailChannels },
    } = Store.useState("campaign");


    // The Current State
    const [ tongueID, setTongueID ] = Hooks.useTongue(tongues);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();


    // Returns the Value
    const getValue = (name) => {
        return data.options[tongueID]?.[name] || "";
    };

    // Handles the Change with a Tongue
    const handleChangeTongue = (name, value) => {
        onChange(name, value, tongueID);
    };


    // Do the Render
    const emailActive = Utils.isActive(data.emailActive);

    if (!hasEmail) {
        return <CampaignEmpty
            waitMessage="CAMPAIGNS_EMAIL_WAIT"
            configureMessage="CAMPAIGNS_EMAIL_CONFIGURE"
        />;
    }
    return <>
        <DialogMessage
            variant="h3"
            message="CAMPAIGNS_EMAIL_TEXT"
        />
        <InputField
            type="toggle"
            name="emailActive"
            label="CAMPAIGNS_EMAIL_ACTIVATE"
            value={emailActive}
            error={getOptionError("emailActive")}
            onChange={onChange}
            withBorder
        />

        <Columns isHidden={!emailActive}>
            <InputField
                type="select"
                name="emailPriority"
                label="CAMPAIGNS_PRIORITY"
                options="SELECT_CAMPAIGN_PRIORITIES"
                value={data.emailPriority}
                error={getOptionError("emailPriority")}
                onChange={onChange}
                isRequired
            />
            <InputField
                type="select"
                name="emailChannelID"
                label="GENERAL_EMAIL_CHANNEL"
                options={emailChannels}
                value={data.emailChannelID}
                error={getOptionError("emailChannelID")}
                onChange={onChange}
                isRequired
            />
        </Columns>

        <CampaignTongues
            isHidden={!emailActive}
            tongueID={tongueID}
            setTongueID={setTongueID}
        />

        <TextField
            isHidden={!emailActive}
            name="emailSubject"
            label="GENERAL_SUBJECT"
            value={getValue("emailSubject")}
            error={getOptionError("emailSubject")}
            onChange={handleChangeTongue}
            variables={variables}
            isRequired
        />

        <EditorField
            isHidden={!emailActive}
            name="emailBody"
            value={getValue("emailBody")}
            error={getOptionError("emailBody")}
            onChange={handleChangeTongue}
            onMedia={handleMediaOpen}
            clientID={elem.clientID}
            variables={variables}
            channelLinks={channelLinks}
        />
        <MediaDialog
            open={showMedia}
            clientID={elem.clientID}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignEmail.propTypes = {
    data           : PropTypes.object.isRequired,
    getOptionError : PropTypes.func.isRequired,
    onChange       : PropTypes.func.isRequired,
};

export default CampaignEmail;
