import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Components
import AssistantHeader      from "./View/AssistantHeader";
import AssistantEditor      from "./View/AssistantEditor";
import AssistantItem        from "Components/Utils/Details/AssistantItem";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Details              from "Dashboard/Components/Details/Details";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Assistant Page
 * @returns {React.ReactElement}
 */
function AssistantPage() {
    const { assistantID } = Navigate.useParams();

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(assistantID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, module } = Store.useState("assistant");
    const { fetchElem, fetchEditData, editAssistant, deleteAssistant } = Store.useAction("assistant");


    // The Fields
    const files = {
        main  : [ "name", "assistantModelID", "description", "instructions", "status" ],
        files : [ "files" ],
    };

    // The Current State
    const [ showDelete,    setShowDelete    ] = React.useState(false);
    const [ showAssistant, setShowAssistant ] = React.useState(false);

    // The Initial Data
    const initialData = {
        clientID         : 0,
        assistantID      : 0,
        assistantModelID : 0,
        name             : "",
        description      : "",
        instructions     : "",
        status           : "Active",
        useByDefault     : 0,
        files            : "[{}]",
    };


    // Handles the Edit
    const handleEdit = (data) => {
        return editAssistant({ ...data, clientID });
    };

    // Handles the Change
    const handleFieldChange = (name, value) => {
        const files = { ...data.files, [name] : value };
        handleChange("files", files);
    };

    // Handles the Update
    const handleUpdate = () => {
        if (!isEdit || !showAssistant) {
            navigate(goto);
        }
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteAssistant(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange,
        resetData, resetErrors, handleSubmit,
    } = useForm("assistant", initialData, handleEdit, handleUpdate);


    // Load the Data
    React.useEffect(() => {
        if (assistantID) {
            fetchElem(assistantID);
        } else {
            fetchEditData(clientID);
        }
    }, [ assistantID ]);

    // Store the Data
    React.useEffect(() => {
        if (isEdit) {
            setElem({ ...elem, files : JSON.stringify(elem.files) });
        } else {
            resetErrors();
            resetData();
        }
    }, [ edition ]);


    // Returns the Error Count in the Step
    const getErrorCount = (step) => {
        return Commons.getErrorCount(files, errors, step);
    };



    // Do the Render
    return <>
        <Main withDetails={showAssistant} wideDetails withNavigation>
            <AssistantHeader
                data={data}
                href={goto}
                isEdit={isEdit}
                showAssistant={showAssistant}
                setShowAssistant={setShowAssistant}
            />

            <Content isLoading={charging || loading}>
                <AssistantEditor
                    loading={loading}
                    isEdit={isEdit}
                    clientID={clientID}
                    data={data}
                    errors={errors}
                    getErrorCount={getErrorCount}
                    onChange={handleChange}
                    onFieldChange={handleFieldChange}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </Content>
        </Main>

        <Details isHidden={!showAssistant} isWide>
            <AssistantItem
                module={module}
                parentID={elem.id}
                assistantID={elem.assistantID}
            />
        </Details>

        <DeleteDialog
            open={showDelete}
            title="ASSISTANTS_DELETE_TITLE"
            message="ASSISTANTS_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

export default AssistantPage;
