import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import IntegrationAction    from "./IntegrationAction";
import IntegrationEdge      from "./IntegrationEdge";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    padding: 24px;
    transform-origin: top center;
`;



/**
 * The Integration Canvas
 * @returns {React.ReactElement}
 */
function IntegrationCanvas() {
    const { hasTrigger, actions } = Store.useState("integrationEditor");

    const { openDetails } = Store.useAction("core");

    const { zoom } = Store.useState("integrationState");
    const { setCreate, setSelectedAction } = Store.useAction("integrationState");


    // Handles the Create
    const handleCreate = (e, position, forTrigger) => {
        setCreate(position, forTrigger);
        e.stopPropagation();
    };

    // Handles the Select
    const handleSelect = (e, actionID) => {
        setSelectedAction(actionID);
        openDetails();
        e.stopPropagation();
    };


    // Do the Render
    const hasAction = actions.length > 1 || (actions.length > 0 && !hasTrigger);
    const increment = !hasTrigger ? 1 : 0;

    return <Container
        style={{ transform : `scale(${zoom / 100})` }}
    >
        {!hasTrigger && <>
            <IntegrationAction
                actionID="trigger"
                icon="action"
                position="1"
                title="INTEGRATIONS_ACTIONS_TRIGGER_TITLE"
                message="INTEGRATIONS_ACTIONS_TRIGGER_TEXT"
                onClick={(e) => handleCreate(e, 1, true)}
                isAllowed
            />
            <IntegrationEdge
                onClick={(e) => handleCreate(e, 1)}
            />
        </>}

        {actions.map((item) => <React.Fragment key={item.id}>
            <IntegrationAction
                actionID={item.id}
                position={item.position + increment}
                icon={item.icon}
                color={item.color}
                title={item.title}
                message={item.description}
                isAllowed={item.isAllowed}
                hasChanges={item.hasChanges}
                onClick={(e) => handleSelect(e, item.id)}
            />
            <IntegrationEdge
                isLast={hasAction && actions.length === item.position}
                onClick={(e) => handleCreate(e, item.position + 1)}
            />
        </React.Fragment>)}

        {!hasAction && <IntegrationAction
            actionID="action"
            position="2"
            icon="action"
            title="GENERAL_ACTION"
            message="INTEGRATIONS_ACTIONS_ACTION_TEXT"
            onClick={(e) => handleCreate(e, 2)}
            isAllowed
        />}
    </Container>;
}

export default IntegrationCanvas;
