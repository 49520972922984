import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import CampaignMain         from "../Options/CampaignMain";
import CampaignTriggers     from "../Options/CampaignTriggers";
import CampaignConditions   from "../Options/CampaignConditions";
import CampaignChannels     from "../Options/CampaignChannels";

// Dashboard
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";



// Styles
const Container = Styled.section.attrs(({ hasTabs }) => ({ hasTabs }))`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: ${(props) => props.hasTabs ? "var(--page-height-tabs)" : "var(--page-height)"};

    ${(props) => props.hasTabs && `
        border-top: var(--border-width) solid var(--border-color-light);
        padding-top: 8px;
    `};
`;



/**
 * The Campaign Editor
 * @returns {React.ReactElement}
 */
function CampaignEditor() {
    const { elem, activateErrors } = Store.useState("campaign");
    const { editOption } = Store.useAction("campaign");

    const { step, inActivate, data, errors } = Store.useState("campaignState");
    const { setStep, setData, setErrors, removeErrors } = Store.useAction("campaignState");


    // The References
    const timerRef = React.useRef(null);


    // Handles the Change
    const handleChange = (name, value, tongueID = 0) => {
        setData(name, value, tongueID);

        Utils.setTimeout(timerRef, () => {
            handleSave(name, value, tongueID);
        }, 1000);
    };

    // Handles the Save
    const handleSave = async (name, value, tongueID = 0) => {
        try {
            removeErrors();
            await editOption(elem.campaignID, name, value, tongueID);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };


    // Returns the Section Error
    const getSectionError = (section) => {
        return inActivate ? Number(activateErrors[section] || 0) : 0;
    };

    // Returns the Option Error
    const getOptionError = (key) => {
        if (errors[key]) {
            return errors[key];
        }
        if (inActivate && activateErrors[key]) {
            return activateErrors[key];
        }
        return "";
    };

    // Returns the Current Error
    const getGlobalError = (errors, key, prefix, id) => {
        if (errors[key]) {
            return errors[key];
        }
        if (inActivate) {
            const globalKey = `${prefix}-${id || 0}-${key}`;
            if (id && activateErrors[globalKey]) {
                return activateErrors[globalKey];
            }
        }
        return "";
    };



    // Do the Render
    return <Container hasTabs={elem.hasTabs}>
        <PageAccordion
            selected={step}
            onChange={setStep}
        >
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="CAMPAIGNS_MAIN_TEXT"
                errorCount={getSectionError("main")}
            >
                <CampaignMain
                    data={data}
                    getOptionError={getOptionError}
                    onChange={handleChange}
                />
            </AccordionItem>

            <AccordionItem
                value="triggers"
                message="CAMPAIGNS_TRIGGERS_NAME"
                description="CAMPAIGNS_TRIGGERS_TEXT"
                errorCount={getSectionError("triggers")}
            >
                <CampaignTriggers
                    getOptionError={getOptionError}
                    getGlobalError={getGlobalError}
                />
            </AccordionItem>

            <AccordionItem
                value="conditions"
                message="CAMPAIGNS_CONDITIONS_NAME"
                description="CAMPAIGNS_CONDITIONS_TEXT"
                errorCount={getSectionError("conditions")}
            >
                <CampaignConditions
                    data={data}
                    getOptionError={getOptionError}
                    getGlobalError={getGlobalError}
                    onChange={handleChange}
                />
            </AccordionItem>

            <AccordionItem
                value="channels"
                message="CAMPAIGNS_CHANNELS_TITLE"
                description="CAMPAIGNS_CHANNELS_TEXT"
                errorCount={getSectionError("channels")}
            >
                <CampaignChannels
                    data={data}
                    getSectionError={getSectionError}
                    getOptionError={getOptionError}
                    onChange={handleChange}
                />
            </AccordionItem>
        </PageAccordion>
    </Container>;
}

export default CampaignEditor;
