import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Campaign             from "Utils/Campaign";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Button               from "Dashboard/Components/Form/Button";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div.attrs(({ hasDelete }) => ({ hasDelete }))`
    display: flex;
    align-items: center;
    gap: 12px;

    ${(elem) => !elem.hasDelete && `
        margin-right: calc(32px + 12px);
    `}
`;

const Content = Styled(Columns)`
    width: 100%;
`;

const Buttons = Styled.div`
    display: flex;
    gap: 12px;
`;



/**
 * The Campaign Trigger
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignTrigger(props) {
    const { item, setCreating, getGlobalError } = props;

    const { elem, selects } = Store.useState("campaign");
    const { createTrigger, editTrigger, deleteTrigger } = Store.useAction("campaign");


    // The References
    const timerRef = React.useRef(null);

    // The Initial Data
    const initialData = {
        campaignID    : elem.campaignID,
        triggerID     : 0,
        triggerName   : "",
        customFieldID : 0,
        amountType    : "",
        days          : "",
        hours         : "",
        minutes       : "",
        weekDay       : "",
        dayPosition   : "",
        time          : "",
        date          : "",
    };

    // The Initial Data
    const initialErrors = {
        triggerName   : "",
        customFieldID : "",
        amountType    : "",
        days          : "",
        hours         : "",
        minutes       : "",
        weekDay       : "",
        dayPosition   : "",
        time          : "",
        date          : "",
    };

    // The Current State
    const [ data,   setData   ] = React.useState({ ...initialData, ...item });
    const [ errors, setErrors ] = React.useState({ ...initialErrors });


    // Some Variables
    const isCreate        = !item.id;
    const showContactDate = Campaign.useHasContactDate(data.triggerName);
    const showTimeAmount  = Campaign.useHasTimeAmount(data.triggerName);
    const hasDays         = Campaign.useHasDays(data.triggerName);
    const showMinutes     = showTimeAmount && data.amountType === "Minutes";
    const showHours       = showTimeAmount && data.amountType === "Hours";
    const showDays        = hasDays || (showTimeAmount && data.amountType === "Days");
    const showWeekDay     = Campaign.useHasWeekDay(data.triggerName);
    const showDayPosition = Campaign.useHasDayPosition(data.triggerName);
    const showDayTime     = showContactDate;
    const showDate        = Campaign.useHasDate(data.triggerName);
    const showTime        = Campaign.useHasTime(data.triggerName);


    // Returns the Current Error
    const getError = (key) => {
        return getGlobalError(errors, key, "triggers", item.triggerID);
    };

    // Handles the Input Change
    const handleChange = (name, value) => {
        const newData = { ...data, [name] : value };
        setData(newData);
        setErrors({ ...errors, [name] : "" });

        if (isCreate) {
            return;
        }
        Utils.setTimeout(timerRef, () => handleEdit(newData), 1000);
    };

    // Handles the Create
    const handleCreate = async () => {
        try {
            setErrors({ ...initialErrors });
            await createTrigger(data);
            setCreating(false);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Edit
    const handleEdit = async (newData) => {
        try {
            setErrors({ ...initialErrors });
            await editTrigger(newData);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };


    // Do the Render
    return <>
        <Container hasDelete={!isCreate}>
            <Content amount="3">
                <InputField
                    type="select"
                    name="triggerName"
                    label="CAMPAIGNS_TRIGGERS_SINGULAR"
                    options={selects.triggers}
                    value={data.triggerName}
                    error={getError("triggerName")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!showContactDate}
                    type="select"
                    name="customFieldID"
                    label="GENERAL_FIELD"
                    options={selects.contactDateFields}
                    value={data.customFieldID}
                    error={getError("customFieldID")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showTimeAmount}
                    type="select"
                    name="amountType"
                    label="GENERAL_EXECUTE_AFTER"
                    options="SELECT_DATE_AMOUNT_TYPES"
                    value={data.amountType}
                    error={getError("amountType")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showMinutes}
                    type="number"
                    name="minutes"
                    label="GENERAL_MINUTES"
                    value={data.minutes}
                    error={getError("minutes")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showHours}
                    type="number"
                    name="hours"
                    label="GENERAL_HOURS"
                    value={data.hours}
                    error={getError("hours")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showDays}
                    type="number"
                    name="days"
                    label="GENERAL_DAYS"
                    value={data.days}
                    error={getError("days")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showWeekDay}
                    type="select"
                    name="weekDay"
                    label="GENERAL_DAY"
                    options="DATE_DAY_NAMES"
                    value={data.weekDay}
                    error={getError("weekDay")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showDayPosition}
                    type="double"
                    label="GENERAL_DAY"
                    onChange={handleChange}
                    error={getError("dayPosition")}
                >
                    <InputItem
                        type="select"
                        name="dayPosition"
                        options="DATE_DAY_POSITIONS"
                        value={data.dayPosition}
                    />
                    <InputItem
                        type="select"
                        name="weekDay"
                        options="DATE_DAY_NAMES"
                        value={data.weekDay}
                    />
                </InputField>
                <InputField
                    isHidden={!showDayTime}
                    type="double"
                    onChange={handleChange}
                    error={getError("days") || getError("time")}
                >
                    <InputItem
                        label="CAMPAIGNS_TRIGGERS_DAYS"
                        type="number"
                        name="days"
                        value={data.days}
                        shrinkLabel
                    />
                    <InputItem
                        label="GENERAL_HOUR"
                        type="time"
                        name="time"
                        value={data.time}
                    />
                </InputField>
                <InputField
                    isHidden={!showDate}
                    type="date"
                    name="date"
                    label="GENERAL_DATE"
                    value={data.date}
                    error={getError("date")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!showTime}
                    type="time"
                    name="time"
                    label="GENERAL_HOUR"
                    value={data.time}
                    error={getError("time")}
                    onChange={handleChange}
                    isRequired
                />
            </Content>

            <IconLink
                isHidden={isCreate}
                variant="black"
                icon="delete"
                onClick={() => deleteTrigger(data.triggerID)}
            />
        </Container>

        {isCreate && <Buttons>
            <Button
                variant="outlined"
                message="GENERAL_SAVE"
                onClick={handleCreate}
            />
            <Button
                variant="outlined"
                message="GENERAL_CANCEL"
                onClick={() => setCreating(false)}
            />
        </Buttons>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignTrigger.propTypes = {
    item           : PropTypes.object,
    setCreating    : PropTypes.func,
    getGlobalError : PropTypes.func.isRequired,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CampaignTrigger.defaultProps = {
    item : {},
};

export default CampaignTrigger;
