import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import FlowTemplateDialogs  from "./FlowTemplateDialogs";
import FlowHeader           from "Components/App/Config/Flow/View/FlowHeader";
import FlowEditor           from "Components/App/Config/Flow/Editor/FlowEditor";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



/**
 * The Flow Template Page
 * @returns {React.ReactElement}
 */
function FlowTemplatePage() {
    const { flowID } = Navigate.useParams();


    // Do the Render
    return <>
        <Main withNavigation>
            <FlowHeader levels={1} hasSearch />

            <Content>
                <FlowEditor
                    flowID={flowID}
                    hasTabs
                />
            </Content>
        </Main>

        <FlowTemplateDialogs />
    </>;
}

export default FlowTemplatePage;
