import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import TeamHeader           from "./TeamHeader";
import TeamDetails          from "./TeamDetails";
import TeamEdit             from "./TeamEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Team List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TeamList(props) {
    const { type } = props;
    const { fetch, load } = useList("team", type);

    const { teamID } = Navigate.useParams();
    const clientID   = Hooks.useClientID();
    const navigate   = Hooks.useGoto();

    const data = Store.useState("team");
    const { loading, canEdit, list, total, sort, elem } = data;

    const { fetchElem, removeElem, deleteTeam } = Store.useAction("team");
    const { openDetails } = Store.useAction("core");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Add the Hooks for the Details
    Hooks.useItemDetails("team", teamID);

    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isView) {
            endAction();
            if (elemID !== teamID) {
                navigate("TEAMS", elemID);
            } else {
                openDetails();
            }
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = (teamID) => {
        fetch();
        if (teamID === elem.id) {
            fetchElem(teamID);
        } else if (teamID) {
            navigate("TEAMS", teamID);
        }
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteTeam(elemID);
        if (result.success) {
            fetch();
            if (elemID === elem.id) {
                removeElem();
            }
        }
        endAction();
    };


    // Variables
    const withNavigation = type === "CLIENT";
    const withDetails    = !!elem.id;
    const elemName       = Utils.getValue(list, "teamID", elemID, "name");


    // Do the Render
    return <>
        <Main withNavigation={withNavigation} withDetails={withDetails}>
            <TeamHeader startAction={handleAction} />

            <Content>
                <Table
                    isLoading={loading}
                    fetch={load}
                    sort={sort}
                    none="TEAMS_NONE_AVAILABLE"
                >
                    <TableHead>
                        <TableHeader field="name"                 message="GENERAL_NAME"                isTitle isFlex smallSpace />
                        <TableHeader field="userCount"            message="USERS_NAME"                  maxWidth="100" align="center" />
                        <TableHeader field="status"               message="GENERAL_STATUS"              maxWidth="70" />
                        <TableHeader field="qualificationPercent" message="CONVERSATIONS_QUALIFICATION" maxWidth="100" align="center" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow
                            key={elem.teamID}
                            elemID={elem.teamID}
                            isSelected={elem.teamID === teamID}
                        >
                            <TableCell>
                                <ColorCircle color={elem.color} />
                                {elem.name}
                            </TableCell>
                            <TableCell message={elem.usersText}  />
                            <TableCell message={elem.statusName} textColor={elem.statusColor} />
                            <TableCell
                                className={elem.qualificationColor}
                                message={elem.qualificationPercent}
                                tooltip={elem.qualificationTooltip}
                            />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={handleAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="TEAMS_VIEW_TITLE"   />
                        <TableAction action="EDIT"   message="TEAMS_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="TEAMS_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>
        </Main>

        <TeamDetails startAction={startAction} />

        <TeamEdit
            open={action.isVCE}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="TEAMS_DELETE_TITLE"
            message="TEAMS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TeamList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default TeamList;
