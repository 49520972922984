import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



// Styles
const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`;

const Bubble = Styled(MessageBubble)`
    --bubble-padding: 0px !important;
    width: calc(100% + 12px);

    .bubble-content {
        margin-right: -12px;
    }
    .bubble.bubble::after {
        background: var(--details-background);
    }
`;



/**
 * The Message Template Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MsgTemplatePreview(props) {
    const { clientID, getValue } = props;


    // Variables
    const message = getValue("message");
    const file    = getValue("file");
    const media   = file ? {
        fileID   : 1,
        fileText : file,
        fileUrl  : `${process.env.REACT_APP_FILES}${clientID}/${file}`,
    } : null;


    // Do the Render
    return <Details>
        <Title>{NLS.get("GENERAL_PREVIEW")}</Title>
        <Bubble
            message={message}
            file={media}
            time="6:45"
            isDelivered
            isMine
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MsgTemplatePreview.propTypes = {
    clientID : PropTypes.number.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default MsgTemplatePreview;
