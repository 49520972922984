import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Client Edit Info
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditInfo(props) {
    const { isHidden, partnerID, data, errors, onChange } = props;

    const { partners, countries, provinces, statuses } = Store.useState("client");



    // Do the Render
    return <Columns isHidden={isHidden}>
        <InputField
            className={partnerID ? "columns-double" : ""}
            name="name"
            label="GENERAL_FANTASY_NAME"
            value={data.name}
            error={errors.name}
            onChange={onChange}
            isRequired
        />
        <InputField
            isHidden={!!partnerID}
            type="select"
            name="partnerID"
            label="PARTNERS_SINGULAR"
            options={partners}
            value={data.partnerID}
            error={errors.partnerID}
            onChange={onChange}
        />

        <InputField
            name="socialReason"
            label="GENERAL_SOCIAL_REASON"
            value={data.socialReason}
            error={errors.socialReason}
            onChange={onChange}
        />
        <InputField
            name="taxID"
            label="GENERAL_TAX_ID"
            value={data.taxID}
            error={errors.taxID}
            onChange={onChange}
        />

        <InputField
            name="firstName"
            label="CLIENTS_FIRST_NAME"
            value={data.firstName}
            error={errors.firstName}
            onChange={onChange}
        />
        <InputField
            name="lastName"
            label="CLIENTS_LAST_NAME"
            value={data.lastName}
            error={errors.lastName}
            onChange={onChange}
        />

        <InputField
            type="email"
            name="email"
            label="CLIENTS_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={onChange}
        />
        <InputField
            type="tel"
            name="phone"
            label="CLIENTS_PHONE"
            value={data.phone}
            error={errors.phone}
            onChange={onChange}
        />

        <InputField
            type="select"
            name="countryID"
            label="COUNTRIES_SINGULAR"
            options={countries}
            value={data.countryID}
            error={errors.countryID}
            onChange={onChange}
        />
        <InputField
            type="select"
            name="provinceID"
            label="GENERAL_PROVINCE"
            options={provinces[data.countryID] || []}
            value={data.provinceID}
            error={errors.provinceID}
            onChange={onChange}
        />
        <InputField
            name="locality"
            label="GENERAL_LOCALITY"
            value={data.locality}
            error={errors.locality}
            onChange={onChange}
        />
        <InputField
            name="address"
            label="GENERAL_ADDRESS"
            value={data.address}
            error={errors.address}
            onChange={onChange}
        />
        <InputField
            name="zipCode"
            label="GENERAL_ZIP_CODE"
            value={data.zipCode}
            error={errors.zipCode}
            onChange={onChange}
        />
        <InputField
            type="select"
            name="timeZone"
            label="GENERAL_TIME_ZONE"
            options={NLS.get("DATE_TIME_ZONES")}
            value={data.timeZone}
            error={errors.timeZone}
            onChange={onChange}
            hideClear
            isRequired
        />

        <InputField
            type="select"
            name="language"
            label="LANGUAGES_SINGULAR"
            options={NLS.select("LANGUAGES")}
            value={data.language}
            error={errors.language}
            onChange={onChange}
            hideClear
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={onChange}
            hideClear
            isRequired
        />
        <InputField
            className="columns-double"
            type="textarea"
            name="observations"
            label="GENERAL_OBSERVATIONS"
            value={data.observations}
            onChange={onChange}
        />
    </Columns>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditInfo.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    data      : PropTypes.object.isRequired,
    errors    : PropTypes.object.isRequired,
    onChange  : PropTypes.func.isRequired,
    partnerID : PropTypes.number,
};

export default ClientEditInfo;
