import React                from "react";
import Store                from "Dashboard/Core/Store";
import Hooks                from "Utils/Hooks";

// Components
import FlowMove             from "../Dialogs/FlowMove";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Flow Editor Dialogs
 * @returns {React.ReactElement}
 */
function FlowEditorDialogs() {
    const navigate = Hooks.useGoto();

    const { elem } = Store.useState("flow");

    const { flowID } = Store.useState("flowEditor");
    const { deleteNodes } = Store.useAction("flowEditor");

    const { action, toSelectNodes, selectedNodes } = Store.useState("flowState");
    const {
        setAction, setHasChanges, setDetailsTab,
        setToSelectNodes, setSelectedNode, setSelectedNodes,
    } = Store.useAction("flowState");


    // Handles the Nodes Move
    const handleMove = (gotoFlowID) => {
        if (gotoFlowID) {
            navigate("SETTINGS", "FLOWS", gotoFlowID, "EDIT");
        }
        setAction();
    };

    // Handles the Nodes Deletes
    const handleDeleteNodes = () => {
        if (selectedNodes.length) {
            deleteNodes(elem.clientID, elem.id, JSON.stringify(selectedNodes));
            setSelectedNode(0);
            setHasChanges(false);
        }
        setAction();
    };

    // Handles the Discard
    const handleDiscard = () => {
        setSelectedNodes(toSelectNodes);
        setToSelectNodes([]);
        setHasChanges(false);
        setAction();
        if (toSelectNodes.length === 1) {
            setDetailsTab("editor");
        }
    };


    // Do the Render
    const multiDelete = selectedNodes.length > 1;

    return <>
        <FlowMove
            open={action.isMove}
            flowID={flowID}
            onSubmit={handleMove}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isRemove}
            title={multiDelete ? "FLOWS_NODES_MULTI_DELETE_TITLE" : "FLOWS_NODES_DELETE_TITLE"}
            message={multiDelete ? "FLOWS_NODES_MULTI_DELETE_TEXT" : "FLOWS_NODES_DELETE_TEXT"}
            content={String(selectedNodes.length)}
            onSubmit={handleDeleteNodes}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isWarning}
            icon="status"
            title="FLOWS_NODES_WARNING_TITLE"
            message="FLOWS_NODES_WARNING_TEXT"
            primary="GENERAL_DISCARD"
            onSubmit={handleDiscard}
            onClose={setAction}
        />
    </>;
}

export default FlowEditorDialogs;
