import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



// Styles
const Grid = Styled(DialogMessage)`
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Client Edit Addons
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEditAddons(props) {
    const { isHidden, data, errors, onChange } = props;

    const { addons } = Store.useState("client");


    // Handles the Addon
    const handleAddon = (addonCode, name, value, hasQuantity) => {
        const oldData = data.addons[addonCode];
        const newData = { ...oldData, [name] : value };

        if (hasQuantity && name === "isActive") {
            const quantity = oldData?.quantity ?? 0;
            if (value !== 0 && quantity === 0) {
                newData.quantity = 1;
            } else if (value === 0 && quantity === 1) {
                newData.quantity = 0;
            }
        }

        const addons = { ...data.addons, [addonCode] : newData };
        onChange("addons", addons);
    };


    // Variables
    const hasMarketing = Boolean(data.addons?.Marketing?.isActive);


    // Do the Render
    return <Grid isHidden={isHidden}>
        <Columns amount="5">
            <>
                {addons.map(({ key, value, hasQuantity }) => <React.Fragment key={key}>
                    <InputField
                        className="columns-double"
                        type="toggle"
                        name={`addonActive-${key}`}
                        label={value}
                        value={data.addons[key]?.isActive || 0}
                        onChange={(name, value) => handleAddon(key, "isActive", value, hasQuantity)}
                        withBorder
                    />
                    <InputField
                        className="columns-double"
                        type="double"
                        label="GENERAL_PRICE"
                        error={errors[`addonPrice-${key}`]}
                    >
                        <InputItem
                            type="number"
                            name={`addonPrice-${key}`}
                            icon="money"
                            value={data.addons[key]?.price || ""}
                            minValue={0}
                            onChange={(name, value) => handleAddon(key, "price", value, hasQuantity)}
                        />
                        <InputItem
                            type="checkbox"
                            name={`addonPriceFree-${key}`}
                            label="GENERAL_FREE"
                            value={data.addons[key]?.priceFree || 0}
                            onChange={(name, value) => handleAddon(key, "priceFree", value, hasQuantity)}
                        />
                    </InputField>
                    {hasQuantity ? <InputField
                        type="number"
                        name={`addonQuantity-${key}`}
                        label="GENERAL_AMOUNT"
                        value={data.addons[key]?.quantity || 0}
                        error={errors[`addonQuantity-${key}`]}
                        onChange={(name, value) => handleAddon(key, "quantity", value, hasQuantity)}
                        minValue={0}
                    /> : <div />}
                </React.Fragment>)}
            </>
        </Columns>

        <Columns topSpace="12" amount="1">
            <InputField
                isHidden={!hasMarketing}
                type="number"
                name="campaignLimit"
                label="CLIENTS_CAMPAIGN_LIMIT"
                value={data.campaignLimit}
                error={errors.campaignLimit}
                onChange={onChange}
            />
        </Columns>
    </Grid>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEditAddons.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ClientEditAddons;
