import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrderDetails         from "../Order/OrderDetails";
import AssistantItem        from "Components/Utils/Details/AssistantItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Store Details
 * @returns {React.ReactElement}
 */
function StoreDetails() {
    const { canEdit, elem, module } = Store.useState("store");
    const { withDetails, withOrder, withAssistant, detailsTab } = Store.useState("storeState");

    const { setDetailsTab, setAction } = Store.useAction("storeState");


    // Variables
    const hasInternalTabs = withOrder && withAssistant;
    const inOrder         = detailsTab === "order";
    const inAssistant     = detailsTab === "assistant";


    // Do the Render
    return <Details
        isHidden={!withDetails}
        collapsible="order"
        hasInternalTabs={hasInternalTabs}
        onAction={setAction}
        canEdit={canEdit}
        isWide
    >
        {hasInternalTabs && <TabList
            variant="lined"
            selected={detailsTab}
            onClick={setDetailsTab}
            inDetails
        >
            <TabItem
                isHidden={!withOrder}
                message="ORDERS_SINGULAR"
                icon="order"
                value="order"
            />
            <TabItem
                isHidden={!withAssistant}
                message="ASSISTANTS_SINGULAR"
                icon="assistant"
                value="assistant"
            />
        </TabList>}

        {inOrder && <OrderDetails />}

        {inAssistant && <AssistantItem
            module={module}
            parentID={elem.id}
            assistantID={elem.assistantID}
            hasInternalTabs={hasInternalTabs}
        />}
    </Details>;
}

export default StoreDetails;
