import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Team Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TeamHeader(props) {
    const { startAction } = props;

    const { canCreate, canEdit, elem } = Store.useState("team");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Variables
    const onlyCreate = canCreate && !elem.id;
    const hasEdit    = canEdit && !!elem.id;


    // Do the Render
    return <Header message="TEAMS_NAME" icon="team">
        <ActionList onAction={startAction}>
            <ActionItem
                isHidden={!onlyCreate}
                action="CREATE"
            />
            <ActionItem
                isHidden={onlyCreate}
                icon="more"
                message="GENERAL_ACTIONS"
                onAction={startAction}
            >
                <ActionOption
                    isHidden={!canCreate}
                    action="CREATE"
                    message="TEAMS_CREATE_TITLE"
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="EDIT"
                    message="TEAMS_EDIT_TITLE"
                    onAction={handleAction}
                />
                <ActionOption
                    isHidden={!hasEdit}
                    action="DELETE"
                    message="TEAMS_DELETE_TITLE"
                    onAction={handleAction}
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TeamHeader.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default TeamHeader;
