import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Campaign List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignList(props) {
    const { type } = props;
    const { fetch, load, loadTab } = useList("campaign", type);

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const data = Store.useState("campaign");
    const { loading, canEdit, list, total, sort, statuses } = data;

    const { createCampaign, pauseCampaign, deleteCampaign } = Store.useAction("campaign");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isTab) {
            loadTab(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Goes to the Campaign
    const gotoCampaign = (elemID, tabUrl) => {
        navigate("CAMPAIGNS", elemID, tabUrl);
    };

    // Handles the Create Submit
    const handleCreate = async (name) => {
        endAction();
        const result = await createCampaign(clientID, name);
        if (result.success) {
            if (result.campaignID) {
                gotoCampaign(result.campaignID, "EDIT");
            } else {
                fetch();
            }
        }
    };

    // Handles the Pause
    const handlePause = async () => {
        endAction();
        const result = await pauseCampaign(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteCampaign(elemID);
        if (result.success) {
            fetch();
        }
    };



    // Do the Render
    const isDraft  = sort.filter === "Draft";
    const isActive = sort.filter === "Active";
    const elemName = Utils.getValue(list, "campaignID", elemID, "name");

    return <Main>
        <Header message="CAMPAIGNS_NAME" icon="campaign">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <TabList
                variant="lined"
                selected={sort.filter}
                onAction={handleAction}
            >
                {statuses.map((elem) => <TabItem
                    key={elem.key}
                    message={elem.value}
                    icon={elem.key.toLowerCase()}
                    value={elem.key}
                />)}
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="CAMPAIGNS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"              message="GENERAL_NAME"           isTitle />
                    <TableHeader field="status"            message="GENERAL_STATUS"         align="center" maxWidth="100" isHidden={!isActive} />
                    <TableHeader field="executions"        message="GENERAL_EXECUTIONS"     align="center" maxWidth="100" isHidden={isDraft} />
                    <TableHeader field="startTime"         message="GENERAL_START"          maxWidth="100" />
                    <TableHeader field="lastExecutionTime" message="GENERAL_LAST_EXECUTION" maxWidth="160" isHidden={isDraft} />
                    <TableHeader field="nextExecutionTime" message="GENERAL_NEXT_EXECUTION" maxWidth="160" isHidden={!isActive} />
                    <TableHeader field="endTime"           message="GENERAL_END"            maxWidth="100" isHidden={isDraft} />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.campaignID} elemID={elem.campaignID}>
                        <TableCell message={elem.name}              />
                        <TableCell message={elem.statusName}        textColor={elem.statusColor} />
                        <TableCell message={elem.executions}        />
                        <TableCell message={elem.startDate}         />
                        <TableCell message={elem.lastExecutionDate} />
                        <TableCell message={elem.nextExecutionDate} />
                        <TableCell message={elem.endDate}           />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="CAMPAIGNS_VIEW_TITLE"   navigate />
                    <TableAction action="PAUSE"  message="CAMPAIGNS_PAUSE_TITLE"  isHidden={!isActive} />
                    <TableAction action="DELETE" message="CAMPAIGNS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <PromptDialog
            open={action.isCreate}
            icon="campaign"
            title="CAMPAIGNS_CREATE_TITLE"
            message="CAMPAIGNS_CREATE_TEXT"
            inputLabel="GENERAL_NAME"
            onSubmit={handleCreate}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="CAMPAIGNS_PAUSE_TITLE"
            message="CAMPAIGNS_PAUSE_TEXT"
            content={elemName}
            onSubmit={handlePause}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CAMPAIGNS_DELETE_TITLE"
            message="CAMPAIGNS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default CampaignList;
