import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import TextField            from "Components/Utils/Inputs/TextField";
import TextItem             from "Components/Utils/Inputs/TextItem";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";
import EditorField          from "Components/Utils/Inputs/EditorField";

// Dashboard
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Integration Input
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationInput(props) {
    const { action, item, data, errors, language, onChange, onMedia } = props;

    const { elem } = Store.useState("integration");
    const { actions, selects, channelLinks, smsCost } = Store.useState("integrationEditor");


    // Handles the Change
    const handleChange = (name, value) => {
        if (!item.useLanguage) {
            onChange(name, value);
            return;
        }

        if (!data[name] || !Utils.isObject(data[name])) {
            data[name] = {};
        }
        data[name][language] = value;
        onChange(name, data[name]);
    };


    // Returns the value for the given field
    const value = React.useMemo(() => {
        if (!item.useLanguage) {
            return data[item.name] || "";
        }
        if (!data[item.name] || !Utils.isObject(data[item.name])) {
            return "";
        }
        return data[item.name][language] || "";
    }, [ JSON.stringify(data), language, item.name ]);

    // Returns the Variables that can be used
    const variableList = React.useMemo(() => {
        const result = [];
        for (const otherAction of actions) {
            if (otherAction.position >= action.position) {
                continue;
            }
            for (const { key, value } of otherAction.variables) {
                result.push({
                    key   : `{${key}}`,
                    value : value,
                    extra : NLS.get(otherAction.title),
                });
            }
        }
        return result;
    }, [ JSON.stringify(actions) ]);


    // Variables
    const type       = Commons.getInputType(item, data, selects);
    const isHidden   = item.hide ? eval(item.hide)(data, selects) : false;
    const isView     = type === "view";
    const isMedia    = type === "media";
    const isText     = type === "text";
    const isTextarea = type === "textarea";
    const isEditor   = type === "editor";
    const isFields   = type === "fields";
    const isInput    = !isView && !isMedia && !isText && !isTextarea && !isEditor && !isFields;
    const items      = item.fields ? item.fields : [];


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <section>
        {isView && <ViewField
            {...item}
            value={eval(item.getView)(elem)}
            showEmpty
        />}

        {isMedia && <MediaField
            {...item}
            clientID={action.clientID}
            value={value}
            error={errors[item.name] || ""}
            onChange={handleChange}
        />}

        {isText && <TextField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variableList}
            onChange={handleChange}
            withVariable
        />}

        {isTextarea && <TextareaField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            variables={variableList}
            channelLinks={channelLinks}
            messageCost={item.showCost ? smsCost : 0}
            onChange={handleChange}
        />}

        {isEditor && <EditorField
            {...item}
            value={value}
            error={errors[item.name] || ""}
            clientID={elem.clientID}
            onChange={handleChange}
            onMedia={onMedia}
            variables={variableList}
            channelLinks={channelLinks}
            height={400}
        />}

        {isFields && <InputField
            {...item}
            type={type}
            value={value}
            error={errors[item.name] || ""}
            errors={errors}
            onChange={handleChange}
        >
            {items.map((field) => <InputItem
                {...field}
                key={field.key || field.name}
                subKey={field.key || field.name}
                hide={field.hide ? eval(field.hide) : undefined}
                getType={(elem) => Commons.getInputType(field, elem, selects)}
                getOptions={(elem) => Commons.getInputSelect(field, { ...data, ...elem }, selects)}
                withBorder
                hasClear
            >
                {field.withChildren && <TextItem
                    variables={variableList}
                />}
            </InputItem>)}
        </InputField>}

        {isInput && <InputField
            {...item}
            type={type}
            value={value}
            error={errors[item.name] || ""}
            errors={errors}
            options={Commons.getInputSelect(item, data, selects)}
            onChange={handleChange}
        >
            {item.withChildren && <TextItem
                variables={variableList}
            />}
        </InputField>}
    </section>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationInput.propTypes = {
    action   : PropTypes.object.isRequired,
    item     : PropTypes.object.isRequired,
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    language : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
    onMedia  : PropTypes.func.isRequired,
};

export default IntegrationInput;
