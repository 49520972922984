import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Components
import MsgTemplateHeader    from "./View/MsgTemplateHeader";
import MsgTemplateEditor    from "./View/MsgTemplateEditor";
import MsgTemplatePreview   from "./View/MsgTemplatePreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Message Template Page
 * @returns {React.ReactElement}
 */
function MsgTemplatePage() {
    const { msgTemplateID } = Navigate.useParams();

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(msgTemplateID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem, tongues } = Store.useState("msgTemplate");
    const { fetchElem, fetchEditData, editMsgTemplate, deleteMsgTemplate } = Store.useAction("msgTemplate");


    // The Fields
    const fields = {
        main    : [ "name", "position", "status" ],
        message : [ "message", "file" ],
        assign  : [ "teams", "hotels" ],
    };

    // The Current State
    const [ tongueID,   setTongueID   ] = React.useState(0);
    const [ step,       setStep       ] = React.useState("");
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        msgTemplateID : 0,
        clientID      : 0,
        name          : "",
        position      : 0,
        status        : "Active",
        values        : {},
        teams         : [],
        hotels        : [],
    };


    // Handles the Edit
    const handleEdit = async (data) => {
        await editMsgTemplate({
            ...data, clientID,
            values : JSON.stringify(data.values),
        });
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteMsgTemplate(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setElem, setData, resetData,
        setErrors, resetErrors, handleSubmit,
    } = useForm("msgTemplate", initialData, handleEdit, handleClose, false);


    // Load the Data
    React.useEffect(() => {
        if (msgTemplateID) {
            fetchElem(msgTemplateID);
            setStep("");
        } else {
            fetchEditData(clientID);
            setStep("main");
        }
    }, [ msgTemplateID ]);

    // Store the Data
    React.useEffect(() => {
        if (elem.id) {
            const msgTemplateID = isEdit ? elem.msgTemplateID : 0;
            setElem({ ...elem, msgTemplateID });
        } else {
            resetErrors();
            resetData();
        }
    }, [ edition ]);

    // Set the initial tongue
    React.useEffect(() => {
        if (!charging && tongues.length) {
            setTongueID(tongues[0].key);
        }
    }, [ charging ]);


    // Returns the Value
    const getValue = (name, defaultValue = "") => {
        if (Utils.hasProp(initialData, name)) {
            return data[name] || defaultValue;
        }
        return data.values[tongueID]?.[name] || defaultValue;
    };

    // Returns the Error
    const getError = (name) => {
        if (name === "form" && !errors.form) {
            return Commons.getLanguageError(tongues, errors, 1);
        }
        if (Utils.hasProp(initialData, name)) {
            return errors[name];
        }
        return errors[`${name}-${tongueID}`];
    };

    // Returns the Error Count in the Step
    const getErrorCount = (step) => {
        return Commons.getErrorCount(fields, errors, step, tongueID);
    };

    // Handles the Change
    const handleChange = (name, value) => {
        if (Utils.hasProp(initialData, name)) {
            setData({ ...data, [name] : value });
            setErrors({ ...errors, [name] : "" });
            return;
        }

        const elem   = { ...data.values[tongueID], [name] : value };
        const values = { ...data.values, [tongueID] : elem };
        setData({ ...data, values });
        setErrors({ ...errors, [`${name}-${tongueID}`] : "" });
    };



    // Do the Render
    const showTongues = Boolean(tongues.length > 1);

    return <>
        <Main withNavigation withDetails>
            <MsgTemplateHeader
                href={goto}
                isEdit={isEdit}
            />

            <Content isLoading={charging}>
                {showTongues && <TabList
                    variant="lined"
                    selected={tongueID}
                    onClick={setTongueID}
                >
                    {tongues.map(({ key, value }) => <TabItem
                        key={key}
                        message={value}
                        value={key}
                    />)}
                </TabList>}

                <MsgTemplateEditor
                    loading={loading}
                    isEdit={isEdit}
                    clientID={clientID}
                    hasTabs={showTongues}
                    step={step}
                    setStep={setStep}
                    getValue={getValue}
                    getError={getError}
                    getErrorCount={getErrorCount}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onDelete={() => setShowDelete(true)}
                    onClose={handleClose}
                />
            </Content>
        </Main>

        <MsgTemplatePreview
            clientID={clientID}
            getValue={getValue}
        />

        <DeleteDialog
            open={showDelete}
            title="MSG_TEMPLATES_DELETE_TITLE"
            message="MSG_TEMPLATES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

export default MsgTemplatePage;
