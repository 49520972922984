import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Components
import PhoneNumberAdd       from "../../PhoneNumber/PhoneNumberAdd";
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



/**
 * The Twilio Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TwilioInstall(props) {
    const { isHidden, provider, onInstall } = props;

    const clientID = Hooks.useClientID();
    const navigate = Navigate.useGoto();

    const { canAddPhone, phoneNumbers } = Store.useState("channel");
    const { fetchEditData } = Store.useAction("channel");

    // The Current State
    const [ phoneNumber, setPhoneNumber ] = React.useState("");
    const [ showDialog,  setShowDialog  ] = React.useState(false);


    // Handles the Change
    const handleContinue = () => {
        if (phoneNumber) {
            onInstall(phoneNumber);
        }
    };

    // Handles the Buy
    const handleBuy = () => {
        setShowDialog(false);
        fetchEditData(clientID);
    };

    // Handles the Ticket
    const handleTicket = () => {
        navigate("TICKETS", "CREATE");
    };


    // Do the Render
    const showSelect = Boolean(phoneNumbers.length);
    const showBuy    = Boolean(!showSelect && canAddPhone);
    const showError  = Boolean(!showSelect && !canAddPhone);

    if (isHidden) {
        return <React.Fragment />;
    }

    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        {showSelect && <>
            <section>
                <p>{NLS.get("CHANNELS_SMS_SELECT")}</p>
                <InputField
                    type="select"
                    name="number"
                    label="PHONE_NUMBERS_SINGULAR"
                    options={phoneNumbers}
                    value={phoneNumber}
                    onChange={(name, value) => setPhoneNumber(value)}
                    isRequired
                />
                <Button
                    className="top-space"
                    variant="outlined"
                    message="GENERAL_CONTINUE"
                    onClick={handleContinue}
                    isDisabled={!phoneNumber}
                />
            </section>
        </>}

        {showBuy && <>
            <section>
                <p>{NLS.get("CHANNELS_SMS_NONE_AVAILABLE")}</p>
                <p>{NLS.get("CHANNELS_SMS_BUY_TEXT")}</p>
            </section>
            <Button
                variant="primary"
                message="CHANNELS_SMS_BUY_BUTTON"
                onClick={() => setShowDialog(true)}
            />
        </>}

        {showError && <>
            <section>
                <p>{NLS.get("CHANNELS_SMS_NONE_AVAILABLE")}</p>
                <p>{NLS.get("CHANNELS_SMS_TICKET_TEXT")}</p>
            </section>
            <Button
                variant="primary"
                message="CHANNELS_SMS_TICKET_BUTTON"
                onClick={handleTicket}
            />
        </>}

        <PhoneNumberAdd
            open={showDialog}
            clientID={clientID}
            onClose={() => setShowDialog(false)}
            onSubmit={handleBuy}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TwilioInstall.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default TwilioInstall;
