import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    Integration, IntegrationAction,
} from "Utils/API";



// The initial State
const initialState = {
    readying      : false,
    error         : false,
    edition       : 0,
    canEdit       : false,
    integrationID : 0,
    clientID      : 0,
    actions       : [],
    totalChanges  : 0,
    publishErrors : [],
    languages     : [],
    channelLinks  : [],
    smsCost       : 0,
    actionTypes   : [],
    selects       : {},
};



// The Actions
const actions = {
    /**
     * Fetches a single Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    async fetchEditor(dispatch, integrationID) {
        dispatch({ type : "INTEGRATION_EDITOR_READYING" });
        const data = await Integration.getEditor({ integrationID });
        dispatch({ type : "INTEGRATION_STATE_RESET" });
        dispatch({ type : "INTEGRATION_EDITOR_READY" });
        dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
    },

    /**
     * Fetches a single Integration
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @returns {Promise}
     */
    async fetchUpdate(dispatch, integrationID) {
        const data = await Integration.getEditor({ integrationID });
        dispatch({ type : "INTEGRATION_EDITOR_UPDATE", data });
    },


    /**
     * Creates an Integration Action
     * @param {Function} dispatch
     * @param {Number}   integrationID
     * @param {String}   actionType
     * @param {String}   name
     * @param {Number}   position
     * @param {Number}   forTrigger
     * @returns {Promise}
     */
    async createAction(dispatch, integrationID, actionType, name, position, forTrigger) {
        const data = await IntegrationAction.create({ integrationID, actionType, name, position, forTrigger });
        if (!data.error) {
            dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
            dispatch({ type : "INTEGRATION_SELECTED_ACTION", selectedAction : data.actionID });
        }
    },

    /**
     * Edits an Integration Action
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editAction(dispatch, params) {
        const data = await IntegrationAction.edit(params);
        dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
    },

    /**
     * Copies an Integration Action
     * @param {Function} dispatch
     * @param {Number}   actionID
     * @returns {Promise}
     */
    async copyAction(dispatch, actionID) {
        const data = await IntegrationAction.copy({ actionID });
        if (!data.error) {
            dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
            dispatch({ type : "INTEGRATION_SELECTED_ACTION", selectedAction : data.actionID });
        }
    },

    /**
     * Moves an Integration Action
     * @param {Function} dispatch
     * @param {Number}   actionID
     * @param {Number}   direction
     * @returns {Promise}
     */
    async moveAction(dispatch, actionID, direction) {
        const data = await IntegrationAction.move({ actionID, direction });
        if (!data.error) {
            dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
            dispatch({ type : "INTEGRATION_SELECTED_ACTION", selectedAction : actionID });
        }
        return 0;
    },

    /**
     * Deletes an Integration Action
     * @param {Function} dispatch
     * @param {Number}   actionID
     * @returns {Promise}
     */
    async deleteAction(dispatch, actionID) {
        const data = await IntegrationAction.delete({ actionID });
        if (!data.error) {
            dispatch({ type : "INTEGRATION_SELECTED_ACTION", selectedAction : 0 });
            dispatch({ type : "INTEGRATION_EDITOR_ELEM", data });
        }
    },
};



/**
 * Returns true if there is a Action as a Trigger
 * @param {Object[]} actions
 * @returns {Boolean}
 */
function hasTrigger(actions) {
    if (actions && actions.length) {
        for (const action of actions) {
            if (action.isTrigger) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Parses the Actions
 * @param {Object[]} actions
 * @param {Object}   actionTypes
 * @returns {Object[]}
 */
function parseActions(actions, actionTypes) {
    return Utils.parseList(actions, (elem) => {
        if (actionTypes[elem.actionType]) {
            for (const [ key, value ] of Object.entries(actionTypes[elem.actionType])) {
                elem[key] = value;
            }
        }
        elem.title = elem.name || elem.message;
    });
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "INTEGRATION_EDITOR")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "INTEGRATION_EDITOR_READYING":
        return {
            ...state,
            readying     : true,
        };

    case "INTEGRATION_EDITOR_READY":
        return {
            ...state,
            readying      : false,
            error         : false,
            edition       : state.edition + 1,
        };

    case "INTEGRATION_EDITOR_ELEM":
        return {
            ...state,
            canEdit       : action.data.canEdit,
            integrationID : action.data.integrationID,
            clientID      : action.data.clientID,
            hasTrigger    : hasTrigger(action.data.actions),
            actions       : parseActions(action.data.actions, action.data.actionTypes),
            totalChanges  : action.data.actions.filter((action) => action.hasChanges).length,
            languages     : action.data.languages,
            publishErrors : action.data.publishErrors,
            channelLinks  : action.data.channelLinks,
            smsCost       : action.data.smsCost,
            actionTypes   : action.data.actionTypes,
            selects       : action.data.selects,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
