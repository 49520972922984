import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import AccountFilter        from "./Dialogs/AccountFilter";
import AccountEdit          from "./Dialogs/AccountEdit";
import AccountAssign        from "./Dialogs/AccountAssign";
import AccountImport        from "./Dialogs/AccountImport";
import ContactEdit          from "../Contact/Dialogs/ContactEdit";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Account Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountDialogs(props) {
    const { action, elemID, endAction, fetch, loadFilter } = props;

    const clientID = Hooks.useClientID();
    const navigate = Hooks.useGoto();

    const { list, filters, elem } = Store.useState("account");
    const { fetchElem, removeElem, deleteAccount } = Store.useAction("account");


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
        removeElem();
    };

    // Handles the Edit Submit
    const handleEdit = (accountID) => {
        fetch();
        if (accountID === elem.id) {
            fetchElem(accountID);
        } else if (accountID) {
            navigate("ACCOUNTS", accountID);
        }
        endAction();
    };

    // Handles the Assign Submit
    const handleAssign = () => {
        fetch();
        if (elemID === elem.id) {
            fetchElem(elem.id);
        }
        endAction();
    };

    // Handles the Contact Submit
    const handleContact = () => {
        fetch();
        fetchElem(elem.id);
        endAction();
    };

    // Handles the Import Submit
    const handleImport = () => {
        fetch();
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteAccount(elemID);
        if (result.success) {
            fetch();
            if (elemID === elem.id) {
                removeElem();
            }
        }
        endAction();
    };

    // Handles the Close
    const handleClose = (update) => {
        if (update) {
            fetch();
            fetchElem(elem.id);
        }
        endAction();
    };


    // Do the Render
    const listElem = Utils.getValue(list, "accountID", elemID);
    const account  = listElem.id ? listElem : elem;

    return <>
        <AccountFilter
            open={action.isFilter}
            filters={filters}
            clientID={clientID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <AccountEdit
            open={action.isCE}
            elemID={action.isCreate ? 0 : elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <AccountImport
            open={action.isImport}
            clientID={clientID}
            onSubmit={handleImport}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="ACCOUNTS_DELETE_TITLE"
            message="ACCOUNTS_DELETE_TEXT"
            content={account.name}
            onSubmit={handleDelete}
            onClose={endAction}
        />

        <AccountAssign
            openAssign={action.isAssign}
            openUnassign={action.isUnassign}
            elemID={elemID}
            elemName={account.name}
            accountID={elemID}
            clientID={clientID}
            onSubmit={handleAssign}
            onClose={handleClose}
        />

        <ContactEdit
            open={action.isContact}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleContact}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountDialogs.propTypes = {
    action     : PropTypes.object.isRequired,
    elemID     : PropTypes.number,
    endAction  : PropTypes.func.isRequired,
    fetch      : PropTypes.func.isRequired,
    loadFilter : PropTypes.func.isRequired,
};

export default AccountDialogs;
