import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import CampaignHeader       from "./View/CampaignHeader";
import CampaignReport       from "./View/CampaignReport";
import CampaignExecutions   from "./View/CampaignExecutions";
import CampaignEditor       from "./View/CampaignEditor";
import CampaignDialogs      from "./View/CampaignDialogs";
import FlowEditor           from "Components/App/Config/Flow/Editor/FlowEditor";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Campaign Page
 * @returns {React.ReactElement}
 */
function CampaignPage() {
    const { campaignID, campaignTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { charging, elem } = Store.useState("campaign");

    const { fetchElem } = Store.useAction("campaign");
    const { setInitialData, setInActivate } = Store.useAction("campaignState");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Load the Data
    React.useEffect(() => {
        handleLoad(campaignID);
        setInActivate(false);
        setFlowInPublish(false);
    }, [ campaignID ]);

    // Handle the Load
    const handleLoad = async (campaignID) => {
        const { elem, optionsDraft } = await fetchElem(campaignID);
        if (elem) {
            setInitialData(elem.name, optionsDraft, elem.isDraft);
            navigate("CAMPAIGNS", campaignID, "EDIT");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("CAMPAIGNS", campaignID, url);
    };



    // Variables
    const isEdit       = Navigate.isUrl("EDIT",       campaignTab);
    const isFlow       = Navigate.isUrl("FLOW_ONE",   campaignTab);
    const isReport     = Navigate.isUrl("REPORT",     campaignTab);
    const isExecutions = Navigate.isUrl("DISPATCHES", campaignTab);


    // Do the Render
    return <>
        <Main>
            <CampaignHeader />

            <Content isLoading={charging}>
                <TabList
                    isHidden={!elem.hasTabs}
                    variant="lined"
                    selected={campaignTab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_EDITOR"
                        icon="edit"
                        url="EDIT"
                    />
                    <TabItem
                        isHidden={!elem.flowActiveDraft}
                        message="FLOWS_SINGULAR"
                        icon="flow"
                        url="FLOW_ONE"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="GENERAL_REPORT"
                        icon="report"
                        url="REPORT"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="CAMPAIGNS_DISPATCHES_NAME"
                        icon="simulate"
                        url="DISPATCHES"
                    />
                </TabList>

                {isReport && <CampaignReport />}
                {isExecutions && <CampaignExecutions />}
                {isEdit && <CampaignEditor />}
                {isFlow && <FlowEditor
                    flowID={elem.flowID}
                    hasTabs
                />}
            </Content>
        </Main>

        <CampaignDialogs />
    </>;
}

export default CampaignPage;
