import React                from "react";
import PropTypes            from "prop-types";

// Components
import ReportsContent       from "./Reports/ReportsContent";
import ReportsHeader        from "./Reports/ReportsHeader";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Dashboard Partner Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardPartnerPage(props) {
    const { type } = props;


    // Variables
    const withNavigation = type === "PARTNER";


    // Do the Render
    return <Main withNavigation={withNavigation}>
        <Header message="DASHBOARD_NAME" icon="dashboard">
            <ReportsHeader />
        </Header>

        <Content>
            <ReportsContent
                type={type}
                withNavigation={withNavigation}
            />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardPartnerPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default DashboardPartnerPage;
