import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Components
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



// Styles
const Title = Styled.h3`
    margin-top: 0;
    margin-bottom: var(--main-gap);
`;

const Bubble = Styled(MessageBubble)`
    --bubble-padding: 0px !important;
    width: calc(100% + 12px);

    .bubble-content {
        margin-right: -12px;
    }
    .bubble.bubble::after {
        background: var(--details-background);
    }
`;



/**
 * The Questionnaire Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function QuestionnairePreview(props) {
    const { clientID, getValue } = props;


    // Variables
    const questions = getValue("questions");
    const list      = React.useMemo(() => {
        const result = [{
            title   : NLS.get("GENERAL_PREVIEW"),
            message : getValue("name"),
            options : questions.map((elem, payload) => ({ payload, message : elem.title || "" })),
            media   : null,
        }];

        for (const [ index, elem ] of Object.entries(questions)) {
            let message = "";
            let media   = null;
            if (elem.question) {
                message += `**${elem.question}**\n\n`;
            }
            if (elem.answer) {
                message += elem.answer;
            }
            if (elem.file) {
                media = {
                    fileID   : 1,
                    fileText : elem.file,
                    fileUrl  : `${process.env.REACT_APP_FILES}${clientID}/${elem.file}`,
                };
            }
            result.push({
                message, media,
                title   : NLS.format("QUESTIONNAIRES_QUESTION_TITLE", String(Number(index) + 1)),
                options : [],
            });
        }
        return result;
    }, [ JSON.stringify(questions) ]);


    // Do the Render
    return <Details>
        {list.map((elem, index) => <div key={index}>
            <Title>{elem.title}</Title>
            <Bubble
                key={index}
                message={elem.message}
                file={elem.media}
                options={elem.options}
                time="6:45"
                isDelivered
                isMine
            />
        </div>)}
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
QuestionnairePreview.propTypes = {
    clientID : PropTypes.number.isRequired,
    getValue : PropTypes.func.isRequired,
};

export default QuestionnairePreview;
