import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import WidgetCode           from "../Dialogs/WidgetCode";
import WidgetCreate         from "../Dialogs/WidgetCreate";
import WidgetPublish        from "../Dialogs/WidgetPublish";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Widget Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetDialogs(props) {
    const { data, tongueID } = props;

    const { widgetID } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { elem } = Store.useState("widget");
    const { action } = Store.useState("widgetState");
    const { setAction } = Store.useAction("widgetState");
    const { fetchData, publishWidget, clearWidget, deleteWidget } = Store.useAction("widget");


    // Handles the Edit
    const handleEdit = (response) => {
        setAction();
        if (response.widgetID !== elem.id) {
            navigate("SETTINGS", "WIDGETS", response.widgetID);
        } else {
            fetchData(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishWidget(widgetID);
        if (result.success) {
            fetchData(widgetID);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearWidget(widgetID);
        if (result.success) {
            fetchData(widgetID);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteWidget(elem.id);
        if (result.success) {
            navigate("SETTINGS", "WIDGETS");
        }
    };



    // Do the Render
    return <>
        <WidgetCode
            open={action.isCode}
            onClose={setAction}
        />
        <WidgetCreate
            open={action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isPublish && !elem.needsPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="WIDGETS_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <WidgetPublish
            open={action.isPublish && elem.needsPublish}
            data={data}
            currTongueID={tongueID}
            onSubmit={handlePublish}
            onClear={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="WIDGETS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="WIDGETS_DELETE_TITLE"
            message="WIDGETS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetDialogs.propTypes = {
    data     : PropTypes.object.isRequired,
    tongueID : PropTypes.number.isRequired,
};

export default WidgetDialogs;
