import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const StatusIcon = Styled(Icon).attrs(({ isRead, isFailed }) => ({ isRead, isFailed }))`
    font-size: 13px;
    margin-left: 4px;
    ${(props) => props.isFailed && "color: rgb(255, 0, 51);"}
    ${(props) => props.isRead && "color: rgb(0, 255, 0);"}
`;



/**
 * The Message Status
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageStatus(props) {
    const { isHidden, isDelivered, item } = props;


    // Returns the Icon
    const getIcon = () => {
        if (item.failedError) {
            return "close";
        }
        if (isDelivered || item.isDelivered) {
            return "double-check";
        }
        if (item.isSent) {
            return "check";
        }
        return "time";
    };

    // Returns the Tooltip
    const getTooltip = () => {
        if (item.failedError) {
            return "CONVERSATIONS_MESSAGE_ERROR";
        }
        if (item.isRead) {
            return "CONVERSATIONS_MESSAGE_READ";
        }
        if (isDelivered || item.isDelivered) {
            return "CONVERSATIONS_MESSAGE_DELIVERED";
        }
        if (item.isSent) {
            return "CONVERSATIONS_MESSAGE_SENT";
        }
        return "CONVERSATIONS_MESSAGE_SCHEDULED";
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <StatusIcon
        isRead={item.isRead}
        isFailed={!!item.failedError}
        icon={getIcon()}
        tooltip={getTooltip()}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageStatus.propTypes = {
    isHidden    : PropTypes.bool.isRequired,
    isDelivered : PropTypes.bool.isRequired,
    item        : PropTypes.object.isRequired,
};

export default MessageStatus;
