import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Personalize Order
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeOrder(props) {
    const { onChange, onClear, forceClear, getText } = props;

    const { errors } = Store.useState("storeOption");


    // Do the Render
    return <>
        <InputField
            name="orderTitle"
            label="PERSONALIZE_ORDER_ITS_TITLE"
            value={getText("orderTitle")}
            error={errors.orderTitle}
            onInput={onChange}
            onClear={onClear}
            forceClear={forceClear("orderTitle")}
            shrinkLabel
        />
        <InputField
            name="orderEmpty"
            label="PERSONALIZE_ORDER_EMPTY"
            value={getText("orderEmpty")}
            error={errors.orderEmpty}
            onInput={onChange}
            onClear={onClear}
            forceClear={forceClear("orderEmpty")}
            shrinkLabel
        />

        <InputField
            name="confirmOrder"
            label="PERSONALIZE_CONFIRM_ORDER"
            value={getText("confirmOrder")}
            error={errors.confirmOrder}
            onInput={onChange}
            onClear={onClear}
            forceClear={forceClear("confirmOrder")}
            shrinkLabel
        />
        <InputField
            name="cancelOrder"
            label="PERSONALIZE_CANCEL_ORDER"
            value={getText("cancelOrder")}
            error={errors.cancelOrder}
            onInput={onChange}
            onClear={onClear}
            forceClear={forceClear("cancelOrder")}
            shrinkLabel
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeOrder.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default PersonalizeOrder;
