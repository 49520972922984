import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";

// Components
import WidgetSection        from "./WidgetSection";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Widget Main
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetMain(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { elem, statuses } = Store.useState("widget");
    const { data, errors } = Store.useState("widgetState");


    // Do the Render
    return <>
        <WidgetSection title="WIDGETS_GENERAL">
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={onChange}
                onInput={onChange}
                isRequired
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={onChange}
                hideClear
                isRequired
            />

            <InputField
                type="select"
                name="fontFamily"
                label="PERSONALIZE_FONT_FAMILY"
                options="SELECT_FONT_FAMILIES"
                value={data.fontFamily}
                onChange={onChange}
                onClear={onClear}
                forceClear={forceClear("fontFamily")}
                hideClear
            />
        </WidgetSection>

        <WidgetSection title="WIDGETS_START">
            <InputField
                type="toggle"
                name="showStart"
                label="WIDGETS_SHOW_START"
                value={Number(data.showStart)}
                onChange={onChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="headerLeftAlign"
                label="PERSONALIZE_HEADER_LEFT_ALIGN"
                value={Number(data.headerLeftAlign)}
                onChange={onChange}
                withBorder
            />
            <MediaField
                name="logoFile"
                label="PERSONALIZE_HEADER_LOGO_FILE"
                mediaType={MediaType.IMAGE}
                value={data.logoFile}
                error={errors.logoFile}
                clientID={elem.clientID}
                onChange={onChange}
            />
            <InputField
                name="headerText"
                label="PERSONALIZE_HEADER_TEXT"
                value={getText("headerText")}
                error={errors.headerText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("headerText")}
                shrinkLabel
            />
            <InputField
                type="color"
                name="headerBgColor"
                label="PERSONALIZE_HEADER_BG_COLOR"
                value={data.headerBgColor}
                error={errors.headerBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("headerBgColor")}
                isRequired
            />
            <InputField
                type="color"
                name="headerTextColor"
                label="PERSONALIZE_HEADER_TEXT_COLOR"
                value={data.headerTextColor}
                error={errors.headerTextColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("headerTextColor")}
                isRequired
            />
        </WidgetSection>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetMain.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetMain;
