import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Client, Partner }  from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Ticket Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketEdit(props) {
    const { open, elemID, clientID, partnerID, onClose, onSubmit } = props;

    const { canAssign, ticketTypes, waitlists, userCredentials, adminCredentials } = Store.useState("ticket");
    const { fetchEditData, createTicket, editTicket } = Store.useAction("ticket");


    // The Initial Data
    const initialData = {
        ticketID          : 0,
        ticketTypeID      : 0,
        ticketWaitlistID  : 0,
        organization      : "client",
        clientID          : 0,
        clientName        : "",
        partnerID         : 0,
        partnerName       : "",
        subject           : "",
        message           : "",
        file              : null,
        fileName          : "",
        allUsers          : 1,
        userCredentialID  : 0,
        adminCredentialID : 0,
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        if (!elemID) {
            await createTicket(data);
        } else {
            await editTicket(data);
        }
    };

    // Handles the Suggest
    const handleSuggest = (name, nameValue, id, idValue,) => {
        handleChange(name, nameValue, id, idValue,);
        if (id === "clientID" && idValue) {
            fetchEditData(idValue, 0, false);
        } else if (id === "partnerID" && idValue) {
            fetchEditData(0, idValue, false);
        }
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("ticket", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading, elem } = useDialog("ticket", open, elemID, {
        clientID, partnerID,
    }, setElem);



    // Variables
    const isEdit           = Boolean(elemID);
    const showOrganization = Boolean(!isEdit && !partnerID && !clientID);
    const showClient       = Boolean(showOrganization && data.organization === "client");
    const showPartner      = Boolean(showOrganization && data.organization === "partner");
    const showWaitlist     = Boolean(canAssign && isEdit && elem.status === "Waiting");
    const showUser         = Boolean(canAssign);


    // Do the Render
    return <EditDialog
        open={open}
        icon="ticket"
        title={elemID ? "TICKETS_EDIT_TITLE" : "TICKETS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            isHidden={!showOrganization}
            type="radio"
            name="organization"
            label="TICKETS_ORGANIZATION"
            options="SELECT_ORGANIZATION"
            value={data.organization}
            onChange={handleChange}
        />
        <InputField
            isHidden={!showClient}
            type="suggest"
            name="clientName"
            label="CLIENTS_SINGULAR"
            suggestID="clientID"
            suggestFetch={Client.search}
            value={data.clientName}
            error={errors.clientID}
            onChange={handleSuggest}
            isRequired
        />
        <InputField
            isHidden={!showPartner}
            type="suggest"
            name="partnerName"
            label="PARTNERS_SINGULAR"
            suggestID="partnerID"
            suggestFetch={Partner.search}
            value={data.partnerName}
            error={errors.partnerID}
            onChange={handleSuggest}
            isRequired
        />
        <InputField
            type="select"
            name="ticketTypeID"
            label="TICKET_TYPES_SINGULAR"
            options={ticketTypes}
            value={data.ticketTypeID}
            error={errors.ticketTypeID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!showWaitlist}
            type="select"
            name="ticketWaitlistID"
            label="TICKET_WAITLISTS_SINGULAR"
            options={waitlists}
            value={data.ticketWaitlistID}
            error={errors.ticketWaitlistID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            name="subject"
            label="GENERAL_SUBJECT"
            value={data.subject}
            error={errors.subject}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={isEdit}
            type="textarea"
            name="message"
            label="GENERAL_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={isEdit}
            type="file"
            name="file"
            label="GENERAL_IMAGE"
            value={data.fileName}
            error={errors.file}
            onChange={handleChange}
            onError={setError}
            maxSize={process.env.REACT_APP_MAX_SIZE}
            onlyImages
        />
        <InputField
            type="toggle"
            name="allUsers"
            label="TICKETS_ALL_USERS"
            helperText="TICKETS_ALL_USERS_HELP"
            value={data.allUsers}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!showUser}
            type="select"
            name="userCredentialID"
            label="USERS_SINGULAR"
            options={userCredentials}
            value={data.userCredentialID}
            error={errors.userCredentialID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!canAssign}
            type="select"
            name="adminCredentialID"
            label="TICKETS_ASSIGNED"
            options={adminCredentials}
            value={data.adminCredentialID}
            error={errors.adminCredentialID}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketEdit.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    elemID    : PropTypes.number,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default TicketEdit;
