import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import AssistantRunLogView  from "./AssistantRunLogView";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Assistant Run Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantRunContent(props) {
    const { type, withNavigate } = props;

    const { logID }     = Navigate.useParams();
    const adminNavigate = Hooks.useAdminGoto(type);
    const logNavigate   = Hooks.useLogGoto(type);

    const { load, loadFilter } = useList("assistantRun", type);

    const data = Store.useState("assistantRun");
    const { loading, list, total, filters, sort } = data;

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();

    // Variables
    const isAdmin = type === "ADMIN";
    const isFlow  = type === "FLOW";


    // Handles the Log change
    React.useEffect(() => {
        if (logID) {
            startAction("VIEW", logID);
        }
    }, [ logID ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        const { clientID, flowID, conversationHash } = Utils.getValue(list, "logID", elemID);

        if (action.isClient) {
            adminNavigate(clientID, "SETTINGS", "LOG_ASSISTANTS");
        } else if (action.isFlow) {
            adminNavigate(clientID, "SETTINGS", "FLOWS", flowID, "LOG_ASSISTANTS");
        } else if (action.isConversation) {
            adminNavigate(clientID, "CONVERSATIONS", conversationHash);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Close
    const handleClose = () => {
        endAction();
        if (withNavigate) {
            logNavigate("LOG_ASSISTANTS");
        }
    };

    // Returns true if the Flow is hidden
    const hideFlow = (elemID) => {
        const flowID = Utils.getValue(list, "logID", elemID, "flowID");
        return isFlow || !flowID;
    };

    // Returns true if the Conversation is hidden
    const hideConversation = (elemID) => {
        const conversationID = Utils.getValue(list, "logID", elemID, "conversationID");
        return !conversationID;
    };


    // Do the Render
    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="ASSISTANTS_RUNS_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs={isFlow}
            hasFilter
        >
            <TableHead>
                <TableHeader field="createdTime"    message="GENERAL_TIME"              minWidth="140" maxWidth="140" />
                <TableHeader field="clientName"     message="CLIENTS_SINGULAR"          isHidden={!isAdmin} />
                <TableHeader field="assistantName"  message="ASSISTANTS_NAME"           />
                <TableHeader field="flowName"       message="FLOWS_SINGULAR"            isHidden={isFlow} />
                <TableHeader field="conversationID" message="CONVERSATIONS_SINGULAR"    maxWidth="110" align="center" />
                <TableHeader field="runTime"        message="ASSISTANTS_RUNS_EXECUTION" maxWidth="150" align="right" />
                <TableHeader field="inputTokens"    message="ASSISTANTS_RUNS_INPUT"     maxWidth="150" align="right" />
                <TableHeader field="outputTokens"   message="ASSISTANTS_RUNS_OUTPUT"    maxWidth="150" align="right" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.assistantRunID} elemID={elem.assistantRunID}>
                    <TableCell message={elem.createdTimeText}  />
                    <TableCell message={elem.clientName}       />
                    <TableCell message={elem.assistantName}    />
                    <TableCell message={elem.flowName}         />
                    <TableCell message={elem.conversationText} />
                    <TableCell message={elem.runTimeText}      />
                    <TableCell message={elem.inputTokensText}  />
                    <TableCell message={elem.outputTokensText} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={handleAction}>
                <TableAction action="VIEW"         message="ASSISTANT_RUNS_VIEW_TITLE" navigate={withNavigate} />
                <TableAction action="CLIENT"       message="CLIENTS_VIEW_TITLE"        isHidden={!isAdmin} />
                <TableAction action="FLOW"         message="FLOWS_VIEW_TITLE"          hide={hideFlow} />
                <TableAction action="CONVERSATION" message="CONVERSATIONS_VIEW_TITLE"  hide={hideConversation} />
            </TableActionList>
        </Table>

        <AssistantRunLogView
            open={action.isView}
            isAdmin={isAdmin}
            isFlow={isFlow}
            elemID={elemID}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantRunContent.propTypes = {
    type         : PropTypes.string.isRequired,
    withNavigate : PropTypes.bool,
};

export default AssistantRunContent;
