import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import IntegrationHeader    from "./View/IntegrationHeader";
import IntegrationReport    from "./View/IntegrationReport";
import IntegrationExecs     from "./View/IntegrationExecs";
import IntegrationDialogs   from "./View/IntegrationDialogs";
import IntegrationEditor    from "./Editor/IntegrationEditor";
import FlowEditor           from "Components/App/Config/Flow/Editor/FlowEditor";
import ConnectorLogContent  from "Components/App/Log/ConnectorLog/ConnectorLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Integration Page
 * @returns {React.ReactElement}
 */
function IntegrationPage() {
    const { integrationID, integrationTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { charging, elem } = Store.useState("integration");

    const { fetchElem } = Store.useAction("integration");
    const { setInPublish } = Store.useAction("integrationState");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Load the Data
    React.useEffect(() => {
        handleLoad(integrationID);
        setInPublish(false);
        setFlowInPublish(false);
    }, [ integrationID ]);

    // Handle the Load
    const handleLoad = async (integrationID) => {
        const { elem } = await fetchElem(integrationID);
        if (elem) {
            navigate("SETTINGS", "INTEGRATIONS", integrationID, "EDIT");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "INTEGRATIONS", integrationID, url);
    };


    // Variables
    const isEdit       = Navigate.isUrl("EDIT",           integrationTab);
    const isFlow       = Navigate.isUrl("FLOW_ONE",       integrationTab);
    const isReport     = Navigate.isUrl("REPORT",         integrationTab);
    const isExecutions = Navigate.isUrl("EXECUTIONS",     integrationTab);
    const isConnectors = Navigate.isUrl("LOG_CONNECTORS", integrationTab);


    // Do the Render
    return <>
        <Main withNavigation>
            <IntegrationHeader levels={2} />

            <Content isLoading={charging}>
                <TabList
                    isHidden={!elem.hasTabs}
                    variant="lined"
                    selected={integrationTab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_EDITOR"
                        icon="edit"
                        url="EDIT"
                    />
                    <TabItem
                        isHidden={!elem.flowActiveDraft}
                        message="FLOWS_SINGULAR"
                        icon="flow"
                        url="FLOW_ONE"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="GENERAL_REPORT"
                        icon="report"
                        url="REPORT"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="INTEGRATIONS_EXECS_NAME"
                        icon="simulate"
                        url="EXECUTIONS"
                    />
                    <TabItem
                        isHidden={elem.isDraft || !elem.hasLog}
                        message="CONNECTORS_LOG_TITLE"
                        icon="connector-log"
                        url="LOG_CONNECTORS"
                    />
                </TabList>

                {isEdit && <IntegrationEditor
                    integrationID={integrationID}
                    hasTabs={elem.hasTabs || false}
                />}
                {isFlow && <FlowEditor
                    flowID={elem.flowID}
                    hasTabs
                />}

                {isReport && <IntegrationReport />}
                {isExecutions && <IntegrationExecs />}
                {isConnectors && <ConnectorLogContent type="INTEGRATION" />}
            </Content>
        </Main>

        <IntegrationDialogs />
    </>;
}

export default IntegrationPage;
