import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// The Steps
const STEP_FILE     = "file";
const STEP_COLUMNS  = "columns";
const STEP_SUCCESS  = "success";
const STEP_CANCELED = "canceled";



/**
 * The Account Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { uploadImport, importAccounts } = Store.useAction("account");


    // The References
    const controllerRef = React.useRef(null);

    // The Current State
    const [ step,       setStep       ] = React.useState(STEP_FILE);
    const [ importText, setImportText ] = React.useState("");
    const [ fields,     setFields     ] = React.useState([]);
    const [ columns,    setColumns    ] = React.useState([]);
    const [ amount,     setAmount     ] = React.useState(0);
    const [ imported,   setImported   ] = React.useState(0);
    const [ progress,   setProgress   ] = Hooks.useProgress();

    // The Initial Data
    const initialData = {
        file     : null,
        fileName : "",
        filePath : "",
        columns  : {},
    };


    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            setStep(STEP_FILE);
        }
    }, [ open ]);


    // Handles the Column Change
    const handleColumnChange = (name, value) => {
        const columns = { ...data.columns, [name] : value };
        handleChange("columns", columns);
    };

    // Handles the Edit
    const handleEdit = (data) => {
        switch (step) {
        case STEP_FILE:
            return uploadImport({ ...data, clientID });

        case STEP_COLUMNS:
            setProgress(1);
            controllerRef.current = new window.AbortController();
            return importAccounts({
                filePath : data.filePath,
                columns  : JSON.stringify(data.columns),
                clientID,
            }, controllerRef.current);

        default:
            return null;
        }
    };

    // Handles the After Submit
    const handleAfterSubmit = (data) => {
        switch (step) {
        case STEP_FILE:
            setElem({
                filePath : data.filePath,
                columns  : Commons.getImportColumns(data.columns, data.fields),
            });
            setFields(data.fields);
            setColumns(data.columns);
            setAmount(data.amount);
            setImportText(NLS.format("GENERAL_IMPORT_LINES", data.first, data.last));
            setStep(STEP_COLUMNS);
            break;

        case STEP_COLUMNS:
            setImported(data.imported);
            setStep(STEP_SUCCESS);
            break;

        default:
            setProgress(0);
            onSubmit();
            return;
        }
    };

    // Handles the Close
    const handleClose = () => {
        const imported = progress;
        setProgress(0);

        switch (step) {
        case STEP_FILE:
            onClose();
            break;

        case STEP_COLUMNS:
            if (controllerRef.current) {
                controllerRef.current.abort();
                setImported(imported);
                setStep(STEP_CANCELED);
            } else {
                onClose();
            }
            break;

        case STEP_SUCCESS:
        case STEP_CANCELED:
            onSubmit();
            break;

        default:
        }
    };

    // The Form State
    const {
        loading, data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("account", initialData, handleEdit, handleAfterSubmit, false, open);



    // Variables
    const columnsStep  = step === STEP_COLUMNS;
    const showFile     = !loading && step === STEP_FILE;
    const showColumns  = !loading && step === STEP_COLUMNS;
    const showSuccess  = !loading && step === STEP_SUCCESS;
    const showCanceled = !loading && step === STEP_CANCELED;
    const useClose     = showSuccess || showCanceled;
    const hidePrimary  = loading || showSuccess || showCanceled;


    // Do the Render
    return <EditDialog
        open={open}
        icon="import"
        title="ACCOUNTS_IMPORT_TITLE"
        error={showCanceled ? "" : errors.form}
        onSubmit={handleSubmit}
        onClose={handleClose}
        primary="GENERAL_SAVE"
        cancel={useClose ? "GENERAL_CLOSE" : "GENERAL_CANCEL"}
        hidePrimary={hidePrimary}
    >
        {loading && <CircularLoader
            message={columnsStep ? NLS.format("GENERAL_IMPORTING", progress, amount) : "GENERAL_LOADING"}
        />}

        {showFile && <>
            <DialogMessage message="ACCOUNTS_IMPORT_FILE" />
            <InputField
                type="file"
                name="file"
                label="MEDIA_SINGULAR"
                value={data.fileName}
                error={errors.file}
                maxSize={process.env.REACT_APP_MAX_SIZE}
                onChange={handleChange}
                onError={setError}
            />
        </>}

        {showColumns && <>
            <DialogMessage message={importText} />
            <DialogMessage message="ACCOUNTS_IMPORT_COLUMNS" />
            <Columns isHidden={!showColumns}>
                {fields.map(({ key, value }) => <InputField
                    key={key}
                    type="select"
                    name={String(key)}
                    label={value}
                    value={data.columns[key]}
                    onChange={handleColumnChange}
                    options={columns}
                />)}
            </Columns>
        </>}

        {showSuccess && <DialogMessage
            className="text-green"
            variant="h3"
            message={NLS.format("ACCOUNTS_IMPORT_SUCCESS", imported)}
            centered
        />}

        {showCanceled && <DialogMessage
            className="text-red"
            variant="h3"
            message={NLS.format("ACCOUNTS_IMPORT_CANCELED", imported, amount)}
            centered
        />}
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default AccountImport;
