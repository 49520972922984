import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



/**
 * The Variable Menu
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function VariableMenu(props) {
    const {
        open, targetRef, inputRef, message,
        list, direction, onClick, onClose,
    } = props;


    // Handles the Click
    const handleClick = (elem) => {
        if (!message || !inputRef) {
            onClick(elem);
        } else {
            const result = Utils.insertText(inputRef, message, elem);
            onClick(result);
            inputRef.current.focus();
        }
    };


    // Generate the Menu Items
    const menuItems = React.useMemo(() => {
        let lastTitle = "";
        const result = [];
        for (const { key, value, extra } of list) {
            if (lastTitle !== extra) {
                lastTitle = extra;
                result.push(<MenuItem
                    key={extra}
                    title={extra}
                />);
            }
            result.push(<MenuItem
                key={key}
                title={NLS.get(value)}
                message={key}
                onClick={() => handleClick(key)}
                leftSpace
                isSmall
            />);
        }
        return result;
    }, [ message, JSON.stringify(list) ]);


    // Do the Render
    return <Menu
        open={open}
        targetRef={targetRef}
        direction={direction}
        width={400}
        maxHeight={300}
        gap={4}
        onClose={onClose}
        withSearch
    >
        {menuItems}
    </Menu>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
VariableMenu.propTypes = {
    open      : PropTypes.bool.isRequired,
    targetRef : PropTypes.any,
    inputRef  : PropTypes.any,
    message   : PropTypes.string,
    list      : PropTypes.array.isRequired,
    direction : PropTypes.string.isRequired,
    onClick   : PropTypes.func.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default VariableMenu;
