import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import WidgetSection        from "./WidgetSection";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Widget Icon
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetIcon(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { elem } = Store.useState("widget");
    const { data, errors } = Store.useState("widgetState");


    // Variables
    const hasIconFile   = Utils.isActive(data.iconShowFile);
    const hasIconBorder = Utils.isActive(data.iconShowBorder);
    const hasBubble     = Utils.isActive(data.showBubble);


    // Do the Render
    return <>
        <WidgetSection title="WIDGETS_ICON">
            <InputField
                type="toggle"
                name="showMessageCount"
                label="WIDGETS_SHOW_MESSAGE_COUNT"
                value={Number(data.showMessageCount)}
                onChange={onChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="iconShowFile"
                label="WIDGETS_ICON_SHOW_FILE"
                value={Number(data.iconShowFile)}
                onChange={onChange}
                withBorder
            />
            <MediaField
                isHidden={!hasIconFile}
                className="columns-double"
                name="iconFile"
                label="WIDGETS_ICON_FILE"
                mediaType={MediaType.IMAGE}
                value={data.iconFile}
                error={errors.iconFile}
                clientID={elem.clientID}
                onChange={onChange}
            />
            <InputField
                isHidden={hasIconFile}
                type="color"
                name="iconIconColor"
                label="WIDGETS_ICON_COLOR"
                value={data.iconIconColor}
                error={errors.iconIconColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("iconIconColor")}
            />
            <InputField
                isHidden={hasIconFile}
                type="color"
                name="iconBgColor"
                label="WIDGETS_ICON_BG_COLOR"
                value={data.iconBgColor}
                error={errors.iconBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("iconBgColor")}
            />
            <InputField
                type="toggle"
                name="iconShowBorder"
                label="WIDGETS_ICON_SHOW_BORDER"
                value={Number(data.iconShowBorder)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!hasIconBorder}
                type="color"
                name="iconBorderColor"
                label="WIDGETS_ICON_BORDER_COLOR"
                value={data.iconBorderColor}
                error={errors.iconBorderColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("iconBorderColor")}
            />
            <InputField
                type="toggle"
                name="iconShowShadow"
                label="WIDGETS_ICON_SHOW_SHADOW"
                value={Number(data.iconShowShadow)}
                onChange={onChange}
                withBorder
            />
            <InputField
                type="number"
                name="iconSize"
                label="WIDGETS_ICON_SIZE"
                value={data.iconSize}
                minValue="30"
                maxValue="200"
                step="1"
                onChange={onChange}
                onClear={onClear}
                forceClear={forceClear("iconSize")}
            />
            <InputField
                type="number"
                name="iconBottomSpace"
                label="WIDGETS_ICON_BOTTOM_SPACE"
                value={data.iconBottomSpace}
                step="1"
                onChange={onChange}
                onClear={onClear}
                forceClear={forceClear("iconBottomSpace")}
            />
        </WidgetSection>

        <WidgetSection title="WIDGETS_BUBBLE">
            <InputField
                type="toggle"
                name="showBubble"
                label="WIDGETS_SHOW_BUBBLE"
                value={Number(data.showBubble)}
                onChange={onChange}
                withBorder
            />
            <InputField
                isHidden={!hasBubble}
                name="bubbleText"
                label="WIDGETS_BUBBLE_TEXT"
                value={getText("bubbleText")}
                error={errors.bubbleText}
                onInput={onChange}
                onClear={onClear}
                forceClear={forceClear("bubbleText")}
                shrinkLabel
            />
            <InputField
                isHidden={!hasBubble}
                type="color"
                name="bubbleBgColor"
                label="WIDGETS_BUBBLE_BG_COLOR"
                value={data.bubbleBgColor}
                error={errors.bubbleBgColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("bubbleBgColor")}
            />
            <InputField
                isHidden={!hasBubble}
                type="color"
                name="bubbleTextColor"
                label="WIDGETS_BUBBLE_TEXT_COLOR"
                value={data.bubbleTextColor}
                error={errors.bubbleTextColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("bubbleTextColor")}
            />
            <InputField
                isHidden={!hasBubble}
                type="color"
                name="bubbleBorderColor"
                label="WIDGETS_BUBBLE_BORDER_COLOR"
                value={data.bubbleBorderColor}
                error={errors.bubbleBorderColor}
                onChange={onColor}
                onClear={onClear}
                forceClear={forceClear("bubbleBorderColor")}
            />
        </WidgetSection>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetIcon.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetIcon;
