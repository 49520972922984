import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Hooks                from "Utils/Hooks";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Button               from "Dashboard/Components/Form/Button";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Content = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Inner = Styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Text = Styled.p`
    margin: 0;
    padding: 2px 4px;
`;

const Create = Styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 4px 2px 4px;

    p {
        margin: 0;
    }
`;

const Link = Styled(IconLink)`
    font-size: 18px;
`;



/**
 * The Reply Template
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyTemplate(props) {
    const { isHidden } = props;

    const navigate = Hooks.useGoto();

    const { isOwner, isAnyAdmin } = Store.useState("auth");
    const { elem, wabaTemplates } = Store.useState("conversation");
    const { lastUpdate } = Store.useState("conversationMessage");
    const { sendTemplate } = Store.useAction("conversationMessage");


    // The Current State
    const [ sending,        setSending        ] = React.useState(false);
    const [ error,          setError          ] = React.useState("");
    const [ wabaTemplateID, setWabaTemplateID ] = React.useState(0);


    // Handles the Change
    const handleChange = (name, value) => {
        setWabaTemplateID(value);
        setError("");
    };

    // Handles the Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (sending || !wabaTemplateID) {
            setError("CONVERSATIONS_ERROR_TEMPLATE_EMPTY");
            return;
        }

        setSending(true);
        try {
            const response = await sendTemplate({
                wabaTemplateID, lastUpdate,
                conversationID : elem.id,
            });
            if (!response.error) {
                setWabaTemplateID(0);
                setError("");
            }
            setSending(false);
        } catch (errors) {
            setSending(false);
        }
    };

    // Handles the WABA Template
    const handleWaba = () => {
        navigate("SETTINGS", "WABA_TEMPLATES");
    };


    // Variables
    const wabaTemplateList = Commons.getWabaTemplates(wabaTemplates, elem.channelID);
    const wabaTemplate     = Utils.getValue(wabaTemplates, "id", wabaTemplateID);
    const wabaMessage      = wabaTemplateID ? Commons.getWabaMessage(wabaTemplate, elem.isoCode) : "";
    const showTemplates    = Boolean(wabaTemplateList.length);
    const showCreate       = Boolean(!showTemplates && (isOwner || isAnyAdmin));


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <Text>{NLS.get("CONVERSATIONS_SEND_TEMPLATE_HELP")}</Text>
        {showCreate && <Create>
            <p>{NLS.get("CONVERSATIONS_SEND_TEMPLATE_CREATE")}</p>
            <Button
                variant="primary"
                message="WABA_TEMPLATES_CREATE_TITLE"
                onClick={handleWaba}
            />
        </Create>}

        {showTemplates && <Content>
            <Inner>
                <InputField
                    type="select"
                    name="wabaTemplateID"
                    label="GENERAL_TEMPLATE"
                    options={wabaTemplateList}
                    value={wabaTemplateID}
                    error={error}
                    onChange={handleChange}
                    isRequired
                />
                <ViewField
                    isHidden={!wabaTemplateID}
                    label="GENERAL_MESSAGE"
                    value={wabaMessage}
                />
            </Inner>
            <Link
                variant="light"
                icon="send"
                tooltip="GENERAL_SEND"
                tooltipVariant="top"
                onClick={handleSubmit}
                onTouchEnd={handleSubmit}
                isDisabled={sending}
            />
        </Content>}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyTemplate.propTypes = {
    isHidden : PropTypes.bool,
};

export default ReplyTemplate;
