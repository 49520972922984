import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import CampaignEmpty        from "../Utils/CampaignEmpty";
import CampaignTongues      from "../Utils/CampaignTongues";
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Campaign SMS
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignSMS(props) {
    const { data, getOptionError, onChange } = props;

    const {
        elem, hasSMS, smsCost,
        selects : { tongues, variables, channelLinks, smsChannels },
    } = Store.useState("campaign");


    // The Current State
    const [ tongueID, setTongueID ] = Hooks.useTongue(tongues);


    // Returns the Value
    const getValue = (name) => {
        return data.options[tongueID]?.[name] || "";
    };

    // Handles the Change with a Tongue
    const handleChangeTongue = (name, value) => {
        onChange(name, value, tongueID);
    };


    // Do the Render
    const smsActive  = Utils.isActive(data.smsActive);
    const smsMessage = getValue("smsMessage");

    if (!hasSMS) {
        return <CampaignEmpty
            waitMessage="CAMPAIGNS_SMS_WAIT"
            configureMessage="CAMPAIGNS_SMS_CONFIGURE"
        />;
    }
    return <>
        <DialogMessage
            variant="h3"
            message="CAMPAIGNS_SMS_TEXT"
        />
        <InputField
            type="toggle"
            name="smsActive"
            label="CAMPAIGNS_SMS_ACTIVATE"
            value={smsActive}
            error={getOptionError("smsActive")}
            onChange={onChange}
            withBorder
        />

        <Columns isHidden={!smsActive}>
            <InputField
                type="select"
                name="smsPriority"
                label="CAMPAIGNS_PRIORITY"
                options="SELECT_CAMPAIGN_PRIORITIES"
                value={data.smsPriority}
                error={getOptionError("smsPriority")}
                onChange={onChange}
                isRequired
            />
            <InputField
                type="select"
                name="smsChannelID"
                label="GENERAL_SMS_CHANNEL"
                options={smsChannels}
                value={data.smsChannelID}
                error={getOptionError("smsChannelID")}
                onChange={onChange}
                isRequired
            />
        </Columns>

        <CampaignTongues
            isHidden={!smsActive}
            tongueID={tongueID}
            setTongueID={setTongueID}
        />

        <TextareaField
            isHidden={!smsActive}
            name="smsMessage"
            label="GENERAL_MESSAGE"
            value={smsMessage}
            error={getOptionError("smsMessage")}
            helperText="CONVERSATIONS_SMS_COST_INFO"
            messageCost={smsCost}
            variables={variables}
            channelLinks={channelLinks}
            onChange={handleChangeTongue}
            withoutFormat
            isRequired
        />
        <MediaField
            isHidden={!smsActive}
            name="smsFile"
            value={getValue("smsFile")}
            error={getOptionError("smsFile")}
            clientID={elem.clientID}
            onChange={handleChangeTongue}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignSMS.propTypes = {
    data           : PropTypes.object.isRequired,
    getOptionError : PropTypes.func.isRequired,
    onChange       : PropTypes.func.isRequired,
};

export default CampaignSMS;
