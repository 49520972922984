import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import TextareaField        from "Components/Utils/Inputs/TextareaField";
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Message Template Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MsgTemplateEditor(props) {
    const {
        loading, isEdit, clientID, hasTabs, step, setStep,
        getValue, getError, getErrorCount,
        onChange, onSubmit, onDelete, onClose,
    } = props;

    const { hasHotel } = Store.useState("permission");
    const {
        teams, hotels, variables, channelLinks,
        messageLength, statuses,
    } = Store.useState("msgTemplate");


    // Do the Render
    return <PageContainer hasTabs={hasTabs} error={getError("form")}>
        <PageAccordion selected={step} onChange={setStep}>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="MSG_TEMPLATES_MAIN_TEXT"
                errorCount={getErrorCount("main")}
            >
                <Columns>
                    <InputField
                        className="columns-double"
                        name="name"
                        label="GENERAL_NAME"
                        value={getValue("name")}
                        error={getError("name")}
                        onChange={onChange}
                        isRequired
                    />

                    <InputField
                        type="number"
                        name="position"
                        label="GENERAL_POSITION"
                        value={getValue("position")}
                        error={getError("position")}
                        onChange={onChange}
                        minValue={0}
                    />
                    <InputField
                        type="select"
                        name="status"
                        label="GENERAL_STATUS"
                        options={statuses}
                        value={getValue("status")}
                        error={getError("status")}
                        onChange={onChange}
                        hideClear
                        isRequired
                    />
                </Columns>
            </AccordionItem>

            <AccordionItem
                value="message"
                message="GENERAL_MESSAGE"
                description="MSG_TEMPLATES_MESSAGE_TEXT"
                errorCount={getErrorCount("message")}
                withGap
            >
                <TextareaField
                    name="message"
                    label="GENERAL_MESSAGE"
                    value={getValue("message")}
                    error={getError("message")}
                    variables={variables}
                    channelLinks={channelLinks}
                    maxLength={messageLength}
                    onChange={onChange}
                    isRequired
                />
                <MediaField
                    name="file"
                    value={getValue("file")}
                    error={getError("file")}
                    clientID={clientID}
                    onChange={onChange}
                />
            </AccordionItem>

            <AccordionItem
                value="assign"
                message="MSG_TEMPLATES_ASSIGN_TITLE"
                description={`MSG_TEMPLATES_ASSIGN_${hasHotel ? "HOTEL" : "TEXT"}`}
                errorCount={getErrorCount("assign")}
                withGap
            >
                <InputField
                    type="multiple"
                    name="teams"
                    label="TEAMS_NAME"
                    options={teams}
                    value={getValue("teams")}
                    error={getError("teams")}
                    onChange={onChange}
                />
                <InputField
                    isHidden={!hasHotel}
                    type="multiple"
                    name="hotels"
                    label="HOTELS_NAME"
                    options={hotels}
                    value={getValue("hotels")}
                    error={getError("hotels")}
                    onChange={onChange}
                />
            </AccordionItem>
        </PageAccordion>

        <PageButtons
            isEdit={isEdit}
            isDisabled={loading}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onClose={onClose}
        />
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MsgTemplateEditor.propTypes = {
    loading       : PropTypes.bool.isRequired,
    isEdit        : PropTypes.bool.isRequired,
    clientID      : PropTypes.number.isRequired,
    hasTabs       : PropTypes.bool.isRequired,
    step          : PropTypes.string.isRequired,
    setStep       : PropTypes.func.isRequired,
    getValue      : PropTypes.func.isRequired,
    getError      : PropTypes.func.isRequired,
    getErrorCount : PropTypes.func.isRequired,
    onChange      : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
};

export default MsgTemplateEditor;
