import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Empty = Styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    height: var(--page-height);
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Iconography = Styled(Icon)`
    padding: 0 24px;
    margin-top: -40px;
    font-size: 72px;
    border-radius: calc(2 * var(--border-radius));
    background-color: var(--lighter-gray);
    color: var(--darkest-gray);
    opacity: 0.8;
`;

const Title = Styled.h3`
    font-size: 16px;
    font-weight: normal;
`;



/**
 * The Chat Empty
 * @returns {React.ReactElement}
 */
function ChatEmpty() {
    const navigate = Hooks.useGoto();

    const { list, providers, canAddChannel } = Store.useState("conversation");


    // Handles the Click
    const handleClick = () => {
        navigate("SETTINGS", "CHANNELS", "ADD");
    };


    // Variables
    let message   = "CONVERSATIONS_SELECT_ONE";
    let hasButton = false;
    if (!providers.length) {
        if (canAddChannel) {
            message   = "CONVERSATIONS_SELECT_CONFIGURE";
            hasButton = true;
        } else {
            message = "CONVERSATIONS_SELECT_NONE";
        }
    } else if (!list.length) {
        message = "CONVERSATIONS_SELECT_EMPTY";
    }


    // Do the Render
    return <Empty>
        <Iconography icon="inbox" />
        <Title>{NLS.get(message)}</Title>
        {hasButton && <Button
            variant="primary"
            message="CHANNELS_ADD_TITLE"
            onClick={handleClick}
        />}
    </Empty>;
}

export default ChatEmpty;
