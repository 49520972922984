import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Content = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0 8px 4px;
    font-size: 13px;
    border-bottom: 1px solid var(--border-color-light);
`;

const Text = Styled.div`
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Title = Styled.b`
    display: block;
    margin-bottom: 4px;
`;



/**
 * The Reply Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyContent(props) {
    const { isHidden, title, message, onClose } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Content>
        <Text>
            {!!title && <Title>{NLS.get(title)}</Title>}
            {message}
        </Text>
        <IconLink
            variant="black"
            icon="close"
            onClick={() => onClose()}
            isTiny
        />
    </Content>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyContent.propTypes = {
    isHidden : PropTypes.bool,
    title    : PropTypes.string,
    message  : PropTypes.string,
    onClose  : PropTypes.func.isRequired,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
ReplyContent.defaultProps = {
    isHidden : false,
    title    : "",
    message  : "",
};

export default ReplyContent;
