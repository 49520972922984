import React                from "react";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import IntegrationTempEdit  from "./IntegrationTempEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Integration Template Dialogs
 * @returns {React.ReactElement}
 */
function IntegrationTempDialogs() {
    const { openDetails } = Store.useAction("core");
    const navigate = Navigate.useGoto();

    const { elem } = Store.useState("integration");
    const {
        fetchElem, activateIntegration, publishIntegration,
        clearIntegration, pauseIntegration, deleteIntegration,
    } = Store.useAction("integrationTemplate");

    const { inActivate, action } = Store.useState("integrationState");
    const { setAction, setInActivate, setInPublish } = Store.useAction("integrationState");

    const { totalChanges } = Store.useState("integrationEditor");
    const { fetchEditor } = Store.useAction("integrationEditor");


    // Handles the Integration Edit
    const handleEdit = (response) => {
        setAction();
        if (response.integrationID !== elem.id) {
            navigate("SETTINGS", "INTEGRATIONS", response.integrationID);
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        setAction();
        const result = await activateIntegration(elem.id);
        if (result.success) {
            setInActivate(false);
            fetchElem(elem.id);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishIntegration(elem.id);
        if (result.success) {
            setInPublish(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearIntegration(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        setAction();
        const result = await pauseIntegration(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        const result = await deleteIntegration(elem.id);
        if (result.success) {
            navigate("SETTINGS", "INTEGRATIONS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        openDetails();
    };

    // Returns the Error Message
    const getErrorsText = () => {
        const parts = [
            "TEXT",
            inActivate ? "ACTIVATE" : "PUBLISH",
            "PANEL",
        ];
        return parts.map((text) => NLS.get(`INTEGRATIONS_TEMPS_ERRORS_${text}`)).join(" ");
    };



    // Do the Render
    return <>
        <IntegrationTempEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="INTEGRATIONS_ACTIVATE_TITLE"
            message="INTEGRATIONS_ACTIVATE_TEXT"
            content={elem.name}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <AlertDialog
            open={action.isPublish && totalChanges === 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="INTEGRATIONS_TEMPS_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish && totalChanges > 0}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={NLS.pluralize("INTEGRATIONS_TEMPS_PUBLISH", totalChanges, elem.name)}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="INTEGRATIONS_TEMPS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="INTEGRATIONS_TEMPS_PAUSE_TITLE"
            message="INTEGRATIONS_TEMPS_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="INTEGRATIONS_TEMPS_DELETE_TITLE"
            message="INTEGRATIONS_TEMPS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isError}
            icon={inActivate ? "check" : "publish"}
            title={inActivate ? "INTEGRATIONS_TEMPS_ACTIVATE_TITLE" : "GENERAL_PUBLISH_CHANGES"}
            message={getErrorsText()}
            onSubmit={handleErrors}
            onClose={setAction}
        />
    </>;
}

export default IntegrationTempDialogs;
