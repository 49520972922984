import React                from "react";
import Store                from "Dashboard/Core/Store";
import Hooks                from "Utils/Hooks";

// Components
import StoreEdit            from "./StoreEdit";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Store Dialogs
 * @returns {React.ReactElement}
 */
function StoreDialogs() {
    const navigate = Hooks.useGoto();

    const { elem } = Store.useState("store");
    const {
        fetchElem, activateStore, publishStore,
        clearStore, pauseStore, deleteStore,
    } = Store.useAction("store");

    const { action } = Store.useState("storeState");
    const { setAction } = Store.useAction("storeState");


    // Handles the Edit Submit
    const handleEdit = async (response) => {
        setAction();
        if (response.storeID !== elem.id) {
            navigate("STORES", response.storeID, "EDIT");
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Activate Submit
    const handleActivate = async () => {
        setAction();
        const result = await activateStore(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Publish Submit
    const handlePublish = async () => {
        setAction();
        const result = await publishStore(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Clear Submit
    const handleClear = async () => {
        setAction();
        const result = await clearStore(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Pause Submit
    const handlePause = async () => {
        setAction();
        const result = await pauseStore(elem.id);
        if (result.success) {
            fetchElem(elem.id);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        const result = await deleteStore(elem.id);
        if (result.success) {
            navigate("STORES");
        }
    };



    // Do the Render
    return <>
        <StoreEdit
            open={action.isEdit}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="STORES_ACTIVATE_TITLE"
            message="STORES_ACTIVATE_TEXT"
            content={elem.name}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <AlertDialog
            open={action.isPublish && !elem.needsPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="STORES_PUBLISH_NO_CHANGES"
            content={elem.name}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish && elem.needsPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="STORES_PUBLISH_CHANGES"
            content={elem.name}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="STORES_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="STORES_PAUSE_TITLE"
            message="STORES_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="STORES_DELETE_TITLE"
            message="STORES_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />
    </>;
}

export default StoreDialogs;
