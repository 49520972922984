import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import ContactItem          from "Components/Utils/Details/ContactItem";
import HospitalityItem      from "Components/Utils/Details/HospitalityItem";
import OrderItem            from "Components/Utils/Details/OrderItem";
import AccountsItem         from "Components/Utils/Details/AccountsItem";
import ConversationsItem    from "Components/Utils/Details/ConversationsItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";



/**
 * The Contact Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactDetails(props) {
    const { startAction } = props;

    const { canEdit, elem, hospitality, order, accounts, conversations } = Store.useState("contact");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    return <Details
        isHidden={!elem.id}
        collapsible="contact"
        onAction={handleAction}
        canEdit={canEdit}
    >
        <ContactItem
            action="EDIT"
            elem={elem}
        />
        <HospitalityItem
            action="HOSPITALITY"
            elem={hospitality}
        />
        <OrderItem
            action="ORDER"
            elem={order}
        />
        <AccountsItem
            list={accounts}
            onItemAction={startAction}
        />
        <ConversationsItem
            list={conversations}
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactDetails.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default ContactDetails;
